bcore.provide('bcore.ui.form');

export const quick_edit = bcore.quick_edit = function (doctype, name) {
	bcore.db.get_doc(doctype, name).then(doc => {
		bcore.ui.form.make_quick_entry(doctype, null, null, doc);
	});
};

export const make_quick_entry = bcore.ui.form.make_quick_entry = (doctype, after_insert, init_callback, doc) => {
	var trimmed_doctype = doctype.replace(/ /g, '');
	var controller_name = "QuickEntryForm";

	if (bcore.ui.form[trimmed_doctype + "QuickEntryForm"]) {
		controller_name = trimmed_doctype + "QuickEntryForm";
	}

	bcore.quick_entry = new bcore.ui.form[controller_name](doctype, after_insert, init_callback, doc);
	return bcore.quick_entry.setup();
};

export class QuickEntryForm {
	constructor(doctype, after_insert, init_callback, doc) {
		this.doctype = doctype;
		this.after_insert = after_insert;
		this.init_callback = init_callback;
		this.doc = doc;
	}

	async setup() {
		await bcore.model.with_doctype(this.doctype);

		this.check_quick_entry_doc();
		this.set_meta_and_mandatory_fields();
		if (this.is_quick_entry()) {
			this.render_dialog();
		} else {
			bcore.quick_entry = null;
			await bcore.set_route('Form', this.doctype, this.doc.name)
		}

		return this;
	}

	set_meta_and_mandatory_fields() {
		this.meta = bcore.get_meta(this.doctype);
		let fields = this.meta.fields;

		// prepare a list of mandatory, bold and allow in quick entry fields
		this.mandatory = $.map(fields, function (d) {
			return ((d.reqd || d.bold || d.allow_in_quick_entry) && !d.read_only) ? $.extend({}, d) : null;
		});
	}

	check_quick_entry_doc() {
		if (!this.doc) {
			this.doc = bcore.model.get_new_doc(this.doctype, null, null, true);
		}
	}

	is_quick_entry() {
		if (this.meta.quick_entry != 1) {
			return false;
		}

		this.validate_for_prompt_autoname();

		if (this.has_child_table() || !this.mandatory.length) {
			return false;
		}

		return true;
	}

	too_many_mandatory_fields() {
		if (this.mandatory.length > 7) {
			// too many fields, show form
			return true;
		}
		return false;
	}

	has_child_table() {
		if ($.map(this.mandatory, function (d) {
			return d.fieldtype === 'Table' ? d : null;
		}).length) {
			// has mandatory table, quit!
			return true;
		}
		return false;
	}

	validate_for_prompt_autoname() {
		if (this.meta.autoname && this.meta.autoname.toLowerCase() === 'prompt') {
			this.mandatory = [{
				fieldname: '__newname', label: __('{0} Name', [this.meta.name]),
				reqd: 1, fieldtype: 'Data'
			}].concat(this.mandatory);
		}
	}

	render_dialog() {
		let col_total = this.mandatory.reduce((p, c) => {
			if (c.fieldtype == "Column Break") {
				p++;
			}
			return p;
		}, 0);
		var me = this;
		this.dialog = new bcore.ui.Dialog({
			title: __("New {0}", [__(this.doctype)]),
			fields: this.mandatory.map(f => {
				if (col_total < 2) {
					f.colspan = 2;
				}
				return f;
			}),
			doc: this.doc
		});

		this.register_primary_action();
		this.render_edit_in_full_page_link();
		// ctrl+enter to save
		this.dialog.wrapper.keydown(function (e) {
			if ((e.ctrlKey || e.metaKey) && e.which == 13) {
				if (!bcore.request.ajax_count) {
					// not already working -- double entry
					me.dialog.get_primary_btn().trigger("click");
					e.preventDefault();
					return false;
				}
			}
		});

		this.dialog.onhide = () => bcore.quick_entry = null;
		this.dialog.show();

		this.dialog.refresh_dependency();
		this.set_defaults();

		if (this.init_callback) {
			this.init_callback(this.dialog);
		}
	}

	register_primary_action() {
		this.dialog.set_primary_action(__('Save'), () => {
			if (this.dialog.working) {
				return;
			}
			var data = this.dialog.get_values();

			if (data) {
				this.dialog.working = true;
				this.dialog.set_message(__('Saving...'));
				this.insert().then(() => {
					this.dialog.clear_message();
				});
			}
		});
	}

	async insert() {
		this.update_doc();
		await bcore.call({
			method: "bcore.client.save",
			args: {
				doc: this.dialog.doc
			},
			callback: (r) => {

				if (bcore.model.is_submittable(this.doctype)) {
					bcore.run_serially([
						() => this.dialog.working = true,
						() => {
							this.dialog.set_primary_action(__('Submit'), function () {
								this.submit(r.message);
							});
						}
					]);
				} else {
					this.dialog.hide();
					// delete the old doc
					bcore.model.clear_doc(this.dialog.doc.doctype, this.dialog.doc.name);
					this.dialog.doc = r.message;
					if (bcore._from_link) {
						bcore.ui.form.update_calling_link(this.dialog.doc);
					} else {
						if (this.after_insert) {
							this.after_insert(this.dialog.doc);
						} else {
							this.open_form_if_not_list();
						}
					}
				}
			},
			error: function () {
				if (!this.skip_redirect_on_error) {
					this.open_doc();
				}
			},
			freeze: true
		});

		this.dialog.working = false;
		return this.dialog.doc;
	}

	async submit(doc) {
		await bcore.call({
			method: "bcore.client.submit",
			args: {
				doc: doc
			},
			callback: async function (r) {
				this.dialog.hide();
				// delete the old doc
				bcore.model.clear_doc(this.dialog.doc.doctype, this.dialog.doc.name);
				this.dialog.doc = r.message;
				if (bcore._from_link) {
					bcore.ui.form.update_calling_link(this.dialog.doc);
				}
				await cur_frm.reload_doc();
			}
		});
	}

	open_form_if_not_list() {
		let route = bcore.get_route();
		let doc = this.dialog.doc;
		if (route && !(route[0] === 'List' && route[1] === doc.doctype)) {
			bcore.run_serially([
				() => bcore.set_route('Form', doc.doctype, doc.name)
			]);
		}
	}

	update_doc() {
		var data = this.dialog.get_values(true);
		$.each(data, (key, value) => {
			if (key === '__newname') {
				this.dialog.doc.name = value;
			}
			else {
				if (!is_null(value)) {
					this.dialog.doc[key] = value;
				}
			}
		});
		return this.dialog.doc;
	}

	open_doc() {
		this.dialog.hide();
		this.update_doc();
		bcore.set_route('Form', this.doctype, this.doc.name);
	}

	render_edit_in_full_page_link() {
		var $link = $('<div class="t-center p-1 sticky bottom-0">' +
			'<button class="edit-full btn btn-secondary">' + __("Edit in full page") + '</button></div>').appendTo(this.dialog.body);

		$link.find('.edit-full').on('click', () => {
			// edit in form
			this.open_doc();
		});
	}

	set_defaults() {
		// set defaults
		$.each(this.dialog.fields_dict, (fieldname, field) => {
			field.doctype = this.doc.doctype;
			field.docname = this.doc.name;

			if (!is_null(this.doc[fieldname])) {
				field.set_input(this.doc[fieldname]);
			}
		});
	}
}

bcore.ui.form.QuickEntryForm = QuickEntryForm;