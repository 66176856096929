import { BootInfoComponent } from "./boot_info";
import { Component } from "../../../component";

export class SessionComponent extends Component {
	/**
   * Logs user out
   */
	async logout() {
		bcore.app.logged_out = true;
		const r = bcore.call({
			method: 'logout'
		});
		if (r.exc) {
			return;
		}
		this.redirect_to_login();
	}

	/**
   * Reroutes user to login page
   */
	redirect_to_login() {
		window.location.href = '/';
	}

	/**
   * trigger session expiration behaviour
   */
	handle_session_expired() {
		if (!bcore.app.session_expired_dialog) {
			const dialog = new bcore.ui.Dialog({
				title: __('Session Expired'),
				keep_open: true,
				fields: [
					{
						fieldtype: 'Password', fieldname: 'password', colspan: 2,
						label: __('Please Enter Your Password to Continue')
					},
				],
				onhide: () => {
					if (!dialog.logged_in) {
						bcore.app.redirect_to_login();
					}
				}
			});
			dialog.set_primary_action(__('Login'), () => {
				dialog.set_message(__('Authenticating...'));
				bcore.call({
					method: 'login',
					args: {
						usr: bcore.session.user,
						pwd: dialog.get_values().password
					},
					callback: (r) => {
						if (r.message === 'Logged In') {
							dialog.logged_in = true;

							// revert backdrop
							$('.modal-backdrop').css({
								'opacity': '',
								'background-color': '#334143'
							});
						}
						dialog.hide();
					},
					statusCode: () => {
						dialog.hide();
					}
				});
			});
			bcore.app.session_expired_dialog = dialog;
		}
		if (!bcore.app.session_expired_dialog.display) {
			bcore.app.session_expired_dialog.show();
			// add backdrop
			$('.modal-backdrop').css({
				'opacity': 1,
				'background-color': '#4B4C9D'
			});
		}
	}
}