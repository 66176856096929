
	bcore.templates['form_footer'] = `<div class="form-footer">
	<div class="after-save">
		<div class="form-comments"></div>
	</div>
	<div class="pull-right scroll-to-top">
		<a onclick="bcore.utils.scroll_to(0)"><i class="fa fa-chevron-up text-muted"></i></a>
	</div>
</div>
`;
