import { WebComponentInfo } from "..";
import { Component } from "../../../component";
import { EVT_CONSTRUCT } from "../../../events";
import { EVT_COMPONENT_MOUNT, EVT_COMPONENT_UNMOUNT } from "../events";

export class DOMEventsComponent extends Component {

	[EVT_CONSTRUCT]() {

		/**
		 * @type {Map<string, Function}
		 */
		this.handlers = new Map();
	}

	/**
	 * @type {WebComponentInfo}
	 */
	get web_component() {
		return this.parent;
	}

	bind(event) {
		if (!this.handlers.has(event)) {
			this.handlers.set(event, (e) => {
				this.broadcast(`dom:${event}`, e);
			});

			if (this.web_component.is_mounted) {
				element.addEventListener(event, this.handlers.get(event));
			}
		}
	}

	unbind(event) {
		if (!this.handlers.has(event)) {
			return;
		}

		if (this.handlers.get(event)) {
			this.handlers.delete(event);
			if (this.web_component.is_mounted) {
				this.web_component.element.removeEventListener(event, this.handlers.get(event));
			}
		}
	}

	[EVT_COMPONENT_MOUNT]() {
		const element = this.web_component.element;
		for (const [event, callback] of this.handlers.entries()) {
			element.addEventListener(event, callback);
		}
	}

	[EVT_COMPONENT_UNMOUNT]() {
		const element = this.web_component.element;
		for (const [event, callback] of this.handlers.entries()) {
			element.removeEventListener(event, callback);
		}
	}
}