import { Component } from "../../../component";

export class ReactWebPage extends Component {
    make_react_page(config) {
        bcore.pages[config.name].on_page_load = function(wrapper) {
            const page = bcore.ui.make_app_page({
                parent: wrapper,
                title: config.title,
                single_column: config.single_column
            });

            const Content = config.page;
            ReactDOM.render(<Content page={page}></Content>, page.body.get(0));
        }
    }
}