
	bcore.templates['kanban_card'] = `<div class="kanban-card-wrapper {{ disable_click }}" data-name="{{name}}">
	<div class="kanban-card-redirect">
		<div class="kanban-card content">
			<div class="kanban-card-title">
				{{ title }}
			</div>
			<div class="kanban-card-meta">
			</div>
		</div>
	</div>
</div>`;
