
	bcore.templates['edit_filter'] = `<div class="filter-box bb-solid ph-1 pv-1h d-flex xxs-flex-col sm-flex-row gap-1h">
	<div class="list_filter d-flex xxs-flex-col sm-flex-row gap-1h flex-fluid">
		<div class="fieldname-select-area ui-front flex-fluid"></div>
		<div class="flex-fluid">
			<select class="condition form-control input-xs ph-1">
				{% for condition in conditions %}
				<option value="{{condition[0]}}">{{ condition[1] }}</option>
				{% endfor %}
			</select>
		</div>
		<div class="flex-fluid">
			<div class="filter-field"></div>
		</div>
	</div>
	<div class="filter-actions flex-row gap-2h flex-static ai-center">
		<a class="set-filter-and-run btn p-0 m-0 d-block">
			{{ bcore.load_icon("circle-check", " c-semantic-success p-0 m-0", "24px", 1) }}
		</a>
		<a class="remove-filter btn p-0 m-0 d-block">
			{{ bcore.load_icon("circle-x", "c-semantic-error p-0 m-0", "24px", 1) }}
		</a>
	</div>
</div>`;
