
	bcore.templates['group_by'] = `<div class="groupby-box bt-solid p-1h hide">
	<div class="list_groupby flex-row ai-center gap-1h">
		<div class="form-group ui-front flex-fluid max-w-35p">
			<select class="groupby form-control input-sm">
				<option value="" disabled selected>{{ __("Select Group By...") }}</option>
				{% for (var parent_doctype in groupby_conditions) { %}
					{% for (var val in groupby_conditions[parent_doctype]) { %}
						{% if (parent_doctype !== doctype) { %}
						<option 
							data-doctype="{{parent_doctype}}"
							value="{{groupby_conditions[parent_doctype][val].fieldname}}">
							{{ groupby_conditions[parent_doctype][val].label }}
							({{ parent_doctype }})
						</option>
						{% } else { %}
						<option 
							data-doctype="{{parent_doctype}}"
							value="{{groupby_conditions[parent_doctype][val].fieldname}}">
							{{ groupby_conditions[parent_doctype][val].label }}
						</option>
						{% } %}
					{% } %}
				{% } %}
			</select>
		</div>
		<div class="form-group flex-fluid max-w-35p">
			<select class="aggregate-function form-control input-sm">
				{% for condition in aggregate_function_conditions %}
				<option value="{{condition.name}}">{{ condition.label }}</option>
				{% endfor %}
			</select>
		</div>
		<div class="flex-row flex-fluid gap-1h max-w-35p">
			<div class="groupby-field">
				<select class="aggregate-on form-control input-sm hide">
					<option value="" disabled selected>{{ __("Select Field...") }}</option>
				</select>
			</div>
			<div class="groupby-actions">
				<a class="btn btn-error flex-row ai-center jc-center remove-groupby">
					<i class="octicon octicon-trashcan visible-xs"></i>
					<span class="hidden-xs">{{ __("Remove") }}</span>
				</a>
			</div>
		</div>
	</div>
</div>
`;
