import { ChildComponent } from "../../components/child";
import { TaggedComponent } from "../../components/tagged";
import { Compose, withMixins } from "../../compose";
import { TAG_INITIALIZED, TAG_MOUNTED } from "../../tags";
import { EVT_INIT, EVT_CONSTRUCT, EVT_AFTER_INIT, EVT_BROADCAST } from "../../events";
import { EVT_COMPONENT_UPDATE, EVT_COMPONENT_MOUNT, EVT_COMPONENT_UNMOUNT } from "./events";
import { EVT_UPDATE, EVT_MOUNT, EVT_SET_ATTRIBUTE, EVT_UNMOUNT } from "../web_components/events";
import { BreakpointSupportComponent } from "./components/breakpoint_support";
import { ContextComponent } from "./components/context";
import { equals } from "../../utils";
import { DraggableComponent } from "./components/draggable";
import { DropzoneComponent } from "./components/dropzone";
import { ParentComponent } from "../../components/parent";
import { DOMEventsComponent } from "./components/dom_events";

/**
 * A facade controller for web components. Abstracts implementation details from different
 * libraries.
 * @class WebComponentInfo
 */
export class WebComponentInfo extends Compose(
	ChildComponent,
	ParentComponent,
	TaggedComponent,
	withMixins(BreakpointSupportComponent,
		"update_breakpoint"
	),
	withMixins(ContextComponent,
		"get_context",
		"set_context"
	),
	DraggableComponent,
	DropzoneComponent,
	DOMEventsComponent
) {

	constructor(element, shadow, config) {
		super(element, shadow, config);

		/**
		 * @type {HTMLElement} The component's dom element
		 */
		this.element = element;

		/**
		 * @type {ShadowRoot} The component's shadow root
		 */
		this.shadow = shadow;
		this.config = config;
		this.props = new Map();
		this.types = new Map();

		if (config.props) {
			for (const key of Object.keys(config.props)) {
				this.define_attribute(key, Reflect.get(config.props, key));
			}
		}

		this.props.set("$web_component", this);
	}

	/**
   * Track an attribute and its data converter.
   * @param {*} name The attribute name.
   * @param {*} converter A function to convert any passed value to its final type.
   */
	define_attribute(name, converter) {
		this.types.set(name, converter);
	}

	/**
   * Returns true if component is ready
   * @type {boolean}
   */
	get initialized() {
		return this[TaggedComponent].has_tag(TAG_INITIALIZED);
	}

	/**
   * Returns true if component is mounted
   * @type {boolean}
   */
	get is_mounted() {
		return this[TaggedComponent].has_tag(TAG_MOUNTED);
	}

	/**
   * Handles event init process
   */
	async [EVT_INIT]() {
		await this[TaggedComponent].add_tag(TAG_INITIALIZED);
	}

	/**
   * Sets an attribute on the component
   * @param {string} name The attribute name
   * @param {*} value The attribute value
   */
	set_attribute(name, value) {
		if (!this.types.has(name)) {
			return;
		}

		const conv = this.types.get(name);
		if (typeof conv === "function") {
			value = conv(value);
		}

		const current_value = this.props.get(name);
		if (!equals(value, current_value)) {
			this.props.set(name, value);
			this.element.update();
			return this.broadcast(EVT_SET_ATTRIBUTE, this, name, current_value, value)
				.then(async () => {
					if (this.config.on_prop && Reflect.has(this.config.on_prop, name)) {
						try {
							await Promise.resolve(this.config.on_prop[name](this, current_value, value));
						} catch (err) {
							console.error(err);
						}
					}
				});
		}
	}

	/**
   * Returns an attribute value
   * @param {string} key 
   */
	get_attribute(key) {
		return this.props.get(key);
	}

	/**
   * Listens for update events from the web component controller.
   * Upon finding an update event for this controller, will trigger an internal broadcast
   * to internal components.
   * @param {*} component 
   */
	[EVT_UPDATE](component) {
		if (component === this) {
			this.broadcast(EVT_COMPONENT_UPDATE);
		}
	}

	[EVT_MOUNT](component) {
		if (component === this) {
			this.broadcast(EVT_COMPONENT_MOUNT);
		}
	}

	[EVT_UNMOUNT](component) {
		if (component === this) {
			this.broadcast(EVT_COMPONENT_UNMOUNT);
		}
	}

	async [EVT_BROADCAST](event, ...args) {
		if (this.config.events && Reflect.has(this.config.events, event)) {
			try {
				await Promise.resolve(this.config.events[event](...args));
			} catch (err) {
				console.error(err);
			}
		}
	}
}