import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";
import { MetadataCacheComponent } from "./metadata_cache";

/**
 * Sets all bcore API globals
 */
export class GlobalsComponent extends ComponentDependencies(BootInfoComponent, MetadataCacheComponent) {
	on_boot() {
		bcore.session.user = bcore.boot.user.name;
		bcore.session.user_email = bcore.boot.user.email;
		bcore.session.user_fullname = bcore.user_info().fullname;

		bcore.user_defaults = bcore.boot.user.defaults;
		bcore.user_roles = bcore.boot.user.roles || [];
		bcore.sys_defaults = bcore.boot.sysdefaults;

		bcore.ui.py_date_format = bcore.boot.sysdefaults.date_format.replace('dd', '%d').replace('mm', '%m').replace('yyyy', '%Y');
		bcore.boot.user.last_selected_values = {};

		// Proxy for user globals
		Object.defineProperties(window, {
			'user': {
				get: function () {
					console.warn('Please use `bcore.session.user` instead of `user`. It will be deprecated soon.');
					return bcore.session.user;
				}
			},
			'user_fullname': {
				get: function () {
					console.warn('Please use `bcore.session.user_fullname` instead of `user_fullname`. It will be deprecated soon.');
					return bcore.session.user;
				}
			},
			'user_email': {
				get: function () {
					console.warn('Please use `bcore.session.user_email` instead of `user_email`. It will be deprecated soon.');
					return bcore.session.user_email;
				}
			},
			'user_defaults': {
				get: function () {
					console.warn('Please use `bcore.user_defaults` instead of `user_defaults`. It will be deprecated soon.');
					return bcore.user_defaults;
				}
			},
			'roles': {
				get: function () {
					console.warn('Please use `bcore.user_roles` instead of `roles`. It will be deprecated soon.');
					return bcore.user_roles;
				}
			},
			'sys_defaults': {
				get: function () {
					console.warn('Please use `bcore.sys_defaults` instead of `sys_defaults`. It will be deprecated soon.');
					return bcore.user_roles;
				}
			}
		});
	}
}