import { Component } from "../../../component";

/**
 * Enables realtime build feedback errors when developer_mode is enabled on
 * site_config.json
 */
export class BuildFeedbackComponent extends Component {
	on_startup() {
		if (bcore.boot.developer_mode) {
			bcore.realtime.on('build_error', (data) => {
				console.error(data);
			});
		}
	}
}