
	bcore.templates['set_sharing'] = `<div class="flex-col gap-1h p-1h">

	<div class="grid-5x1">
		<div class="col-span-2">
			<h6>{%= __("User") %}</h6>
		</div>
		<div class="flex-row ai-center jc-center">
			<h6>{%= __("Can Read") %}</h6>
		</div>
		<div class="flex-row ai-center jc-center">
			<h6>{%= __("Can Write") %}</h6>
		</div>
		<div class="flex-row ai-center jc-center">
			<h6>{%= __("Can Share") %}</h6>
		</div>
	</div>

	<div class="grid-5x1" data-everyone=1>
		<div class="col-span-2 share-all" style="height: 30px;"><b>{{ __("Everyone") }}</b></div>
		<div class="flex-row ai-center jc-center">
			<input 
				type="checkbox"
				name="read"
				{% if(cint(everyone.read)) { %}checked{% } %}
				class="edit-share"
			/>
		</div>
		<div class="flex-row ai-center jc-center">
			<input
				type="checkbox"
				name="write"
				{% if(cint(everyone.write)) { %}checked{% } %}
				class="edit-share"
				{% if (!frm.perm[0].write){ %} disabled="disabled" {% } %}
			/>
		</div>
		<div class="flex-row ai-center jc-center">
			<input
				type="checkbox"
				name="share"
				{% if(cint(everyone.share)) { %}checked{% } %}
				class="edit-share"
			/>
		</div>
	</div>

	{% for (var i=0, l=shared.length; i < l; i++) { 
		let s=shared[i]; 
		if(s && !s.everyone) { 
	%}
	<div class="grid-5x1 shared-user" data-user="{%= s.user %}" data-name="{%= s.name %}">
		<div class="col-span-2">{%= s.user %}</div>
		<div class="flex-row ai-center jc-center">
			<input
				type="checkbox"
				name="read"
				{% if(cint(s.read)) { %}checked{% } %}
				class="edit-share"
			/>
		</div>
		<div class="flex-row ai-center jc-center">
			<input 
				type="checkbox"
				name="write"
				{% if(cint(s.write)) { %}checked{% } %}
				class="edit-share"
				{% if (!frm.perm[0].write){ %} disabled="disabled" {% } %}
			/>
		</div>
		<div class="flex-row ai-center jc-center">
			<input
				type="checkbox"
				name="share"
				{% if(cint(s.share)) { %}checked{% } %}
				class="edit-share"
			/>
		</div>
	</div>
	{% } %}
{% } %}

{% if(bcore.model.can_share(null, frm)) { %}
<hr>

<div class="grid-5x1">
	<div class="col-span-2">
		<h6>{%= __("Share this document with") %}</h6>
	</div>
	<div class="flex-row ai-center jc-center">
		<h6>{%= __("Can Read") %}</h6>
	</div>
	<div class="flex-row ai-center jc-center">
		<h6>{%= __("Can Write") %}</h6>
	</div>
	<div class="flex-row ai-center jc-center">
		<h6>{%= __("Can Share") %}</h6>
	</div>
</div>

<div class="grid-5x1">
	<div class="col-span-2 input-wrapper-add-share pr-2"></div>
	<div class="flex-row ai-center jc-center"><input type="checkbox" class="add-share-read" name="read"></div>
	<div class="flex-row ai-center jc-center"><input type="checkbox" class="add-share-write" name="write" {% if (!frm.perm[0].write){ %} disabled="disabled"
			{% } %}></div>
	<div class="flex-row ai-center jc-center"><input type="checkbox" class="add-share-share" name="share"></div>
</div>
<p>
	<button class="btn btn-primary btn-add-share flex-row ai-center gap-1h">{{ __("Add") }}</button>
</p>
{% endif %}
</div>`;
