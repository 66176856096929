import { Compose } from "../../compose";
import { EVT_INIT } from "../../events";
import { EVT_NOTIFICATION_UPDATE } from "./events";
import { OpenDocumentsComponent } from "./components/open";
import { GeneralNotificationsComponent } from "./components/general";
import { TodayEventsComponents } from "./components/events";

export class NotificationsController extends Compose(
	GeneralNotificationsComponent,
	TodayEventsComponents,
	OpenDocumentsComponent
) {
	async [EVT_INIT]() {
		this.notifications_settings = await bcore.model.with_doc('Notification Settings', bcore.session.user);
		this.user = bcore.session.user;

		bcore.search.utils.make_function_searchable(
			() => {
				bcore.set_route(`#Form/Notification Settings/${bcore.session.user}`);
			},
			__('Notification Settings'),
		);

		document.getElementById('notifications-icon').addEventListener("click", () => {
			bcore.app.extras_panel.toggle_panel('notifications');
		});

	}

	async update(limit) {
		this.info = await bcore.xcall('bcore.desk.notifications.get_notification_info');
		this.notifications = [];
		await this.broadcast("update", limit);
		this.broadcast(EVT_NOTIFICATION_UPDATE, this.notifications);
	}

	async mark_all_as_read() {
		await bcore.call(
			'bcore.desk.doctype.notification_log.notification_log.mark_all_as_read',
		);
		this.update(100);
	}

	async set_notification_as_read(docname) {
		await bcore.call(
			'bcore.desk.doctype.notification_log.notification_log.mark_as_read',
			{ docname: docname }
		)
		this.update(100);
	}

}