import { Component } from "../../component";
import { EVT_INIT } from "../../events";
import { EVT_FORM_LOAD, EVT_FORM_REFRESH, EVT_FORM_RENAME, EVT_FORM_UNLOAD } from "./events";

export class FormEventsComponent extends Component {
	[EVT_INIT]() {
		// Using old jquery listeners while we still use old form codebase
		$(document).on("form-refresh", (e, frm) => this.broadcast(EVT_FORM_REFRESH, frm));
		$(document).on("form-rename", (e, frm) => this.broadcast(EVT_FORM_RENAME, frm));
		$(document).on("form-unload", (e, frm) => this.broadcast(EVT_FORM_UNLOAD, frm));
		$(document).on("form-load", (e, frm) => this.broadcast(EVT_FORM_LOAD, frm));
	}
}
