import { SocialHome } from './social_home';
import { Factory } from '../views/factory';

export class SocialFactory extends Factory {
	show() {
		if (bcore.pages.social) {
			bcore.container.change_to('social');
		} else {
			this.make('social');
		}
	}

	make(page_name) {
		bcore.social.home = new SocialHome({
			parent: this.make_page(true, page_name)
		});
	}
};

bcore.views.SocialFactory = SocialFactory