
	bcore.templates['sort_selector'] = `<div class="sort-selector d-flex ai-center gap-1h ml-auto">
	<div class="dropdown">
		<a class="text-muted dropdown-toggle small"
			data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
			<span class="dropdown-text">{{ __(sort_by_label) }}</span>
		</a>
		<ul class="dropdown-menu dropdown-menu-right">
			{% for value in options %}
			<li>
				<a class="option" data-value="{{ value.fieldname }}">
					{{ __(value.label) }}
				</a>
			</li>
			{% endfor %}
		</ul>
	</div>
	<button class="btn btn-order p-0"
		data-value="{{ sort_order }}">
		{{ bcore.load_icon("sort-descending", "direction desc p-0 m-0 " + (sort_order!="desc"?"hide":""), "18px", 1) }}
		{{ bcore.load_icon("sort-ascending", "direction asc p-0 m-0 " + (sort_order=="desc"?"hide":""), "18px", 1) }}
	</button>
</div>`;
