import { Compose, withMixins } from "../../compose";
import { EVT_AFTER_INIT } from "../../events";
import { VueSetupComponent } from "./components/vue_setup";
import { BootInfoComponent } from "./components/boot_info";
import { MetadataCacheComponent } from "./components/metadata_cache";
import { GlobalsComponent } from "./components/globals";
import { PageCachingComponent } from "./components/page_caching";
import { BootGuestsComponent } from "./components/boot_guests";
import { AppLogoComponent } from "./components/app_logo";
import { ToolBarComponent } from "./components/toolbar";
import { FavIconComponent } from "./components/fav_icon";
import { MixpanelIntegrationComponent } from "./components/mixpanel_integration";
import { UserPermissionsComponent } from "./components/user_permissions";
import { FullWidthComponent } from "./components/fullwidth";
import { EnergyPointsComponent } from "./components/energy_points";
import { BootstrapKeyboardComponent } from "./components/bootstrap_keyboard";
import { RTLSupportComponent } from "./components/rtl_support";
import { LastRouteComponent } from "./components/last_route";
import { PageContainerComponent } from "./components/page_container";
import { BootMessagesComponent } from "./components/boot_messages";
import { ChangeLogComponent } from "./components/change_log";
import { ShowNotesComponents } from "./components/show_notes";
import { SetupDialogComponent } from "./components/setup_dialog";
import { CSRFComponent } from "./components/csrf";
import { VersionUpdateComponent } from "./components/version_update";
import { SocialComponent } from "./components/social";
import { BuildFeedbackComponent } from "./components/build_feedback";
import { EmailComponent } from "./components/email";
import { DeveloperUtilsComponent } from "./components/developer_utils";
import { ModulesComponent } from "../common/components/modules";
import { LinkPreviewComponent } from "./components/link_preview";
import { SessionComponent } from "./components/session";
import { ActionsComponent } from "../common/components/actions";
import { BreadcrumbsComponent } from "../common/components/breadcrumbs";
import "../page";
import { RouterInitComponent } from "./components/route_init";
import { WaitForDOMComponent } from "../../components/wait_for_dom";
import { ExtrasPanelComponent } from "./components/extras_panel";
import { NotificationsComponent } from "./components/notifications";

bcore.provide('bcore.app');
bcore.provide('bcore.desk');

/**
 * Application controller for desk
 */
export class Application extends Compose(
	WaitForDOMComponent,
	BootInfoComponent,
	ExtrasPanelComponent,
	BootGuestsComponent,
	VueSetupComponent,
	MetadataCacheComponent,
	GlobalsComponent,
	RouterInitComponent,
	PageCachingComponent,
	UserPermissionsComponent,
	AppLogoComponent,
	ToolBarComponent,
	FavIconComponent,
	MixpanelIntegrationComponent,
	FullWidthComponent,
	EnergyPointsComponent,
	BootstrapKeyboardComponent,
	RTLSupportComponent,
	LastRouteComponent,
	PageContainerComponent,
	BootMessagesComponent,
	ChangeLogComponent,
	ShowNotesComponents,
	SetupDialogComponent,
	CSRFComponent,
	VersionUpdateComponent,
	SocialComponent,
	BuildFeedbackComponent,
	DeveloperUtilsComponent,
	ModulesComponent,
	LinkPreviewComponent,
	NotificationsComponent,
	withMixins(SessionComponent,
		"handle_session_expired",
		"redirect_to_login",
		"logout"
	),
	withMixins(ActionsComponent,
		"trigger_primary_action"
	),
	BreadcrumbsComponent
) {
	async [EVT_AFTER_INIT]() {

		// trigger app startup
		$(document).trigger("startup");
		$(document).trigger("app_ready");
		await this.broadcast("startup");

		bcore.tags.utils.fetch_tags();
	}
}
