// for link titles
bcore._link_titles = {};

bcore.get_link_title = function(doctype, name) {
	if (!doctype || !name) {
		return;
	}

	return bcore._link_titles[doctype + "::" + name];
};

bcore.add_link_title = function (doctype, name, value) {
	if (!doctype || !name) {
		return;
	}

	bcore._link_titles[doctype + "::" + name] = value;
};

bcore.set_link_title =  function(f) {
	let doctype = f.get_options();
	let docname = f.get_input_value();

	if ((!in_list(bcore.boot.doctypes_with_show_link_field_title, doctype)) || (!doctype || !docname) ||
		(bcore.get_link_title(doctype, docname))) {
		return;
	}

	bcore.xcall("bcore.desk.search.get_link_title", {"doctype": doctype, "docname": docname}).then((r) => {
		if (r && docname !== r) {
			f.set_input_label(r);
		}
	});
};