import { useEffect, useState } from "react";

/**
 * Simple wait for promise hook. Calls the callback once the promise is resolved.
 * @param {Promise<any>} promise A promise to wait for
 * @param {Function} callback A callback used in the .then() callback.
 */
export const use_wait_promise = (promise, callback) => {
	useEffect(() => {
		promise.then(callback);
	}, [])
};

/**
 * Returns true once the web component has finished loading external stylesheets and assets.
 * @param {WebComponentInfo} wc The web component api instance
 * @param  {...Promise<any>} promises Any extra promises to wait for.
 * @returns {boolean} Returns true when the component(and my extra promises) have resolved.
 */
export const use_wc_ready = (wc, ...promises) => {
	const [ready, setReady] = useState(false);
	use_wait_promise(Promise.allSettled([wc.loading_promise, ...promises]), () => {
		setReady(true);
	});

	return ready
};