export default `
:host {
  display: flex;
}
:host #__mountpoint {
  flex: 1 1 auto;
  display: flex;
  padding: 0;
  margin: 0;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}
:host .skeleton {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}
:host .skeleton.min {
  min-width: 80px;
  min-height: 20px;
}
:host .skeleton.tiny {
  padding: 0;
}
:host .skeleton .skeleton-row {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
:host .skeleton .skeleton-col {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
:host .skeleton .skeleton-line,
:host .skeleton .skeleton-icon,
:host .skeleton .skeleton-box {
  width: 100%;
  height: 10px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0) 100%), lightgray;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  background-position: -80% 0;
  -webkit-animation: skeleton_shine 1.5s infinite;
          animation: skeleton_shine 1.5s infinite;
}
:host .skeleton .skeleton-box {
  height: unset;
  flex: 1 1 auto;
}
:host .skeleton .skeleton-icon {
  border-radius: 100%;
  position: relative;
  height: unset;
  flex: 1 1 auto;
}
:host .skeleton .skeleton-icon:before {
  content: "";
  display: block;
  padding-top: 100%;
}

@-webkit-keyframes skeleton_shine {
  to {
    background-position: 240% 0, 0 0;
  }
}

@keyframes skeleton_shine {
  to {
    background-position: 240% 0, 0 0;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL2Jjb3JlL2Jjb3JlL2Jjb3JlL3B1YmxpYy9qcy9iY29yZS9jb250cm9sbGVycy93ZWJfY29tcG9uZW50cy9jb21wb25lbnRzL3dlYl9jb21wb25lbnRfZmFjdG9yeS5zY3NzIiwiJTNDaW5wdXQlMjBjc3MlMjBscnRfX08lM0UiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDQyxhQUFBO0FDQ0Q7QURDQztFQUNDLGNBQUE7RUFDQSxhQUFBO0VBQ0EsVUFBQTtFQUNBLFNBQUE7RUFDQSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSxrQkFBQTtBQ0NGO0FERUM7RUFDQyxrQkFBQTtFQUNBLGNBQUE7RUFDQSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxRQUFBO0VBQ0EsWUFBQTtBQ0FGO0FERUU7RUFDQyxlQUFBO0VBQ0EsZ0JBQUE7QUNBSDtBREdFO0VBQ0MsVUFBQTtBQ0RIO0FESUU7RUFDQyxjQUFBO0VBQ0EsYUFBQTtFQUNBLG1CQUFBO0VBQ0EsUUFBQTtBQ0ZIO0FES0U7RUFDQyxjQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsUUFBQTtBQ0hIO0FETUU7OztFQUdDLFdBQUE7RUFDQSxZQUFBO0VBQ0EsY0FBQTtFQUNBLGtCQUFBO0VBQ0EsbUlBQUE7RUFPQSwyQkFBQTtFQUNBLDJCQUFBO0VBQ0EsMkJBQUE7RUFDQSwrQ0FBQTtVQUFBLHVDQUFBO0FDVkg7QURhRTtFQUNDLGFBQUE7RUFDQSxjQUFBO0FDWEg7QURjRTtFQUNDLG1CQUFBO0VBQ0Esa0JBQUE7RUFDQSxhQUFBO0VBQ0EsY0FBQTtBQ1pIO0FEZUU7RUFDQyxXQUFBO0VBQ0EsY0FBQTtFQUNBLGlCQUFBO0FDYkg7O0FEa0JBO0VBQ0M7SUFDQyxnQ0FBQTtFQ2ZBO0FBQ0Y7O0FEWUE7RUFDQztJQUNDLGdDQUFBO0VDZkE7QUFDRiIsImZpbGUiOiJ0by5jc3MifQ== */`;
