
	bcore.templates['form_links'] = `<div class="form-documents dashboard-grid col-gap-1 row-gap-1 mt-1">
	{% for (var i=0; i < transactions.length; i++) { %}
	<div class="flex-col row-span-{{transactions[i].items.length + 2}}">
		<div class="m-0 p-0 f-upper fw-bolder bb-solid h-1">{{ transactions[i].label }}</div>
		{% for (var j=0; j < transactions[i].items.length; j++) {
			var doctype = transactions[i].items[j]; %}
		<div class="document-link f-1h flex-row bb-dotted gap-1h child:h-1 ai-center"
			data-doctype="{{ doctype }}">
			<a class="badge-link small flex-row ai-center">{{ __(doctype) }}</a>
			<span class="count bg-semantic-info c-white round-2 min-w-16px h-1rem flex-row f-2h ai-center jc-center hide"></span>
			<span class="open-notification bg-semantic-error c-white round-2 min-w-16px h-1rem flex-row f-2h ai-center jc-center hide"
				title="{{ __("Open {0}", [__(doctype)])}}"></span>
			<span class="flex-fluid"></span>
			{% if !internal_links[doctype] %}
				<button 
					class="btn-sm btn-new flex-row ai-center jc-center p-0 hidden"
					data-doctype="{{ doctype }}"
				>
					{{ bcore.load_icon("square-plus", "p-0 m-0", "1rem", 1) }}
				</button>
			{% endif %}
		</div>
		{% } %}
	</div>
	{% } %}
</div>
`;
