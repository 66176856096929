import Widget from "./base_widget.js";

bcore.provide("bcore.utils");

export default class NumberCardWidget extends Widget {
	constructor(opts) {
		super(opts);
	}

	get_config() {
		return {
			name: this.name,
			label: this.label,
			color: this.color,
			hidden: this.hidden,
		};
	}

	refresh() {
		this.set_body();
	}

	set_body() {
		this.widget.addClass("number-widget-box");
		this.make_card();
	}

	set_title() {
		$(this.title_field).html(`<div class="number-label f-3">${this.card_doc.label}</div>`);
	}

	make_card() {
		bcore.model.with_doc('Number Card', this.name).then(card => {
			if (!card) {
				if (this.document_type) {
					bcore.run_serially([
						() => this.create_number_card(),
						() => this.render_card(),
					]);
				} else {
					// widget doesn't exist so delete
					this.delete(false);
					return;
				}
			} else {
				this.card_doc = card;
				this.render_card();
			}
			this.set_events();
		});
	}

	create_number_card() {
		this.set_doc_args();
		return bcore.xcall(
			'bcore.desk.doctype.number_card.number_card.create_number_card',
			{
				'args': this.card_doc
			}
		).then(doc => {
			this.name = doc.name;
			this.card_doc = doc;
			this.widget.attr('data-widget-name', this.name);
		});
	}

	set_events() {
		$(this.body).click(() => {
			if (this.in_customize_mode || this.card_doc.type == 'Custom' || this.card_doc.type == 'Script') return;
			this.set_route();
		});
	}

	set_route() {
		const is_document_type = this.card_doc.type !== 'Report';
		bcore.model.with_doctype(this.card_doc.document_type, () => {
			let is_child = bcore.get_meta(this.card_doc.document_type).istable;
			let name;
			if (is_child) {
				let doc = bcore.get_doc("DocField", {"fieldtype": "Table", "options": this.card_doc.document_type});
				name = doc.parent ? doc.parent : "";
			} else {
				name = is_document_type ? this.card_doc.document_type : this.card_doc.report_name;
			}
			const route = bcore.utils.generate_route({
				name: name,
				type: is_document_type ? 'doctype' : 'report',
				is_query_report: !is_document_type,
			});
			if (is_document_type) {
				const filters = JSON.parse(this.card_doc.filters_json);
				bcore.route_options = filters.reduce((acc, filter) => {
					return Object.assign(acc, {
						[`${filter[0]}.${filter[1]}`]: [filter[2], filter[3]]
					});
				}, {});
			}

			bcore.set_route(route);
		});
	}

	set_doc_args() {
		this.card_doc = Object.assign({}, {
			document_type: this.document_type,
			label: this.label,
			function: this.function,
			aggregate_function_based_on: this.aggregate_function_based_on,
			color: this.color,
			filters_json: this.stats_filter
		});
	}

	get_settings(type) {
		this.filters = this.get_filters();
		this.or_filters = this.get_or_filters();
		const settings_map = {
			'Custom': {
				method: this.card_doc.method,
				args: {
					filters: this.filters,
					or_filters: this.or_filters
				},
				get_number: res => this.get_number_for_custom_card(res),
			},
			'Report': {
				method: 'bcore.desk.query_report.run',
				args: {
					report_name: this.card_doc.report_name,
					filters: this.filters,
					ignore_prepared_report: 1
				},
				get_number: res => this.get_number_for_report_card(res),
			},
			'Document Type': {
				method: 'bcore.desk.doctype.number_card.number_card.get_result',
				args: {
					doc: this.card_doc,
					filters: this.filters,
					or_filters: this.or_filters
				},
				get_number: res => this.get_number_for_doctype_card(res),
			},
			'Script': {
				method: 'bcore.desk.doctype.number_card.number_card.get_result',
				args: {
					doc: this.card_doc,
					filters: this.filters,
					or_filters: this.or_filters
				},
				get_number: res => this.get_number_for_doctype_card(res),
			}
		};
		return settings_map[type];
	}

	get_filters() {
		const filters = bcore.dashboard_utils.get_all_filters(this.card_doc);
		return filters;
	}

	get_or_filters() {
		return bcore.dashboard_utils.get_or_filters(this.card_doc);
	}

	render_card() {
		this.prepare_actions();
		this.set_title();
		this.set_loading_state();

		if (!this.card_doc.type) {
			this.card_doc.type = 'Document Type';
		}

		this.settings = this.get_settings(this.card_doc.type);

		bcore.run_serially([
			() => this.render_number(),
			() => this.render_stats(),
		]);
	}

	set_loading_state() {
		$(this.body).html(`<div class="number-card-loading text-muted f-3 pl-2 pb-2">
			${__('Loading...')}
		</div>`);
	}

	get_number() {
		return bcore.xcall(this.settings.method, this.settings.args).then(res => {
			return this.settings.get_number(res);
		});
	}

	get_number_for_custom_card(res) {
		if (typeof res === 'object') {
			this.number = res.value;
			this.get_formatted_number(res);
		} else {
			this.formatted_number = res;
		}
	}

	get_number_for_doctype_card(res) {
		this.number = res;
		if (this.card_doc.function !== 'Count') {
			return bcore.model.with_doctype(this.card_doc.document_type, () => {
				const based_on_df =
					bcore.meta.get_docfield(this.card_doc.document_type, this.card_doc.aggregate_function_based_on);
				this.get_formatted_number(based_on_df);
			});
		} else {
			this.formatted_number = res;
		}
	}

	get_number_for_report_card(res) {
		const field = this.card_doc.report_field;
		const vals = res.result.reduce((acc, col) => {
			col[field] && acc.push(col[field]);
			return acc;
		}, []);
		this.number = bcore.report_utils.get_result_of_fn(this.card_doc.report_function, vals);
		this.formatted_number = this.number;
	}

	get_formatted_number(df) {
		const default_country = bcore.sys_defaults.country;
		const shortened_number = bcore.utils.shorten_number(this.number, default_country);
		let number_parts = shortened_number.split(' ');

		const symbol = number_parts[1] || '';
		const formatted_number = $(bcore.format(number_parts[0], df)).text();

		this.formatted_number = formatted_number + ' ' + symbol;
	}

	render_number() {
		return this.get_number().then(() => {
			$(this.body).html(`<div class="widget-content d-flex jc-space-between pt-3">
				<div class="number as-end pl-1 pb-1 fw-bold f-3" style="color:${this.card_doc.color}">${this.formatted_number}</div>
				</div>`);
		});
	}

	render_stats() {
		if (this.card_doc.type !== 'Document Type') {
			return;
		}

		let caret_html = '';
		let color_class = '';

		return this.get_percentage_stats().then(() => {
			if (this.percentage_stat == undefined) return;

			if (this.percentage_stat == 0) {
				color_class = 'grey-stat';
			} else if (this.percentage_stat > 0) {
				caret_html = '<i class="fa fa-caret-up"></i>';
				color_class = 'green-stat';
			} else {
				caret_html = '<i class="fa fa-caret-down"></i>';
				color_class = 'red-stat';
			}

			const stats_qualifier_map = {
				'Daily': __('since yesterday'),
				'Weekly': __('since last week'),
				'Monthly': __('since last month'),
				'Yearly': __('since last year')
			};
			const stats_qualifier = stats_qualifier_map[this.card_doc.stats_time_interval];

			$(this.body).find('.widget-content').append(`<div class="card-stats flex-col pr-1 ${color_class}">
				<span class="percentage-stat t-right">
					${caret_html}
					${bcore.utils.shorten_number(Math.abs(this.percentage_stat))} %
				</span>
				<span class="stat-period text-muted">
					${stats_qualifier}
				</span>
			</div>`);
		});
	}

	get_percentage_stats() {
		return bcore.xcall('bcore.desk.doctype.number_card.number_card.get_percentage_difference', {
			doc: this.card_doc,
			filters: this.filters,
			or_filters: this.or_filters,
			result: this.number
		}).then(res => {
			if (res !== undefined) {
				this.percentage_stat = res;
			}
		});
	}

	prepare_actions() {
		let actions = [
			{
				label: __('Refresh'),
				action: 'action-refresh',
				handler: () => {
					this.render_card();
				}
			},
			{
				label: __('Edit'),
				action: 'action-edit',
				handler: () => {
					bcore.set_route(
						'Form',
						'Number Card',
						this.name
					);
				}
			},
		];

		this.set_card_actions(actions);
	}

	set_card_actions(actions) {
		/* eslint-disable indent */
		this.card_actions =
			$(`<div class="card-actions dropdown pull-right">
				<a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<button class="btn pv-1h ph-1"><span class="caret"></span></button>
				</a>
				<ul class="dropdown-menu" style="max-height: 300px; overflow-y: auto;">
					${actions
						.map(
							action =>
								`<li>
									<a data-action="${action.action}">${action.label}</a>
								</li>`
						).join('')}
				</ul>
			</div>`);
		/* eslint-disable indent */

		this.card_actions.find("a[data-action]").each((i, o) => {
			const action = o.dataset.action;
			$(o).click(actions.find(a => a.action === action));
		});

		this.action_area.html(this.card_actions);
	}
}
