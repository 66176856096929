
export class Viewers {
	constructor(opts) {
		Object.assign(this, opts);
	}
	get_viewers() {
		let docinfo = this.frm.get_docinfo();
		if (docinfo) {
			return docinfo.viewers || {};
		} else {
			return {};
		}
	}
	refresh(data_updated) {
		if (this.frm.doc.__islocal) {
			this.parent.toggle(false);
			this.frm.sidebar.sidebar.find(".viewers-label").toggle(false);
			return;
		}
		this.frm.sidebar.sidebar.find(".viewers-label").toggle(true);
		this.parent.toggle(true);
		this.parent.empty();
		var viewers = this.get_viewers();
		var users = [];
		var new_users = [];
		for (var i=0, l=(viewers.current || []).length; i < l; i++) {
			var username = viewers.current[i];
			if (username===bcore.session.user) {
				// current user
				continue;
			} 
			var user_info =  bcore.user_info(username);
			users.push(bcore.avatar(username));

			if (viewers.new.indexOf(username)!==-1) {
				new_users.push(user_info.fullname);
			}
		}
		if (users.length) {
			this.parent.parent().removeClass("hide");
			this.parent.append(users.join(' '));
		} else {
			this.parent.parent().addClass("hide");
		}

		if (data_updated && new_users.length) {
			// new user viewing this document, who wasn't viewing in the past
			if (new_users.length===1) {
				bcore.show_alert(__("{0} is currently viewing this document", [new_users[0]]));
			} else {
				bcore.show_alert(__("{0} are currently viewing this document", [bcore.utils.comma_and(new_users)]));
			}

		}
	}
}

bcore.ui.form.Viewers = Viewers;

export const set_viewers = bcore.ui.form.set_viewers = function(data) {
	var doctype = data.doctype;
	var docname = data.docname;
	var docinfo = bcore.model.get_docinfo(doctype, docname);
	var past_viewers = ((docinfo && docinfo.viewers) || {}).past || [];
	var viewers = data.viewers || [];

	var new_viewers = viewers.filter(viewer => !past_viewers.includes(viewer));

	bcore.model.set_docinfo(doctype, docname, "viewers", {
		past: past_viewers.concat(new_viewers),
		new: new_viewers,
		current: viewers
	});

	if (cur_frm && cur_frm.doc && cur_frm.doc.doctype===doctype && cur_frm.doc.name==docname) {
		cur_frm.viewers && cur_frm.viewers.refresh(true);
	}
}
