import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

/**
 * Developer utilities to enable when developer_mode is set in site_config.json
 */
export class DeveloperUtilsComponent extends ComponentDependencies(BootInfoComponent) {
	on_startup() {
		if (!bcore.boot.developer_mode) {
			// Fetches scheduler status every 5 minutes      
			setInterval(function() {
				bcore.call({
					method: 'bcore.core.page.background_jobs.background_jobs.get_scheduler_status',
					callback: function(r) {
						if (r.message[0] == __("Inactive")) {
							bcore.call('bcore.utils.scheduler.activate_scheduler');
						}
					}
				});
			}, 300000); // check every 5 minutes
		}
	}
}