import { EVT_NOTIFICATION_UPDATE } from "../../../controllers/notifications/events";
import { useState, useEffect, useMemo } from "react";
import { Icon } from "../../components/icon";
import { Collapsible } from "../../components/collapsible";
import { Button } from "../../components/button";
import clsx from "clsx";
import { use_mounted } from "../../hooks/use_mounted";
import { use_local_cache } from "../../hooks/use_local_cache";
const __ = window.__;

const NotificationCard = ({ doc_link, message, title, user, user_avatar, timestamp, read, name, onUpdate }) => {
	const [onHover, setOnHover] = useState(false);
	const handle_mouse_enter = () => {
		setOnHover(true);
	}
	const handle_mouse_leave = () => {
		setOnHover(false);
	}
	const handle_read_click = async (e) => {
		await bcore.app.notifications.set_notification_as_read(name);
		e.stopPropagation();
	}

	const handle_click = async () => {
		await bcore.app.notifications.set_notification_as_read(name);
		window.location.href = doc_link;
	}

	return <a
		className={clsx(
			"d-flex", "flex-row", "p-0", "elevate", "bg-secondary",
			"round", "rel", "flex-row", "overflow-hidden", {
			"unread": !read,
			"-mt-2px": onHover
		}
		)}
		onClick={handle_click}
		onMouseEnter={handle_mouse_enter}
		onMouseLeave={handle_mouse_leave}
	>
		<div className="f-1h flex-fluid pl-1 pr-1h pv-1h" dangerouslySetInnerHTML={{ __html: message }}></div>
		{/* <div className="d-flex flex-row gap-1">
			<span className="f-1h">{timestamp}</span>
		</div> */}
		{!read &&
			<Button
				className={clsx(
					"flex-static", "overflow-hidden",
					"round-unset", "rel",
					{
						"p-1h": onHover,
						"w-0": !onHover,
					}
				)} small intent="info"
				onClick={handle_read_click}
			>
				<Icon icon="eye-check" stroke={1} size="16px" className="c-white" />
			</Button>
		}
	</a>
}

const TodayEventCard = ({ name, time, subject, color, icon }) => {
	return <a
		className={clsx(
			"d-flex", "flex-row", "p-0", "elevate", "bg-secondary",
			"round", "rel", "overflow-hidden", "gap-1h", "ai-center"
		)}
		href={`#Form/Event/${name}`}
	>
		<div className="w-5rem flex-static pv-1h pl-1"
			style={{
				backgroundColor: color
			}}
		>
			<span
				className="f-2h fw-bolder"
				style={{
					backgroundClip: "text",
					color: color,
					filter: "invert(1)"
				}}
			>{time}</span>
		</div>
		<div className="f-1h flex-fluid ph-1h pv-0">{subject}</div>
		{icon && <Icon icon={icon} size="1.25rem" stroke={1} className="flex-static mr-1" />}
	</a>
}

const TargetCard = ({ title, value }) => {
	return <a
		className={clsx(
			"d-flex", "flex-row", "p-1", "elevate", "bg-secondary",
			"round", "rel", "overflow-hidden"
		)}
	>
		<div className="f-1h flex-fluid">{title}</div>
		<div className="f-1h flex-static">{value}</div>
	</a>
}

const OpenCard = ({ title, value }) => {
	return <a
		className={clsx(
			"d-flex", "flex-row", "pv-1h", "ph-1", "elevate", "bg-secondary",
			"round", "rel", "overflow-hidden"
		)}
	>
		<div className="f-1h flex-fluid">{title}</div>
		<div className="f-1h flex-static">{value}</div>
	</a>
}

export const NotificationPanel = () => {
	const is_mounted = use_mounted();
	const [openTab, setOpenTab] = use_local_cache("bcore.ui.notification_panel.open_tab", "notifications");
	const [state, setState] = useState("loading");
	const [notifications, setNotifications] = useState([]);

	const { notification_items, today_items, open_items } = useMemo(() => {

		return notifications.reduce((p, c, i) => {
			if (c.type == "Notification") {
				p.notification_items.push(<NotificationCard key={`notification-${i}`} {...c} />);
			} else if (c.type == "Event") {
				p.today_items.push(<TodayEventCard key={`today-${i}`} {...c} />);
			} else if (c.type == "Target") {
				p.open_items.push(<TargetCard key={`target-${i}`} {...c} />);
			} else if (c.type == "Open") {
				p.open_items.push(<OpenCard key={`open-${i}`} {...c} />);
			}

			return p;
		}, { notification_items: [], today_items: [], open_items: [] });
	}, [notifications]);

	const on_notifications_updated = (controller, notifications) => {
		if (is_mounted) {
			setNotifications(notifications);
		}
	}

	useEffect(async () => {
		bcore.app.notifications.on(EVT_NOTIFICATION_UPDATE, on_notifications_updated);
		await bcore.app.notifications.update();
		setState("loaded");
		return () => {
			bcore.app.notifications.off(EVT_NOTIFICATION_UPDATE, on_notifications_updated);
		}
	}, []);

	const handle_close_click = () => {
		bcore.app.extras_panel.close_panel();
	}

	const handle_collapsed = (name) => {
		setOpenTab(name);
	}

	const handle_mark_all_as_read = async () => {
		await bcore.app.notifications.mark_all_as_read();
	}

	const handle_settings_click = () => {
		bcore.app.notifications.route_to_settings();
	}

	return <div className="d-flex flex-col h-100vh">
		<Collapsible
			name="notifications"
			label={__("Notifications")}
			open={openTab == "notifications"}
			handle_click={handle_collapsed}
			contentClasses="bg-primary gap-1h"
			actions={() => <><div className="flex-fluid"></div><div className="flex-static flex-row gap-1h">
				<Button onClick={handle_mark_all_as_read} small intent="secondary" className="d-flex ai-center"><Icon size="16px" icon="eye-check" className="mr-1h" stroke={1} />Mark all as Read</Button>
				<Button onClick={handle_settings_click} small className="p-0"><Icon size="16px" className="d-flex ai-center" icon="settings" stroke={1} /></Button>
			</div></>}
		>
			{state == "loading" && <div className="t-center">Loading...</div>}
			{notification_items}
		</Collapsible>
		<Collapsible
			name="todays-events"
			label={__("Today's Events")}
			open={openTab == "todays-events"}
			handle_click={handle_collapsed}
			contentClasses="bg-primary gap-1h"
		>
			{state == "loading" && <div className="t-center">Loading...</div>}
			{today_items}
		</Collapsible>
		<Collapsible
			name="open-documents"
			label={__("Open Documents")}
			open={openTab == "open-documents"}
			handle_click={handle_collapsed}
			contentClasses="bg-primary gap-1h"
		>
			{state == "loading" && <div className="t-center">Loading...</div>}
			{open_items}
		</Collapsible>
		<div className="bt-solid sm-hide" onClick={handle_close_click}>
			<a className="btn"><Icon icon="chevron-left" className="max-w-16px max-h-16px" /></a>
		</div>
	</div>
}