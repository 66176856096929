
	bcore.templates['page'] = `<div class="page-head sticky top-0 bg-primary z-10 flex-static pb-1h">
	<div class="xxs-ph-1 md-ph-2">
		<div class="d-flex flex-row ai-center gap-1">
			<div class="flex-fluid page-title">
				<!-- title -->
				<div class="title-area d-flex flex-row editable-title m-0 ji-center gap-1h">
					<div class="title-image flex-static hide hidden-md hidden-lg">
					</div>
					<div class="d-flex flex-fluid flex-row title-area ai-center gap-1h">
						<h1 class="ellipsis title-text f-3 mv-0 cursor-pointer"></h1>
						<span class="indicator whitespace-nowrap hide f-1"></span>
					</div>
				</div>
			</div>
			<div class="flex-static t-right page-actions m-0 flex-row jc-end ai-center gap-1h">
				<!-- ID and icon buttons -->
				<span class="checked-items-status text-ellipsis text-muted small hide hidden-xs hidden-sm">## items selected</span>
				<h6 class="ellipsis sub-heading hide text-muted d-inline mv-0 cursor-copy"></h6>
				<span class="page-icon-group hide hidden-xs hidden-sm d-flex flex-row gap-1h"></span>

				<!-- buttons -->
				<div class="btn-group menu-btn-group hide">
					<button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
						<span class="hidden-xs">
							<span class="menu-btn-group-label">{%= __("Menu") %}</span>
							<span class="caret"></span>
						</span>
						<span class="visible-xs">
							<i class="octicon octicon-triangle-down"></i>
						</span>
					</button>
					<ul class="dropdown-menu dropdown-menu-right" role="menu"></ul>
				</div>
				<button class="btn btn-secondary secondary-action hide"></button>
				<div class="btn-group actions-btn-group hide">
					<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
						<span class="hidden-xs">
							<span class="actions-btn-group-label">{%= __("Actions") %}</span>
							<span class="caret"></span>
						</span>
						<span class="visible-xs octicon octicon-check"></span>
					</button>
					<ul class="dropdown-menu dropdown-menu-right" role="menu">
					</ul>
				</div>
				<button class="btn hide primary-action"></button>
			</div>
		</div>
	</div>
</div>
<div class="page-body xxs-ph-1 md-ph-2 flex-fluid flex-col">
	<div class="page-toolbar flex-static hide">
		<div class=""> <!-- container was here -->
		</div>
	</div>
	<div class="page-wrapper flex-fluid flex-col">
		<div class="page-content-wrapper flex-fluid flex-col">
			<div class="page-content flex-fluid flex-col">
				<div class="workflow-button-area btn-group pull-right hide"></div>
			</div>
		</div>
	</div>
</div>`;
