
	bcore.templates['report_links'] = `<div class="form-documents">
	{% for (var i=0; i < reports.length; i++) { %}
		{% if((i % 2)===0) { %}<div class="row">{% } %}
		<div class="col-xs-6">
			<h6>{{ reports[i].label }}</h6>
			{% for (var j=0; j < reports[i].items.length; j++) {
				var report = reports[i].items[j]; %}
			<div class="document-link"
				data-report="{{ report }}">
				<a class="report-link small">{{ __(report) }}</a>
			</div>
			{% } %}
		</div>
		{% if((i % 2)===1) { %}</div>{% } %}
	{% } %}
	<!-- finally, close one-column row -->
	{% if((i % 2)===0) { %}</div>{% } %}
</div>
`;
