
	bcore.templates['timeline'] = `<div class="timeline rel">
	<div class="timeline-head bg-secondary rel">
	</div>
	<div class="timeline-new-email mv-1 md-pl-70px rel">
		{% if (doctype === "Communication") { %}
			<button class="btn btn-secondary btn-reply-email">
				{%= __("Reply") %}
			</button>
		{% } else { %}
			<button class="btn btn-secondary btn-new-email">
			{%= __("New Email") %}
			</button>
			{% if (allow_events_in_timeline===1) { %}
			<button class="btn btn-secondary btn-new-interaction">
				{%= __("New Event") %}
			</button>
			{% } %}
		{% } %}
	</div>
	<div class="timeline-email-import text-muted small mv-2 md-pl-70px rel">

	</div>
	<div class="timeline-items rel">

	</div>
</div>
`;
