import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

export class SetupDialogComponent extends ComponentDependencies(BootInfoComponent) {
	on_startup() {
		if (bcore.ui.startup_setup_dialog && !bcore.boot.setup_complete) {
			bcore.ui.startup_setup_dialog.pre_show();
			bcore.ui.startup_setup_dialog.show();
		}
	}
}