import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

/**
 * Displays a boot message to the user stored in bcore.boot.message
 */
export class BootMessagesComponent extends ComponentDependencies(BootInfoComponent) {
	on_startup() {
		if (bcore.boot.messages) {
			bcore.msgprint(bcore.boot.messages);
		}
	}
}