import Home from './Home.vue';

bcore.provide('bcore.social');

export class SocialHome {
	constructor({ parent }) {
		this.$parent = $(parent);
		this.page = parent.page;
		this.setup_header();
		this.make_body();
	}
	make_body() {
		this.$social_container = this.$parent.find('.layout-main');
		new Vue({
			el: this.$social_container[0],
			render: h => h(Home),
			data: {
				'page': this.page
			}
		});
	}
	setup_header() {
		this.page.set_title(__('Social'));
	}
};
bcore.social.Home = SocialHome

bcore.social.post_dialog = new bcore.ui.Dialog({
	title: __('Create Post'),
	fields: [
		{
			fieldtype: "Text Editor",
			fieldname: "content",
			label: __("Content"),
			reqd: 1
		}
	],
	primary_action_label: __('Post'),
	primary_action: (values) => {
		bcore.social.post_dialog.disable_primary_action();
		const post = bcore.model.get_new_doc('Post');
		post.content = values.content;
		bcore.db.insert(post).then(() => {
			bcore.social.post_dialog.clear();
			bcore.social.post_dialog.hide();
		}).finally(() => {
			bcore.social.post_dialog.enable_primary_action();
		});
	}
});

bcore.social.is_home_page = () => {
	return bcore.get_route()[0] === 'social' && bcore.get_route()[1] === 'home';
};

bcore.social.is_profile_page = (user) => {
	return bcore.get_route()[0] === 'social'
		&& bcore.get_route()[1] === 'profile'
		&& (user ? bcore.get_route()[2] === user : true);
};

bcore.social.is_session_user_page = () => {
	return bcore.social.is_profile_page() && bcore.get_route()[2] === bcore.session.user;
};

bcore.provide('bcore.app_updates');

bcore.utils.make_event_emitter(bcore.app_updates);
