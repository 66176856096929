export default `
:host {
  display: flex;
}
:host .w-0 {
  width: 0;
}
:host #__mountpoint {
  width: 0;
  transition: width 0.125s linear;
}
:host #__mountpoint.has-icons {
  width: 16px;
}
:host #__mountpoint.open {
  width: 20rem;
}
:host #__mountpoint .panel-content {
  grid-template-columns: 1fr -webkit-min-content;
  grid-template-columns: 1fr min-content;
}
:host #__mountpoint .icons {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL2Jjb3JlL2Jjb3JlL2Jjb3JlL3B1YmxpYy9qcy9iY29yZS93ZWJfY29tcG9uZW50cy9iLWV4dHJhcy1iYXIvc3R5bGVzLnNjc3MiLCIlM0NpbnB1dCUyMGNzcyUyMDlkTVVJXyUzRSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLGFBQUE7QUNDRDtBRENDO0VBQ0MsUUFBQTtBQ0NGO0FERUM7RUFDQyxRQUFBO0VBQ0EsK0JBQUE7QUNBRjtBREVFO0VBQ0MsV0FBQTtBQ0FIO0FER0U7RUFDQyxZQUFBO0FDREg7QURJRTtFQUNDLDhDQUFBO0VBQUEsc0NBQUE7QUNGSDtBREtFO0VBQ0MsMEJBQUE7RUFBQSx1QkFBQTtFQUFBLGtCQUFBO0FDSEgiLCJmaWxlIjoidG8uY3NzIn0= */`;
