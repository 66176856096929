/**
 * @typedef {object} WCConfig
 * @property {string}    tag The HTML Element tag name.
 * @property {array}     stylesheets An array of stylesheet urls to include.
 * @property {string}    style A string of raw css to inject into the root.
 * @property {object}    props An object containing component props and a function to sanitize and convert its values.
 * @property {function}  component A component implementation function to render
 * @property {string}    mode Set to "closed" to build a private shadow dom. Defaults to open.
 * @property {object}    events Web Component internal event handlers.
 * @property {object}    on_prop An object containing prop change event handlers. { prop: (wc, old, value) => {} }
 */

/**
 * @typedef {object} WCProps Component properties
 * @property {import("../web_component_info/index").WebComponentInfo} $web_component
 */
