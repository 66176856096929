import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

/**
 * Handles page information caching and clearing
 */
export class PageCachingComponent extends ComponentDependencies(BootInfoComponent) {

	// removed this for now will. it was causing problem due which bundle was returning old hashed value.

	on_boot() {
	// 	// clear cached pages if timestamp is not found
	// 	if (localStorage["page_info"]) {
	// 		bcore.boot.allowed_pages = [];
	// 		var page_info = JSON.parse(localStorage["page_info"]);
	// 		$.each(bcore.boot.page_info, function (name, p) {
	// 			if (!page_info[name] || (page_info[name].modified != p.modified)) {
	// 				delete localStorage["_page:" + name];
	// 			}
	// 			bcore.boot.allowed_pages.push(name);
	// 		});
	// 	} else {
	// 		bcore.boot.allowed_pages = Object.keys(bcore.boot.page_info);
	// 	}
	// 	localStorage["page_info"] = JSON.stringify(bcore.boot.page_info);
		localStorage.clear()
	}
}
