import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

export class VersionUpdateComponent extends ComponentDependencies(BootInfoComponent) {
	on_startup() {
		bcore.realtime.on("version-update", function () {
			let dialog = bcore.msgprint({
				message: __("The application has been updated to a new version, please refresh this page"),
				indicator: 'green',
				title: __('Version Updated')
			});
			dialog.set_primary_action(__("Refresh"), function () {
				location.reload(true);
			});
			dialog.get_close_btn().toggle(false);
		});
	}
}