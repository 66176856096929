
	bcore.templates['form_sidebar'] = `
<div class="d-flex flex-col gap-1">
	<ul class="list-unstyled sidebar-menu user-actions hidden"></ul>
	<ul class="list-unstyled sidebar-menu sidebar-image-section xxs-hide md-d-block hide">
		<li class="sidebar-image-wrapper xxs-hide md-d-block">
			<div class="sidebar-image"></div>
			<div class="sidebar-standard-image">
				<div class="standard-image"></div>
			</div>
			<div class="abs z-1 center-hv f-2 sidebar-image-actions">
				<div class="dropdown">
					<a class="dropdown-toggle" data-toggle="dropdown">{{ __("Change") }}</a>
					<ul class="dropdown-menu" role="menu">
						<li><a class="sidebar-image-change">{{ __("Upload") }}</a></li>
						<li><a class="sidebar-image-remove">{{ __("Remove") }}</a></li>
					</ul>
				</div>
			</div>
		</li>
	</ul>

	<ul class="list-unstyled sidebar-menu sidebar-rating hide flex-col gap-1h">
		<li style="position: relative;">
			<a class="strong badge-hover">
				<span>{%= __("Feedback") %}</span>
			</a>
		</li>
		<li class="rating-icons"></li>
	</ul>

	<ul class="list-unstyled sidebar-menu comments-section flex-col gap-1h">
		<li class="divider visible-sm visible-xs"></li>
		<li style="position: relative;">
			<a class="sidebar-comments badge-hover">
				<span>{%= __("Comments") %}</span>
				<span class="badge n-comments">0</span>
			</a>
		</li>
	</ul>
	<ul class="list-unstyled sidebar-menu form-assignments flex-col gap-1h">
		<li class="form-sidebar-label assigned-to-label">{%= __("Assigned To") %}</li>
		<li><a class="add-assignment text-muted">{%= __("Assign") %}
			<i class="octicon octicon-plus" style="margin-left: 2px;"></i></a></li>
	</ul>
	<ul class="list-unstyled sidebar-menu form-attachments flex-col gap-1h">
		<li class="form-sidebar-label attachments-label">{%= __("Attachments") %}</li>
		<li><a class="add-attachment text-muted">{%= __("Attach File") %}
			<i class="octicon octicon-plus" style="margin-left: 2px;"></i></li></a>
	</ul>
	<ul class="list-unstyled sidebar-menu tags-section flex-col gap-1h">
		<li class="form-sidebar-label tags-label">{%= __("Tags") %}</li>
		<li class="form-tags">
			<div class="tag-area"></div>
			<div class="clearfix"></div>
		</li>
	</ul>
	<ul class="list-unstyled sidebar-menu form-reviews flex-col gap-1h">
		<li class="form-sidebar-label reviews-label">{%= __("Reviews") %}</li>
		<li class="review-list d-flex flex-row gap-2h"></li>
	</ul>
	<ul class="list-unstyled sidebar-menu form-share flex-col gap-1h">
		<li class="form-sidebar-label shared-with-label">{%= __("Shared With") %}</li>
		<li class="form-shared d-flex flex-row gap-2h"></li>
	</ul>
	
	<ul class="list-unstyled sidebar-menu flex-col gap-1h">
		<li class="form-sidebar-label viewers-label">{%= __("Currently Viewing") %}</li>
		<li class="form-viewers"></li>
	</ul>

	<ul class="list-unstyled sidebar-menu flex-col gap-1h">
		<a><li class="auto-repeat-status"><li></a>
	</ul>

	<ul class="list-unstyled sidebar-menu like-section flex-col gap-1h">
		<li class="liked-by-parent">
			<span class="liked-by">
				<i class="octicon octicon-heart like-action text-extra-muted fa-fw"></i>
				<span class="likes-count"></span>
			</span>
		</li>
	</ul>
	<ul class="list-unstyled sidebar-menu followed-by-section flex-col gap-1h">
		<li class="form-sidebar-label followed-by-label text-medium hidden">{%= __("Followed by") %}</li>
		<li class="followed-by"></li>
		<li class="document-follow">
			<a class="badge-hover follow-document-link hidden">
				{%= __("Follow") %}
			</a>
			<a class="badge-hover unfollow-document-link hidden">
				{%= __("Unfollow") %}
			</a>
		</li>
	</ul>
	<ul class="list-unstyled sidebar-menu text-muted flex-col gap-1h">
		<li class="modified-by"></li>
		<li class="created-by"></li>
	</ul>
	{% if(bcore.get_form_sidebar_extension) { %}
		{{ bcore.get_form_sidebar_extension() }}
	{% } %}
</div>
`;
