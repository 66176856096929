
	bcore.templates['search_header'] = `<div class="search-header ai-center p-1 gap-1h bb-solid d-flex">
	<div class="flex-fluid jc-start">
		<i class="octicon octicon-search"></i>
		<input type="text" class="search-input f-1h">
	</div>
	<div class="d-flex ai-center gap-1h">
		<p class="loading-state hide text-muted m-0">Searching&nbsp;...</p>
		<a type="button" class="btn btn-secondary btn-modal-minimize">
			<i class="octicon octicon-chevron-down"></i>
		</a>
		<a type="button" class="btn btn-secondary btn-modal-close" data-dismiss="modal" aria-hidden="true">
			<i class="octicon octicon-x xxs-d-inline-block md-hide"></i>
			<span class="hidden-xs f-1h">Close</span>
		</a>
	</div>
</div>`;
