import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";
import { BootGuestsComponent } from "./boot_guests";
import { EVT_INIT } from "../../../events";

export class UserPermissionsComponent extends ComponentDependencies(
	BootInfoComponent,
	BootGuestsComponent
) {

	[EVT_INIT]() {
		bcore.defaults.update_user_permissions();

		bcore.realtime.on('update_user_permissions', bcore.utils.debounce(() => {
			bcore.defaults.update_user_permissions();
		}, 500));
	}
}