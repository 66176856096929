import { ComponentDependencies } from "../../../compose";
import { WebComponentInfo } from "../../web_component_info";
import { ParentComponent } from "../../../components/parent";
import { TAG_INITIALIZED, TAG_MOUNTED } from "../../../tags";
import { EVT_MOUNT, EVT_INIT_ELEMENT, EVT_UNMOUNT, EVT_RENDER, EVT_UPDATE, EVT_SETUP_RENDERES } from "../events";
import { EVT_CONSTRUCT, EVT_INIT, FLAG_INITIALIZED } from "../../../events";
import { TAG_WEB_COMPONENT } from "../tags";
import { TaggedComponent } from "../../../components/tagged";
import { promised_api } from "../../../utils";
import component_style from "./web_component_factory.scss";

export class WebComponentFactoryComponent extends ComponentDependencies(ParentComponent) {

	async [EVT_CONSTRUCT]() {
		this.renderers = new Map();
	}

	async [EVT_INIT]() {
		await this.broadcast(EVT_SETUP_RENDERES);
	}

	async set_renderer_type(type, callback) {
		if (!this.renderers.has(type)) {
			this.renderers.set(type, callback);
		} else {
			throw Error(`Renderer for ${type} already configured`);
		}
	}

	async on_build_web_component(config, observed_attributes) {
		// cache apis to access inside dynamic element class
		const _controller = this.parent;
		const _broadcast = this.broadcast.bind(this);
		const _component = this;

		// build attribute breakpoint combinations
		const breakpoint_attributes = [];
		for (const attr of observed_attributes) {
			for (const bp of bcore.breakpoints) {
				breakpoint_attributes.push(`[${bp.name}]${attr}`);
			}
		}

		const extended_observed_attributes = [...observed_attributes, ...breakpoint_attributes];

		customElements.define(config.tag, class extends HTMLElement {
			constructor() {
				super();
				this.__web_component = new WebComponentInfo(
					this,
					this.attachShadow({
						mode: config.mode || 'open',
						// delegatesFocus: true,
					}),
					config
				);
				this.__web_component.init();

				if (config.props) {
					const component = this.__web_component;
					for (const key of Object.keys(config.props)) {
						Reflect.defineProperty(this, key, {
							get() {
								return component.props.get(key);
							},
							set(v) {
								component.set_attribute(key, v);
							}
						});

						// pickup values already set on element.
						const current_value = component.element.getAttribute(key);
						if (current_value !== undefined && current_value !== null) {
							component.set_attribute(key, current_value, false);
						}

					}
				}

			}

			/**
			 * @returns {string[]}
			 */
			static get observedAttributes() {
				return extended_observed_attributes;
			}

			/**
			 * Element attributes callback. Will only trigger for attributes listed in observerAttributes()
			 * @param {string} name Attribute name
			 * @param {*} oldValue Old attribute value
			 * @param {*} newValue New attribute value
			 */
			attributeChangedCallback(name, oldValue, newValue) {
				if (this.isConnected && oldValue != newValue) {
					if (this.__web_component[FLAG_INITIALIZED]) {
						this.__web_component.set_attribute(name, newValue);
					}
				}
			}

			async update() {
				if (!this.isConnected) {
					return;
				}

				await _broadcast(EVT_UPDATE, this.__web_component);
				await _broadcast(EVT_RENDER, this);
			}

			async render() {
				await _broadcast(EVT_RENDER, this);
			}

			render_immediate() {
				if (!this.isConnected) {
					return;
				}

				const props = Object.fromEntries(this.__web_component.props);

				try {
					const renderer = _component.renderers.get(config.type);
					renderer(this.__web_component, props);
				} catch (ex) {
					console.error(ex);
				}
			}

			async disconnectedCallback() {
				if (!this.isConnected) {
					return;
				}

				this.__web_component[TaggedComponent].remove_tag(TAG_MOUNTED);
				_controller[ParentComponent].remove_child_controller(this.__web_component);

				await _broadcast(EVT_UNMOUNT, this.__web_component,);
				await _broadcast(`${config.type}_unmount`, this.__web_component);

				this.__web_component[TaggedComponent].remove_tag(TAG_INITIALIZED);
			}

			async connectedCallback() {
				if (!this.isConnected) {
					return;
				}

				await this.__web_component.init();
				await _controller[ParentComponent].add_child_controller(this.__web_component);

				for (const att of extended_observed_attributes) {
					if (this.hasAttribute(att)) {
						const value = this.getAttribute(att);
						if (value) {
							this.__web_component.set_attribute(att, value);
						}
					}
				}

				// apply attributes before rendering for the first time.
				const props = Object.fromEntries(this.__web_component.props);

				await _broadcast(EVT_INIT_ELEMENT, this.__web_component);

				this.__web_component.shadow.innerHTML = `
					<style>
						${component_style}
						${this.__web_component.config.style ? config.style : ''}
					</style>
					<div id="__mountpoint"></div>
				`;

				const link_await = [];
				const styles = this.__web_component.config.stylesheets || [];
				const stylesheets = typeof styles === "function" ? styles() : styles;
				const styleguide = "//bloomstack.github.io/styleguide/styleguide.noimports.min.css";
				if (stylesheets.indexOf(styleguide) == -1) {
					stylesheets.unshift(styleguide);
				}

				for (const link of stylesheets) {
					const link_el = document.createElement("link");
					link_el.href = link;
					link_el.type = "text/css";
					link_el.rel = "stylesheet";
					const link_promise = promised_api();
					this.__web_component.shadow.appendChild(link_el);
					link_el.onload = link_promise.on_resolve;
					link_el.onerror = link_promise.on_reject;
					link_await.push(link_promise.promise);
				}

				// Track stylesheet loading promises so web components may use it to display
				// their own skeletons while stylesheets are not available
				this.__web_component.loading_promise = Promise.allSettled(link_await);
				this.__web_component.mountpoint = this.__web_component.shadow.getElementById("__mountpoint");

				await this.__web_component[TaggedComponent].add_tag(TAG_WEB_COMPONENT);

				this.__web_component.loading_promise.then(() => {
					this.__web_component.mountpoint.classList.add("cssready");
				})

				await this.update();

				await _broadcast(EVT_MOUNT, this.__web_component, props);
				await _broadcast(`${config.type}_mount`, this.__web_component, props);

				await this.__web_component[TaggedComponent].add_tag(TAG_MOUNTED);

				await _broadcast(EVT_RENDER, this);
			}
		});
	}
}