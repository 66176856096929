import { useEffect, useCallback, useRef } from "react";

export const use_mounted = () => {
	const ref = useRef(true);
	const is_mounted = useCallback(() => ref.current, []);

	useEffect(() => {
		return () => void (is_mounted.current = false);
	}, []);

	return is_mounted;
}