import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";
import { EVT_INIT } from "../../../events";

/**
 * Fetches the app logo url and sets global bcore.app.logo_url
 */
export class AppLogoComponent extends ComponentDependencies(BootInfoComponent) {
	async[EVT_INIT]() {
		const r = await bcore.call('bcore.client.get_hooks', { hook: 'app_logo_url' })
		bcore.app.logo_url = (r.message || []).slice(-1)[0];
		if (window.cordova) {
			let host = bcore.request.url;
			host = host.slice(0, host.length - 1);
			bcore.app.logo_url = host + bcore.app.logo_url;
		}
	}
}