
	bcore.templates['upload'] = `<div class="file-upload">
	<div class="input-upload">
		<input class="input-upload-file hidden" type="file" {{ opts.allow_multiple ? "multiple" : "" }} name="filedata" />
		<button class="btn btn-primary btn-sm btn-browse">{%= __("Browse") %}</button>
	</div>
	<div class="uploaded-filename hidden" style="width: 100%; margin-top: 12px;"></div>
	<div class="web-link-wrapper" style="width: calc(100% - 80px);">
		<span class="text-muted file-upload-or">{%= __("or") %}</span>
		<div class="input-link" style="width: calc(100% - 30px);">
			<div class="input-group">
				<div class="input-group-addon">
					<span class="hidden-xs">{%= __("Web Link") %}</span>
					<i class="fa fa-link visible-xs"></i>
				</div>
				<input class="form-control" type="text" name="file_url"
					placeholder="{%= (opts.sample_url || "e.g. http://example.com/somefile.png") %}"/>
			</div>
		</div>
	</div>
	<div class="private-file hidden">
		<div class="checkbox">
		    <label>
		      <input type="checkbox" checked> {{ __("Private") }}
		    </label>
		  </div>
	</div>
</div>
`;
