import { Component } from "../compose";
import { EVT_BEFORE_INIT } from "../events";

/**
 * Use this component to pause component initialization until the dom is ready.
 */
export class WaitForDOMComponent extends Component {
	async [EVT_BEFORE_INIT]() {
		// wait for dom to be ready.
		let _resolve = null;
		const promise = new Promise((resolve) => {
			_resolve = resolve;
		})

		$(_resolve);

		await promise;
	}
}