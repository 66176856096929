
	bcore.templates['form_dashboard'] = `<div class="form-dashboard-wrapper pos-1_1 col-span-all">
	<div class="progress-area hidden form-dashboard-section">
	</div>
	<div class="form-heatmap hidden form-dashboard-section">
		<div id="heatmap-{{ bcore.model.scrub(frm.doctype) }}" class="heatmap overflow-auto"></div>
		<div class="text-muted small heatmap-message hidden"></div>
	</div>
	<div class="form-graph form-dashboard-section hidden"></div>
	<div class="form-stats form-dashboard-section hidden">
		<div class="row"></div>
	</div>
	<div class="form-links form-dashboard-section hidden">
		<div class="transactions"></div>
	</div>
</div>`;
