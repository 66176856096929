import { Compose } from "../../compose";
import { EVT_INIT, EVT_CONSTRUCT } from "../../events";

export class ExtrasPanelController extends Compose() {
	[EVT_CONSTRUCT](app) {
		this.app = app;
		this.panels = new Map();
	}

	async [EVT_INIT]() {
		this.panel = document.getElementById("extras-panel");
	}

	add_panel(id, icon, component) {
		this.panels.set(id, {
			icon,
			component
		});

		window.dispatchEvent(new CustomEvent("extras-panel-added", { detail: {id, icon, component} }));
	}

	open_panel(id) {
		window.dispatchEvent(new CustomEvent("extras-panel-open", { detail: id }));
	}

	toggle_panel(id) {
		window.dispatchEvent(new CustomEvent("extras-panel-toggle", { detail: id }));
	}

	close_panel() {
		window.dispatchEvent(new CustomEvent("extras-panel-close"));
	}
}