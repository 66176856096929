import { Component } from "../../../component";
import { EVT_CONSTRUCT } from "../../../events";
import { EVT_MOUNT, EVT_SET_ATTRIBUTE, EVT_UNMOUNT } from "../../web_components/events";
import { EVT_DRAG_LEAVE, EVT_DRAG_OVER, EVT_DRAG_START, EVT_DRAG_STOP, EVT_DROP } from "./drag_events";

/**
 * This component enables dragzones on components.
 * The dnd:active attribute is set everytime a draggable element hovers over the dropzone.
 * You can use this to style your components to give users feed back of a valid drag zone.
 */
export class DropzoneComponent extends Component {
	[EVT_CONSTRUCT]() {
		this.dropzones = new WeakMap();
	}

	[EVT_SET_ATTRIBUTE](wc, attr, old, value) {
		// look for the draggable attribute being changed
		if (attr == "dnd:dropzone") {
			if (value) {
				this.enable_dropzone(wc);
			} else {
				this.disable_dropzone(wc);
			}
		}
	}

	async [EVT_DRAG_START](wc) {
		this.active_component = wc;
	}

	async [EVT_DRAG_STOP](wc) {
		if (this.active_component == wc) {
			this.active_component = null;
		}
	}

	[EVT_MOUNT](wc) {
		// enable dropzone if the dnd:dropzone attribute is found on mount
		if (wc.element.hasAttribute("dnd:dropzone")) {
			this.enable_dropzone(wc);
		}
	}

	[EVT_UNMOUNT](wc) {
		this.disable_dropzone(wc);
	}

	handle_drop(wc, e) {
		e.preventDefault();
		if (this.active_component) {
			wc.broadcast(EVT_DROP, this.active_component);
			// wc.mountpoint.appendChild(this.active_component.element);
			wc.element.removeAttribute("dnd:active");
			this.active_component = null;
		}
	}

	handle_drag_over(wc, e) {
		e.preventDefault();
		if (this.active_component) {
			wc.broadcast(EVT_DRAG_OVER, this.active_component);
			wc.element.setAttribute("dnd:active", "");
		}
	}

	handle_drag_leave(wc, e) {
		wc.broadcast(EVT_DRAG_LEAVE);
		wc.element.removeAttribute("dnd:active");
	}

	make_handlers_for_wc(wc) {
		return {
			handle_drop: this.handle_drop.bind(this, wc),
			handle_drag_over: this.handle_drag_over.bind(this, wc),
			handle_drag_leave: this.handle_drag_leave.bind(this, wc),
		}
	}

	enable_dropzone(wc) {
		if (this.dropzones.has(wc)) {
			return; // early exit, already setup
		}

		const handlers = this.make_handlers_for_wc(wc);
		wc.element.addEventListener("drop", handlers.handle_drop);
		wc.element.addEventListener("dragover", handlers.handle_drag_over);
		wc.element.addEventListener("dragleave", handlers.handle_drag_leave);
		this.dropzones.set(wc, handlers);
	}

	disable_dropzone(wc) {
		if (!this.dropzones.has(wc)) {
			return; // early exit, nothing to do.
		}

		const handlers = this.this.dropzones.get(wc);
		wc.element.removeEventListener("drop", handlers.handle_drop);
		wc.element.removeEventListener("dragover", handlers.handle_drag_over);
		wc.element.removeEventListener("dragleave", handlers.handle_drag_leave);
		this.dropzones.set(wc, handlers);

	}
}