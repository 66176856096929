/**
 * @callback set_separator_count setState
 * @param {number} prev_separator_count state
 * @return {void}
 */

/**
 * @callback Formatter
 * @param {string} value
 * @param {number} separator_count
 * @param {set_separator_count} set_separator_count
 * @return {string}
 */

/**
 * @callback mouse_handle
 * @param {number} index
 * @return {void}
 */