import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

export class BootGuestsComponent extends ComponentDependencies(BootInfoComponent) {
	on_boot_guest() {
		bcore.session.user = 'Guest';
		bcore.session.user_email = '';
		bcore.session.user_fullname = 'Guest';

		bcore.user_defaults = {};
		bcore.user_roles = ['Guest'];
		bcore.sys_defaults = {};
	}
}