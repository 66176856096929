import './typedef';
import { Compose, withMixins } from "../../compose";
import { ReactSupportComponent } from "./components/react_support";
import { BreakpointBootstrappingComponent } from "./components/breakpoint_bootstrapping";
import { WebComponentFactoryComponent } from "./components/web_component_factory";
import { RenderingSchedulerComponent } from "./components/rendering_scheduler";
import { VanillaJSSupportComponent } from "./components/vanilla_support";
import { ParentComponent } from "../../components/parent";
import { ReactWebPage } from "./components/react_web_page";

export default class WebComponentController extends Compose(
	ParentComponent,
	BreakpointBootstrappingComponent,
	WebComponentFactoryComponent,
	RenderingSchedulerComponent,
	withMixins(ReactSupportComponent,
		"make_react_component"
	),
	withMixins(ReactWebPage,
		"make_react_page"
	),
	withMixins(VanillaJSSupportComponent,
		"make_js_component"
	)
) {
}

bcore.web = new WebComponentController();
bcore.web_ready = bcore.web.init();

// Expose simple apis to keep wrists healthy ///////////////////////////

/**
 * Seamlessly bootstraps a web component.
 * @param { WCConfig } config Component configuration.
 */
export const make_react_component = async (config) => {
	await bcore.web_ready;
	return bcore.web.make_react_component(config);
}
/**
 * Seamlessly bootstraps a web component.
 * @param {WCConfig}    config Component configuration.
 */
export const make_js_component = async (config) => {
	await bcore.web_ready;
	return bcore.web.make_js_component(config);
}


/**
 * Seamlessly bootstraps a page.
 * @param {object}    config Component configuration.
 * @param {string}    config.name The page name.
 * @param {array}     config.stylesheets An array of stylesheet urls to include.
 * @param {string}    config.style A string of raw css to inject into the root.
 * @param {function}  config.page A component implementation function to render
 * @param {string}    config.mode Set to "closed" to build a private shadow dom. Defaults to open.
 * @param {number}    config.render_debounce_timeout Sets a rendering debounce timeout. Defaults to 50 milliseconds.
 */
export const make_react_page = async (...args) => {
	await bcore.web_ready;
	return bcore.web.make_react_page(...args);
}
/**
 * Seamlessly bootstraps a page.
 * @param {object}    config Component configuration.
 * @param {string}    config.name The page name.
 * @param {array}     config.stylesheets An array of stylesheet urls to include.
 * @param {string}    config.style A string of raw css to inject into the root.
 * @param {function}  config.page A component implementation function to render
 * @param {string}    config.mode Set to "closed" to build a private shadow dom. Defaults to open.
 * @param {number}    config.render_debounce_timeout Sets a rendering debounce timeout. Defaults to 50 milliseconds.
 */
export const make_js_page = async (...args) => {
	await bcore.web_ready;
	return bcore.make_js_page(...args);
}
