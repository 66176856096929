
	bcore.templates['attachment'] = `<li class="attachment-row d-flex align-center">
	<a class="close">&times;</a>
	<a href="{{ file_path }}" class="flex align-center">
		<i class="{{ icon }} fa-fw text-warning"></i>
	</a>
	<a href="{{ file_url }}" target="_blank" title="{{ file_name }}" class="ellipsis" style="max-width: calc(100% - 43px);">
		<span>{{ file_name }}</span>
	</a>
	<input type="checkbox" id="file-attach" class="file-attach hide" style="position: absolute; right: 5px;">
</li>

`;
