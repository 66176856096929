import { Component } from "../../../component";
import { TaggedComponent } from "../../../components/tagged";
import { EVT_CONSTRUCT, EVT_INIT } from "../../../events";
import { EVT_RENDER } from "../events";
import { TAG_MOUNTED } from "../../../tags";

export class RenderingSchedulerComponent extends Component {
	[EVT_CONSTRUCT]() {
		this.queue = new Set();
		this.frame_requested = null;
	}

	[EVT_INIT]() {
		window.requestAnimationFrame(this.render.bind(this));
	}

	[EVT_RENDER](component) {
		this.schedule_rendering(component);
	}

	schedule_rendering(component) {
		this.queue.add(component);
		if (!this.frame_requested) {
			this.frame_requested = window.requestAnimationFrame(this.render.bind(this));
		}
	}

	async render() {
		window.cancelAnimationFrame(this.frame_requested);
		this.frame_requested = null;
		// early exit when there is nothing to render
		if (this.queue.size == 0) {
			return;
		}

		// since we are rendering async we could modify the queue while rendering
		// so we'll make a copy of the queue to avoid mutating in the middle of the loop
		const queueCopy = Array.from(this.queue);
		this.queue.clear();

		// iterate over every queued component and trigger rendering while the task has
		// idle time left.
		let perf_exit = false;
		let render_start = window.performance.now();
		const max_comp_timeout = 5; //ms
		const max_render_timeout = 32; //ms 30fps
		while (queueCopy.length) {
			const component = queueCopy.shift();
			if (!perf_exit && component.__web_component[TaggedComponent].has_tag(TAG_MOUNTED)) {
				const comp_start = window.performance.now();
				await component.render_immediate();
				const now = window.performance.now();
				const comp_perf = now - comp_start;
				const render_perf = now - render_start;
				perf_exit = render_perf > max_render_timeout; // ensure we don't freeze renderin due to ill behaving components
				if (comp_perf > max_comp_timeout) {
					console.warn(`Component ${component.__web_component.config.tag} took ${comp_perf}ms to execute.`);
				}
			} else {
				this.schedule_rendering(component);
			}
		}
	}
}