import { ComponentDependencies } from "../../../compose";
import { EVT_INIT } from "../../../events";
import { RealtimeController } from "../../realtime";
import { BootInfoComponent } from "./boot_info";

/**
 * Initializes socket io support
 */
export class SocketIOComponent extends ComponentDependencies(
	BootInfoComponent
) {
	/**
	 * Kickstarts the socketio support init
	 */
	async [EVT_INIT]() {
		this.realtime = bcore.socketio = new RealtimeController(bcore.boot.socketio_port);
		await this.realtime.init();
	}
}

// Support for the older realtime api
bcore.provide("bcore.realtime");
bcore.realtime.on = function (event, callback) {
	bcore.socketio && bcore.socketio.socket && bcore.socketio.socket.on(event, callback);
};

bcore.realtime.off = function (event, callback) {
	bcore.socketio && bcore.socketio.socket && bcore.socketio.socket.off(event, callback);
}

bcore.realtime.publish = function (event, message) {
	if (bcore.socketio && bcore.socketio.socket) {
		bcore.socketio.socket.emit(event, message);
	}
}