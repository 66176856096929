import { Component } from "../../../component";
import { EVT_CONSTRUCT } from "../../../events";
import { EVT_MOUNT, EVT_SET_ATTRIBUTE, EVT_UNMOUNT } from "../../web_components/events";
import { EVT_DRAG_START, EVT_DRAG_STOP } from "./drag_events";

export class DraggableComponent extends Component {
	[EVT_CONSTRUCT]() {
		this.draggable = new WeakMap();
	}

	[EVT_SET_ATTRIBUTE](wc, attr, old, value) {
		// look for the draggable attribute being changed
		if (attr == "draggable") {
			if (old != value) {
				// when true enable drag functionality
				if (value == "true") {
					this.enable_draggable(wc);
				} else {
					this.disable_draggable(wc);
				}
			}
		}
	}

	[EVT_MOUNT](wc) {
		// on mount check for draggable
		if (wc.element.getAttribute("draggable") == "true") {
			this.enable_draggable(wc);
		}
	}

	[EVT_UNMOUNT](wc) {
		// always cleanup on unmount
		this.disable_draggable(wc);
	}

	enable_draggable(wc) {
		if (this.draggable.has(wc)) {
			return; // already draggable early exit
		}

		const element = wc.element;
		const handlers = this.make_handlers_for_wc(wc);
		element.addEventListener("dragstart", handlers.handle_drag_start);
		element.addEventListener("dragend", handlers.handle_drag_end);
		element.addEventListener("drop", handlers.handle_drag_end);
		element.addEventListener("dragover", handlers.handle_drag_over);
		element.addEventListener("dragleave", handlers.handle_drag_leave);
		this.draggable.set(wc, handlers);
	}

	disable_draggable(wc) {
		if (!this.draggable.has(wc)) {
			return; // early exit if nothing to do.
		}
		const handlers = this.draggable.get(wc);
		wc.element.removeEventListener("dragstart", handlers.handle_drag_start);
		wc.element.removeEventListener("dragend", handlers.handle_drag_end);
		wc.element.removeEventListener("drop", handlers.handle_drag_end);
		wc.element.removeEventListener("dragover", handlers.handle_drag_over);
		wc.element.removeEventListener("dragleave", handlers.handle_drag_leave);
		this.draggable.delete(wc);
	}

	make_handlers_for_wc(wc) {
		return {
			handle_drag_start: this.handle_drag_start.bind(this, wc),
			handle_drag_end: this.handle_drag_end.bind(this, wc),
			handle_drag_over: this.handle_drag_over.bind(this, wc),
			handle_drag_leave: this.handle_drag_leave.bind(this, wc),
		}
	}

	async handle_drag_start(wc, e) {
		e.dataTransfer.setData("text/html", "dnd");
		wc.element.setAttribute("dnd:dragging", "");
		await this.broadcast(EVT_DRAG_START, wc);
		wc.broadcast(EVT_DRAG_START)
	}

	async handle_drag_end(wc, e) {
		wc.element.removeAttribute("dnd:over");
		wc.element.removeAttribute("dnd:dragging");
		await this.broadcast(EVT_DRAG_STOP, wc);
		wc.broadcast(EVT_DRAG_STOP)
	}

	handle_drag_over(wc, e) {
		if (wc.element.hasAttribute("dnd:dragging")) {
			wc.element.removeAttribute("dnd:over");
		} else {
			wc.element.setAttribute("dnd:over", "");
		}
	}

	handle_drag_leave(wc, e) {
		wc.element.removeAttribute("dnd:over");
	}
}