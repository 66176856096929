// Libs
import "awesomplete";
import "./js/lib/Sortable.min.js";
import "./js/lib/jSignature.min.js";
import "./js/lib/jquery/jquery.scrollParent.js";
import "./js/bcore_legacy/translate.js";
import "./js/lib/datepicker/datepicker.min";
import "./js/lib/datepicker/datepicker.en";
import "./js/lib/datepicker/locale-all";
import "./js/lib/leaflet/leaflet.js";
import "./js/lib/leaflet/leaflet.draw.js";
import "./js/lib/leaflet/L.Control.Locate.js";
import "./js/lib/leaflet/easy-button.js";

// Desk
import "./js/bcore_legacy/class.js";
import "./js/bcore_legacy/polyfill.js";
import "./js/bcore_legacy/provide.js";
import "./js/bcore_legacy/assets.js";
import "./js/bcore_legacy/format.js";
import "./js/bcore_legacy/form/formatters.js";
import "./js/bcore_legacy/dom.js";
import "./js/bcore_legacy/ui/messages.js";
import "./js/bcore_legacy/ui/keyboard.js";
import "./js/bcore_legacy/ui/colors.js";
import "./js/bcore_legacy/ui/sidebar.js";
import "./js/bcore_legacy/ui/link_preview.js";

import "./js/bcore_legacy/translate";
import "./js/lib/datepicker/datepicker.min";
import "./js/lib/datepicker/datepicker.en";

import "./js/bcore_legacy/request.js";
import "./js/bcore_legacy/utils/utils.js";
import "./js/bcore_legacy/event_emitter.js";
// import "./js/bcore_legacy/router.js";
// import "./js/bcore_legacy/router_history.js";
import "./js/bcore_legacy/defaults.js";
import "./js/bcore_legacy/roles_editor.js";
import "./js/bcore_legacy/module_editor.js";
import "./js/bcore_legacy/microtemplate.js";

import "./js/bcore_legacy/ui/slides.js";
import "./js/bcore_legacy/ui/find.js";
import "./js/bcore_legacy/ui/iconbar.js";
import "./js/bcore_legacy/form/layout.js";
import "./js/bcore_legacy/ui/field_group.js";
import "./js/bcore_legacy/form/link_selector.js";
import "./js/bcore_legacy/form/multi_select_dialog.js";
import "./js/bcore_legacy/ui/dialog.js";
import "./js/bcore_legacy/ui/capture.js";
import "./js/bcore_legacy/ui/app_icon.js";
import "./js/bcore_legacy/ui/dropzone.js";

import "./js/bcore_legacy/model/model.js";
import "./js/bcore_legacy/db.js";
import "./js/bcore_legacy/model/meta.js";
import "./js/bcore_legacy/model/sync.js";
import "./js/bcore_legacy/model/create_new.js";
import "./js/bcore_legacy/model/perm.js";
import "./js/bcore_legacy/model/workflow.js";
import "./js/bcore_legacy/model/user_settings.js";

import "./js/bcore_legacy/utils/user.js";
import "./js/bcore_legacy/utils/common.js";
import "./js/bcore_legacy/utils/urllib.js";
import "./js/bcore_legacy/utils/pretty_date.js";
import "./js/bcore_legacy/utils/test_utils.js";
import "./js/bcore_legacy/utils/tools.js";
import "./js/bcore_legacy/utils/datetime.js";
import "./js/bcore_legacy/utils/number_format.js";
import "./js/bcore_legacy/utils/help.js";
import "./js/bcore_legacy/utils/help_links.js";
import "./js/bcore_legacy/utils/address_and_contact.js";
import "./js/bcore_legacy/utils/preview_email.js";
import "./js/bcore_legacy/utils/file_manager.js";

import "./js/bcore_legacy/ui/upload.html";
import "./js/bcore_legacy/upload.js";
import "./js/bcore_legacy/ui/tree.js";

import "./js/bcore_legacy/views/container.js";
// import "./js/bcore_legacy/views/breadcrumbs.js";
import "./js/bcore_legacy/views/factory.js";
import "./js/bcore_legacy/views/pageview.js";

import "./js/bcore_legacy/ui/toolbar/awesome_bar.js";
import "./js/bcore_legacy/ui/notifications/notifications.js";
import "./js/bcore_legacy/ui/toolbar/search.js";
import "./js/bcore_legacy/ui/toolbar/tag_utils.js";
import "./js/bcore_legacy/ui/toolbar/search.html";
import "./js/bcore_legacy/ui/toolbar/search_header.html";
import "./js/bcore_legacy/ui/toolbar/search_utils.js";
import "./js/bcore_legacy/ui/toolbar/about.js";
import "./js/bcore_legacy/ui/toolbar/navbar.html";
import "./js/bcore_legacy/ui/toolbar/toolbar.js";
import "./js/bcore_legacy/views/communication.js";
import "./js/bcore_legacy/views/translation_manager.js";

import "./js/bcore_legacy/ui/sort_selector.html";
import "./js/bcore_legacy/ui/sort_selector.js";

import "./js/bcore_legacy/change_log.html";

import "./js/bcore/controllers/page/page.html";

import "./js/bcore_legacy/desk.js";
import "./js/bcore_legacy/query_string.js";

import "./js/bcore_legacy/utils/rating_icons.html";

import "./js/bcore_legacy/social/social_factory.js";
import "./js/bcore_legacy/utils/energy_point_utils.js";
import "./js/bcore_legacy/utils/dashboard_utils.js";
import "./js/bcore_legacy/ui/chart.js";
import "./js/bcore_legacy/link_title.js";
import "./js/bcore_legacy/barcode_scanner/index.js";

import "./js/bcore_legacy/widgets/widget_group.js";
import "./js/bcore_legacy/widgets/utils.js";

// Lists
import "./js/bcore_legacy/ui/listing.html";

import "./js/bcore_legacy/model/indicator";
import "./js/bcore_legacy/ui/filters/filter";
import "./js/bcore_legacy/ui/filters/filter_list";
import "./js/bcore_legacy/ui/filters/field_select";
import "./js/bcore_legacy/ui/filters/edit_filter.html";
import "./js/bcore_legacy/ui/tags";
import "./js/bcore_legacy/ui/tag_editor";
import "./js/bcore_legacy/ui/like";
import "./html/print_template.html";

import "./js/bcore_legacy/list/base_list";
import "./js/bcore_legacy/list/list_view";
import "./js/bcore_legacy/list/list_factory";

import "./js/bcore_legacy/list/list_sidebar";
import "./js/bcore_legacy/list/list_sidebar.html";
import "./js/bcore_legacy/list/list_sidebar_stat.html";
import "./js/bcore_legacy/list/list_sidebar_group_by";
import "./js/bcore_legacy/list/list_view_permission_restrictions.html";

import "./js/bcore_legacy/views/gantt/gantt_view";
import "./js/bcore_legacy/views/tree/tree_view";
import "./js/bcore_legacy/views/calendar/calendar";
import "./js/bcore_legacy/views/dashboard/dashboard_view";
import "./js/bcore_legacy/views/image/image_view";
import "./js/bcore_legacy/views/kanban/kanban_view";
import "./js/bcore_legacy/views/inbox/inbox_view";
import "./js/bcore_legacy/views/file/file_view";

import "./js/bcore_legacy/views/treeview";
import "./js/bcore_legacy/views/interaction";

import "./js/bcore_legacy/views/image/image_view_item_row.html";
import "./js/bcore_legacy/views/image/photoswipe_dom.html";

import "./js/bcore_legacy/views/kanban/kanban_board.html";
import "./js/bcore_legacy/views/kanban/kanban_column.html";
import "./js/bcore_legacy/views/kanban/kanban_card.html";
import "./js/bcore_legacy/views/kanban/kanban_board";
import "./js/bcore_legacy/views/kanban/kanban_view";

// Forms
import "./js/bcore_legacy/form/templates/print_layout.html";
import "./js/bcore_legacy/form/templates/set_sharing.html";
import "./js/bcore_legacy/form/templates/form_sidebar.html";
import "./js/bcore_legacy/form/templates/form_dashboard.html";
import "./js/bcore_legacy/form/templates/form_links.html";
import "./js/bcore_legacy/form/templates/report_links.html";
import "./js/bcore_legacy/form/templates/attachment.html";
import "./js/bcore_legacy/form/templates/form_footer.html";
import "./js/bcore_legacy/form/templates/timeline.html";
import "./js/bcore_legacy/form/templates/timeline_item.html";
import "./js/bcore_legacy/views/formview.js";
import "./js/bcore_legacy/form/form.js";
import "./js/bcore_legacy/meta_tag.js";

// Control
import "./js/bcore_legacy/ui/capture.js";
import "./js/bcore_legacy/form/controls/control.js";

// Reports
import "./js/bcore_legacy/views/reports/report_factory";
import "./js/bcore_legacy/views/reports/report_view";
import "./js/bcore_legacy/views/reports/query_report";
import "./js/bcore_legacy/views/reports/print_grid.html";
import "./js/bcore_legacy/views/reports/print_tree.html";
import "./js/bcore_legacy/ui/group_by/group_by.html";
import "./js/bcore_legacy/ui/group_by/group_by";
import "./js/bcore_legacy/views/reports/report_utils";

// Sentry
import "./js/integrations/sentry";