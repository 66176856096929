import { Component } from "../../../compose";
import { EVT_CONSTRUCT } from "../../../events";
import { EVT_COMPONENT_MOUNT, EVT_COMPONENT_UNMOUNT } from "../events";

/**
 * Provides a hierarchical api to store values between parent and child nodes.
 * Similar to react's context provider and consumer api
 */
export class ContextComponent extends Component {

	[EVT_CONSTRUCT]() {
		this.context = new Map();
		this.context_subscriptions = new Map();
		this.context_handler = (e) => {
			if (this.context_subscriptions.has(e.detail.context)) {
				const ctx = this.get_context(e.detail.context, false);
				if ( ctx != this.context_subscriptions.get(e.detail.context)) {
					this.parent.element.update();
				}
			}
		}
	}

	find_context(name) {
		if (this.context.has(name)) {
			const result = this.context.get(name);
			return result;
		}

		let node = this.parent.element.parentElement;
		while (node) {
			if (Reflect.has(node, "__web_component")) {
				const result = node.__web_component[ContextComponent].find_context(name);
				return result;
			}
			node = node.parentElement || node.parentNode || node.host;
		}

		return undefined;
	}

	[EVT_COMPONENT_MOUNT]() {
		document.addEventListener("bcore-context-update", this.context_handler, true);
	}

	[EVT_COMPONENT_UNMOUNT]() {
		document.removeEventListener("bcore-context-update", this.context_handler);
	}

	get_context(name, updateSubscription=true) {
		const ctx = this.find_context(name);
		if ( updateSubscription ) {
			this.context_subscriptions.set(name, ctx);
		}
		return ctx;
	}

	async set_context(name, value) {
		let setContext = true;
		// TODO: Optimize by doing deep isEqual test to avoid triggering unecessary rendering.
		if (this.context.has(name) && this.context.get(name) == value) {
			setContext = false;
		}

		if (setContext) {
			this.context.set(name, value);
			document.dispatchEvent(new CustomEvent("bcore-context-update", { detail: { context: name }, composed: true, cancelable: false }));
		}
	}
}