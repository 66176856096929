import './typedef';

bcore.breakpoints = [
	{ name: "xs", size: 0 },
	{ name: "sm", size: 576 },
	{ name: "md", size: 768 },
	{ name: "lg", size: 992 },
	{ name: "xl", size: 1200 },
	{ name: "xxl", size: 1400 }
];

export const to_str = (v) => (typeof v !== "undefined" && v !== null) ? `${v}` : undefined;
export const parse_json = (data) => {
	try {
		return JSON.parse(data);
	} catch (e) {
		console.warn("Could not parse json data");
		return undefined;
	}
}
export const to_number = (v) => parseFloat(v);
export const to_bool = (v) => {
	const v_str = `${v}`.toLowerCase().trim();
	if (v_str == "true" || v_str == "1") {
		return true;
	} else if (v_str == "false" || v_str == "0") {
		return false;
	}
	return !!v_str;
}
export const enums = function (...values) {
	return (v) => {
		if (values.includes(v)) {
			return v;
		}
		return undefined;
	}
}
export const passthrough = (x) => x;
export const prop_to_dom_event_map = (event, event_dict) => (wc, old, value) => wc.element.dispatchEvent(new Event(event, event_dict))
export const to_array = (x) => {
	if (x) {
		if (Array.isArray(x)) {
			return x;
		} else if (typeof x === "string") {
			try {
				x = JSON.parse(x);
				return to_array(x);
			} catch (ex) {
				// pass
			}
		}
	}

	return [];
}

export const list_breakpoints = () => {
	return bcore.breakpoints;
}

export const find_breakpoint_by_size = (size) => {
	return bcore.breakpoints
		.filter((b) => size >= b.size)
		.pop();
}

export const find_cascading_breakpoints = (size) => {
	return bcore.breakpoints
		.filter((b) => size >= b.size);
}

export const find_breakpoint_size = (name) => {
	return bcore.breakpoints.find((b) => b.name == name).size;
}

export const find_element_breakpoint = (element) => {
	const width = element.clientWidth;
	if (width === 0) {
		return undefined;
	}
	return find_breakpoint_by_size(width);
}

/**
 * React helper shorthand to check wether a reference has resolved
 * @param {*} ref 
 */
export const is_ref_valid = (ref) => ref && ref.current

/**
 * get the string number without any separator i.e comma
 * @param {string} decorated_input number string
 * @returns {string}
 */
export const remove_commas = (decorated_input) => {
	let string_number = decorated_input.replace(/,/g, '');
	return string_number;
}

/**
 * counts the total number of comma in the parameter
 * @param {string} text this is a created formatted text for the current input value using `add_percent_formatting` function
 * @returns {number}
 */
export const comma_counter = (text) => {
	let total_commas = (text.match(/,/g) || []).length;
	return total_commas;
}

/**
 * Adds the percent formatting to a number
 * @param {string} input  user entered input value
 * @returns {string}
 */
export const add_percent_formatting = (input) => {
	let decimal_separated_values = [];
	let decorated_value;
	if (input.includes(".")) {
		decimal_separated_values = input.split(".");
		/**
		 * toLocalString confirms work to Octillion i.e 10^27
		 */
		let localstring = parseInt(decimal_separated_values[0]).toLocaleString();
		let decimal = decimal_separated_values[1].substring(0, 2);
		decorated_value = `${localstring}.${decimal}`;
	} else {
		decorated_value = `${parseInt(input).toLocaleString()}.00`
	}
	return decorated_value;
}

/** 
 * Todo: see if we can make a custom hooks out of sync_cursor_position
 */

/**
 * Corrects the cursor position when a separator is added
 * by incrementing the cursor position by the number of separator characters.
 * it's hard corded to one as we only increment it by dot and commas
 * @param {number} total_separator_count current total separator count 
 * @param {number} prev_separator_count previous total separator count
 * @param {set_separator_count}
 */
export const sync_cursor_position = (total_separator_count, prev_separator_count, set_separator_count) => {
	if (total_separator_count === (prev_separator_count + 1)) {
		set_separator_count((prev) => prev = prev + 1)
	}
}
