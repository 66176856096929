
	bcore.templates['navbar'] = `<div class="flex-fluid navbar" role="navigation">
	<div class="flex-row jc-space-between ai-center rel">
		<div class="d-flex navbar-desk flex-row ai-center gap-2h">
			<div class="flex-static d-flex flex-row ai-center md-hide sidebar-toggle">
				<span class="btn-sm d-block flex-static p-0 m-0 sidebar-toggle-close">
					{{ bcore.load_icon("arrow-bar-to-left", "", "24px", 1) }}
				</span>
				<span class="btn-sm d-block flex-static p-0 m-0 sidebar-toggle-open">
					{{ bcore.load_icon("arrow-bar-right", "", "24px", 1) }}
				</span>
			</div>
			<a href="/desk" class="d-block flex-static xxs-h-24px"><img src="{{ config.app_logo_url }}" class="h-100p p-0" /></a>
			<ul class="list-unstyled d-flex flex-row ai-center gap-2h" id="navbar-breadcrumbs">
			</ul>
		</div>
		<div class="navbar-center ellipsis hide"></div>


		<form class="navbar-form xxs-hide md-d-block" role="search">
			<div class="form-group ui-front ">
				<input id="navbar-search" type="text" class="form-control input-sm"
					placeholder="Search or type a command (Ctrl + G)" aria-haspopup="true">
				<span class="octicon octicon-search navbar-search-icon"></span>
			</div>
		</form>

		<div class="d-flex ai-center gap-1">
			<div class="md-hide">
				<a class="navbar-search-button" href="#" data-toggle="modal" data-target="#search-modal"><i
						class="octicon octicon-search"></i></a>
			</div>
			<div class="dropdown dropdown-navbar-user dropdown-mobile">
				<a class="dropdown-toggle" data-toggle="dropdown">
					<span id="avatar" class="mr-5px"></span>
					<span class="ellipsis toolbar-user-fullname hidden-xs hidden-sm">Settings</span>
					<b class="caret hidden-xs hidden-sm"></b>
				</a>
				<ul class="dropdown-menu dropdown-menu-right" id="toolbar-user" role="menu">
					<li><a class="my-user-settings">
							My Settings</a></li>
					<li><a id="setup_session_defaults">
							Session Defaults</a></li>
					<li class="navbar-reload">
						<a class="clear_cache">
							Reload</a>
					</li>
					<li><a href="/index" target="_blank" rel="noopener noreferrer">
							View Website</a></li>
					<li><a href="#background_jobs">
							Background Jobs</a></li>
					<li class="divider"></li>
					<li><a href="#" class="logout">
							Logout</a></li>
				</ul>
			</div>
			<div class="dropdown dropdown-mobile">
				<a class="dropdown-toggle" data-toggle="dropdown">
					<span class="xxs-hide md-d-inline-block">Help</span>
					<span class="avatar avatar-small help-icon md-hide">
						{{ bcore.load_icon("question-mark", "", "24px", 1) }}
					</span>
					<b class="caret hidden-xs hidden-sm"></b></a>
				</a>
				<ul class="dropdown-menu dropdown-menu-right" id="toolbar-help" role="menu">
					<li><a id="about_us">
							About</a></li>
					<li><a id="shortcuts">
							Keyboard Shortcuts</a></li>
				</ul>
				</li>
			</div>
			<div class="dropdown-notifications">
				<span class="notifications-indicator"><i class="fa fa-circle"></i></span>
				<a id="notifications-icon" class="notifications-icon xs-f-2 " href="#" onclick="return false;">
					<span class="btn-sm p-0 flex-row ai-center jc-center">{{ bcore.load_icon("bell", "", "18px", 1) }}</span>
				</a>
			</div>
		</div>


		<div id="search-modal" class="modal fade" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<form role="search">
							<div class="input-group w-100p">
								<input id="modal-search" type="text" class="form-control"
									placeholder="Search or type a command" aria-haspopup="true">
								<button type="reset" class="close" data-dismiss="modal"><i
										class="octicon octicon-x"></i></button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>`;
