import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";
import { ToolBarComponent } from "./toolbar";
import { ExtrasPanelController } from "../../extras_panel";
import { EVT_AFTER_INIT } from "../../../events";

export class ExtrasPanelComponent extends ComponentDependencies(
	BootInfoComponent,
	ToolBarComponent
) {
	async [EVT_AFTER_INIT]() {
		this.parent.extras_panel = new ExtrasPanelController(this.parent);
		await this.parent.extras_panel.init();
	}	
}