import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";

/**
 * Displays the change log popup to users if they haven't seen them.
 */
export class ChangeLogComponent extends ComponentDependencies(BootInfoComponent) {
	on_startup() {
		if (bcore.user_roles.includes('System Manager')) {
			var me = this;
			let change_log = bcore.boot.change_log;
			this.broadcast("show_notes");
  
			// bcore.boot.change_log = [{
			// 	"change_log": [
			// 		[<version>, <change_log in markdown>],
			// 		[<version>, <change_log in markdown>],
			// 	],
			// 	"description": "ERP made simple",
			// 	"title": "ERPNext",
			// 	"version": "12.2.0"
			// }];
  
			if (!Array.isArray(change_log) || !change_log.length || window.Cypress) {
				return;
			}
  
			// Iterate over changelog
			let change_log_dialog = bcore.msgprint({
				message: bcore.render_template("change_log", {"change_log": change_log}),
				title: __("Updated To New Version 🎉"),
				wide: true,
				scroll: true
			});
			change_log_dialog.keep_open = true;
			change_log_dialog.custom_onhide = () => {
				bcore.call({
					"method": "bcore.utils.change_log.update_last_known_versions"
				});
				this.broadcast("show_notes");
			};

			bcore.call({
				"method": "bcore.utils.change_log.show_update_popup"
			});
		} else {
			this.broadcast("show_notes");
		}
	}
}