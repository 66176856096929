import { useEffect, useCallback } from 'react';

/**
 * @typedef BCoreCallResponse
 * @property {object} message The response message sent back from the server.
 * @property {string} _exc Server side exception
 */

/**
 * Wrapps the bcore.call method to use as a react hook.
 *
 * @param {string} method The method to call on the backend
 * @param {Array<*>} args Arguments to the method
 * @param {(r: BCoreCallResponse) => void} callback A callback to process results
 * @param {Array<*>} deps List of dependencies objects
 */
export const useBCoreCall = (method, args, callback, deps) => {
	useEffect(
		useCallback(async () => {
			try {
				const r = await bcore.call(method, args);
				callback(r, undefined);
			} catch (err) {
				console.error(err);
				callback(undefined, err);
			}
		}, deps || []),
		deps || []);
}