
	bcore.templates['print_layout'] = `<div class="form-print-wrapper md-bl-solid md-br-solid">
	<div class="print-toolbar md-grid-6x1 pv-1 ph-1 bb-solid bg-secondary gap-1">
		<div class="pos-1_1 mb-1 md-m-0">
			<select class="print-preview-select input-sm form-control"></select>
		</div>
		<div class="pos-2_1 mb-1 md-m-0">
			<select class="languages input-sm form-control" placeholder="{{ __(" Language") }}"></select>
		</div>
		<div class="pos-3_1 d-flex jc-start ai-center mb-1 md-m-0">
			<div class="checkbox f-1h m-0">
				<label class="d-flex jc-start ai-center"><input type="checkbox" class="print-letterhead text-muted" />
					<span class="mt-1h md-m-0">{%= __("Letter Head") %}</span></label>
			</div>
		</div>
		<div class="pos-4_1 col-span-3 md-t-right mt-1h md-m-0">
			<!-- <a class="close btn-print-close" style="margin-top: 2px; margin-left: 10px;">&times;</a> -->
			<div class="btn-group">
				<a class="btn-print-print btn btn-default">
					<strong>{%= __("Print") %}</strong></a>
				<a class="ph-1 btn btn-default" href="#Form/Print Settings">
					{%= __("Settings...") %}</a>
				<a class="btn-printer-setting ph-1 btn btn-default" style="display: none;">
					{%= __("Printer Settings...") %}</a>
				<a class="btn-print-edit ph-1 btn btn-default">
					{%= __("Customize...") %}</a>
				<a class="btn-print-preview ph-1 btn btn-default">
					{%= __("Full Page") %}</a>
				<a class="btn-download-pdf ph-1 btn btn-default">
					{%= __("PDF") %}</a>
			</div>
		</div>
	</div>
	<div class="print-preview-wrapper">
		<div class="print-preview mh-2 pv-3">
			<div class="print-format min-w-70p"></div>
		</div>
		<div class="page-break-message text-muted text-center text-medium margin-top hide"></div>
	</div>
</div>`;
