import clsx from "clsx"

export const Button = ({label, large, small, intent, className, children, onClick}) => {
	return <span 
		className={clsx({
			"btn": !large && !small,
			"btn-sm": small && !large,
			"btn-lg": large && !small,
			[`btn-${intent}`]: !!intent
		}, "flex-row", "ai-center", "jc-center", className)}
		onClick={onClick}
	>{label || children}</span>
}