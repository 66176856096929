import { Component } from "../../../compose";

export class GeneralNotificationsComponent extends Component {

	normalize(item) {
		const title = item.subject.match(/<b class="subject-title">(.*?)<\/b>/);
		return {
			type: "Notification",
			doc_link: bcore.utils.get_form_link(
				item.document_type,
				item.document_name),
			title,
			message: title ? item.subject.replace(title[1], bcore.ellipsis(title[1], 100)) : item.subject,
			user: item.from_user,
			user_avatar: bcore.avatar(item.from_user, 'avatar-small user-avatar'),
			timestamp: bcore.datetime.pretty_date(item.creation, true),
			read: item.read,
			name: item.name
		}
	}

	async on_update(limit) {
		const result = await bcore.db.get_list('Notification Log', {
			fields: ['*'],
			limit: limit || 100,
			order_by: 'creation desc'
		});

		this.parent.notifications.push(...result.map(this.normalize));
	}
}