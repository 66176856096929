import { Component } from "../../../component";

export class SocialComponent extends Component {
	on_startup() {
		bcore.realtime.on('mention', (message) => {
			if (bcore.get_route()[0] !== 'social') {
				bcore.show_alert(message);
			}
		});
	}
}