import { Component } from "../../../compose";

export class OpenDocumentsComponent extends Component {
	normalize_open_doctypes(open) {
		return Object.entries(open).reduce((result, [key, value]) => {
			if (value > 0) {
				result.push([key, value]);
			}
			return result;
		}, []).map(([key, value]) => {
			return {
				type: "Open",
				title: key,
				value: value
			}
		});
	}

	normalize_targets(targets) {
		const results = [];
		for (const top of Object.values(targets)) {
			results.push(...Object.entries(top).map(([key, value]) => {
				return {
					type: "Target",
					title: key,
					value: value
				}
			}))
		}
		return results;
	}

	async on_update(limit) {
		const info = this.parent.info;
		this.parent.notifications.push(
			...this.normalize_targets(info.targets),
			...this.normalize_open_doctypes(info.open_count_doctype)
		)
	}
}