import { Component, ComponentDependencies } from "../../../compose";
import { EVT_AFTER_INIT } from "../../../events";
import { NotificationPanel } from "../../../react/apps/notifications";
import { NotificationsController } from "../../notifications";
import { ExtrasPanelController } from "../../extras_panel";

export class NotificationsComponent extends ComponentDependencies(
	ExtrasPanelController
) {
	async [EVT_AFTER_INIT]() {
		this.parent.notifications = new NotificationsController();
		await this.parent.notifications.init();
		this.parent.extras_panel.add_panel("notifications", null, NotificationPanel);
	}
}