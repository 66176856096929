import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";
import { Container } from "../../app_container";
import { EVT_INIT } from "../../../events";
import { RouterInitComponent } from "./route_init";

/**
 * Injects page container into the dom.
 */
export class PageContainerComponent extends ComponentDependencies(
	BootInfoComponent,
	RouterInitComponent
) {
	async [EVT_INIT]() {

		if ($("#body_div").length) {
			bcore.temp_container = $("<div id='temp-container' style='display: none;'>")
				.appendTo("body");
			bcore.container = new Container();
			await bcore.container.init();
			bcore.router.on("route_updated", () => {
				$("#app-container").fadeIn("slow", () => {
					$("#splash").remove();
				});
			})
		}
	}
}