import { Component } from "../../../compose";
import { EVT_INIT } from "../../../events";

export class RouterHistoryComponent extends Component {
	[EVT_INIT]() {
		bcore.route_history_queue = [];

		const save_routes = bcore.utils.debounce(() => {
			const routes = bcore.route_history_queue;
			bcore.route_history_queue = [];
			bcore.xcall('bcore.deferred_insert.deferred_insert', {
				'doctype': 'Route History',
				'records': routes
			}).catch(() => {
				this.parent.route_history_queue.concat(routes);
			});
		}, 10000);

		this.on('change', () => {
			const route = this.parent.get_route();
			if (this.is_route_useful(route)) {
				bcore.route_history_queue.push({
					'user': bcore.session.user,
					'creation': bcore.datetime.now_datetime(),
					'route': bcore.get_route_str()
				});

				save_routes();
			}
		});
	}

	is_route_useful(route) {
		const routes_to_skip = ['Form', 'social', 'setup-wizard'];
		if (!route[1]) {
			return false;
		} else if ((route[0] === 'List' && !route[2]) || routes_to_skip.includes(route[0])) {

			return false;
		} else {
			return true;
		}
	}
}