import { Compose } from "../../compose";
import { EVT_CONSTRUCT, EVT_INIT } from "../../events";
import { io } from "socket.io-client/dist/socket.io.js";
import { EVT_FORM_LOAD, EVT_FORM_REFRESH, EVT_FORM_RENAME, EVT_FORM_UNLOAD } from "../../components/form_events/events";
import { with_throttle } from "../../utils";
import { EVT_REALTIME_CONNECT, EVT_REALTIME_RECONNECT, EVT_REALTIME_TASK_PROGRESS, EVT_REALTIME_TASK_STATUS_CHANGE } from "./events";
import { FormEventsComponent } from "../../components/form_events";
import { EventDebugger } from "../../components/event_debugger";

/**
 * Resolves protocol, host and port for socket io server.
 * @returns {string}
 */
const get_host = () => {
	let host = window.location.origin;
	if (window.config.dev_server) {
		const parts = host.split(":");
		const port = window.config.web_socket_port;
		if (parts.length > 2) {
			host = parts[0] + ":" + parts[1];
		}
		host = host + ":" + port;
	}
	return host;
}

/**
 * Handles realtime coms with server via socket io.
 */
export class RealtimeController extends Compose(
	FormEventsComponent,
	EventDebugger
) {

	/**
	 * @type {Map}
	 */
	open_docs;
	
	/**
	 * The last doctype we sen
	 * @type {object}
	 */
	last_doc;

	/**
	 * Open tasks
	 * @type {object}
	 */
	open_tasks;

	/**
	 * The controller's async constructor.
	 * @param {number} port A port to listen to
	 */
	[EVT_CONSTRUCT]() {
		this.open_tasks = {};
		this.open_docs = new Map();
		this.last_doc = null;

		// Throttle calls to doc_subscribe to 1 sec
		this.doc_subscribe = with_throttle(this.doc_subscribe, this, 1000);
	}

	/**
	 * Initializes the socketio browser client and binds events
	 */
	[EVT_INIT]() {
		//Enable secure option when using HTTPS
		if (window.location.protocol != "file:") {
			this.socket = io(get_host(), {
				secure: window.location.protocol == "https:",
				query: {
					"auth_sid": bcore.get_cookie("sid")
				}
			});
		} else {
			this.socket = io(window.localStorage.server);
		}

		if (!this.socket) {
			console.log("Unable to connect to " + get_host());
			// TODO: Consider throwing here
			return;
		}

		this.setup_listeners();
		this.uploader = new SocketIOUploader();

		window.onbeforeunload = () => {
			if (!cur_frm || cur_frm.is_new()) {
				return;
			}

			// if tab/window is closed, notify other users
			if (cur_frm.doc) {
				this.doc_close(cur_frm.doctype, cur_frm.docname);
			}
		}
	}

	/**
	 * Listens for form refresh event to notify the user opened a document
	 * @param {object} frm 
	 * @returns 
	 */
	[EVT_FORM_REFRESH](frm) {
		if (frm.is_new()) {
			return;
		}

		this.doc_open(frm.doctype, frm.docname);
	}

	/**
	 * Listens for form load events to notify the server user opened a document
	 * @param {*} frm 
	 * @returns 
	 */
	[EVT_FORM_LOAD](frm) {
		if (frm.is_new()) {
			return;
		}

		this.doc_open(frm.doctype, frm.docname);
	}

	/**
	 * Listens for form rename events to subscribe to the doctype
	 * @param {object} frm 
	 * @returns 
	 */
	[EVT_FORM_RENAME](frm) {
		if (frm.is_new()) {
			return;
		}

		this.doc_subscribe(frm.doctype, frm.docname);
	}

	/**
	 * Listens for form unload events to notify server user closed the document
	 * @param {object} frm 
	 * @returns 
	 */
	[EVT_FORM_UNLOAD](frm) {
		if (frm.is_new()) {
			return;
		}

		this.doc_close(frm.doctype, frm.docname);
	}

	/**
	 * Subscribes to server tasks
	 * @param {string} task_id 
	 * @param {oecjt} opts 
	 */
	subscribe(task_id, opts) {
		// TODO DEPRECATE

		this.socket.emit('task_subscribe', task_id);
		this.socket.emit('progress_subscribe', task_id);

		this.open_tasks[task_id] = opts;
	}

	/**
	 * Subscribes to doctype events
	 * @param {string} doctype The doctype to subscribe to
	 * @param {string} docname The doctype's name
	 */
	doc_subscribe(doctype, docname) {		
		if (this.open_docs.has(`${doctype}:${docname}`)) {
			return; // no op on already subcribed doctypes
		}

		this.socket.emit('doc_subscribe', doctype, docname);
		this.open_docs.set(`${doctype}:${docname}`, { doctype, docname });
	}

	/**
	 * Unsubscribes from doctype events
	 * @param {string} doctype The doctype to unsubscribe from
	 * @param {string} docname The doctype's name
	 */
	doc_unsubscribe(doctype, docname) {
		this.socket.emit('doc_unsubscribe', doctype, docname);
		this.open_docs.delete(`${doctype}:${docname}`);
	}

	/**
	 * Emits a doc_open event to the server
	 * @param {string} doctype The doctype
	 * @param {string} docname The doctype's name
	 */
	doc_open(doctype, docname) {
		// notify that the user has opened this doc, if not already notified
		if (!this.last_doc
			|| (this.last_doc.doctype != doctype && this.last_doc.docname != docname)) {
			this.socket.emit('doc_open', doctype, docname);
		}
		this.last_doc = { doctype, docname };
	}

	/**
	 * Emits a doc_close event to the server. Flagging a doctype as being closed.
	 * @param {string} doctype 
	 * @param {string} docname 
	 */
	doc_close(doctype, docname) {
		// notify that the user has closed this doc
		this.socket.emit('doc_close', doctype, docname);
	}

	/**
	 * Sets up socket.io event listeners
	 */
	setup_listeners() {

		// display a message to the user on event.
		this.socket.on('msgprint', bcore.msgprint);

		// execute script sent from server.
		// TODO: deprecate
		this.socket.on('eval_js', (message) => new Function(message)());

		// process progress event display
		this.socket.on('progress', (data) => {
			if (data.progress) {
				data.percent = flt(data.progress[0]) / data.progress[1] * 100;
			}
			if (data.percent) {
				if (data.percent == 100) {
					bcore.hide_progress();
				} else {
					bcore.show_progress(data.title || __("Progress"), data.percent, 100, data.description);
				}
			}
		});

		// catchall, routes socketio events ton composition events
		this.socket.onAny((event, ...args) => {
			this.broadcast(`socket_${event}`, ...args);
		});

		// process task status change events
		this.socket.on('task_status_change', (data) => {
			this.broadcast(EVT_REALTIME_TASK_STATUS_CHANGE, data, data.status.toLowerCase());
			this.process_response(data, data.status.toLowerCase());
		});

		// process task progress events
		this.socket.on('task_progress', (data) => {
			this.broadcast(EVT_REALTIME_TASK_PROGRESS, data, "progress");
			this.process_response(data, "progress");
		});

		let first_connect = true;
		// subscribe again to open_tasks
		this.socket.on("connect", () => {
			Object.entries(this.open_tasks).map(
				(task_id, opts) => this.subscribe(task_id, opts));
			
			Array.from(this.open_docs.values()).map(
				d => locals[d.doctype] &&
					locals[d.doctype][d.name] &&
					this.doc_subscribe(d.doctype, d.docname));

			if (cur_frm && cur_frm.doc) {
				this.doc_open(cur_frm.doc.doctype, cur_frm.doc.name);
			}

			if (first_connect) {
				first_connect = false;
				this.broadcast(EVT_REALTIME_CONNECT);
			} else {
				this.broadcast(EVT_REALTIME_RECONNECT);
			}
		});
	}

	/**
	 * Processes a response from the server and optionally invokes a method callback
	 * @param {object} data 
	 * @param {string} method 
	 */
	process_response(data, method) {
		if (!data) {
			return;
		}

		// success
		var opts = this.open_tasks[data.task_id];
		if (opts[method]) {
			opts[method](data);
		}

		// "callback" is std bcore term
		if (method === "success") {
			if (opts.callback) opts.callback(data);
		}

		// always
		bcore.request.cleanup(opts, data);
		if (opts.always) {
			opts.always(data);
		}

		// error
		if (data.status_code && data.status_code > 400 && opts.error) {
			opts.error(data);
		}
	}
}

class SocketIOUploader {
	constructor() {
		bcore.socketio.socket.on('upload-request-slice', (data) => {
			var place = data.currentSlice * this.chunk_size,
				slice = this.file.slice(place,
					place + Math.min(this.chunk_size, this.file.size - place));

			if (this.on_progress) {
				// update progress
				this.on_progress(place / this.file.size * 100);
			}

			this.reader.readAsArrayBuffer(slice);
			this.started = true;
			this.keep_alive();
		});

		bcore.socketio.socket.on('upload-end', (data) => {
			this.reader = null;
			this.file = null;
			if (data.file_url.substr(0, 7) === '/public') {
				data.file_url = data.file_url.substr(7);
			}
			this.callback(data);
		});

		bcore.socketio.socket.on('upload-error', (data) => {
			this.disconnect(false);
			bcore.msgprint({
				title: __('Upload Failed'),
				message: data.error,
				indicator: 'red'
			});
		});

		bcore.socketio.socket.on('disconnect', () => {
			this.disconnect();
		});
	}

	start({ file = null, is_private = 0, filename = '', callback = null, on_progress = null,
		chunk_size = 24576, fallback = null } = {}) {

		if (this.reader) {
			bcore.throw(__('File Upload in Progress. Please try again in a few moments.'));
		}

		function fallback_required() {
			return !bcore.socketio.socket.connected
				|| !(!bcore.boot.sysdefaults || bcore.boot.sysdefaults.use_socketio_to_upload_file);
		}

		if (fallback_required()) {
			return fallback ? fallback() : bcore.throw(__('Socketio is not connected. Cannot upload'));
		}

		this.reader = new FileReader();
		this.file = file;
		this.chunk_size = chunk_size;
		this.callback = callback;
		this.on_progress = on_progress;
		this.fallback = fallback;
		this.started = false;

		this.reader.onload = () => {
			bcore.socketio.socket.emit('upload-accept-slice', {
				is_private: is_private,
				name: filename,
				type: this.file.type,
				size: this.file.size,
				data: this.reader.result
			});
			this.keep_alive();
		};

		var slice = file.slice(0, this.chunk_size);
		this.reader.readAsArrayBuffer(slice);
	}

	keep_alive() {
		if (this.next_check) {
			clearTimeout(this.next_check);
		}
		this.next_check = setTimeout(() => {
			if (!this.started) {
				// upload never started, so try fallback
				if (this.fallback) {
					this.fallback();
				} else {
					this.disconnect();
				}
			}
			this.disconnect();
		}, 3000);
	}

	disconnect(with_message = true) {
		if (this.reader) {
			this.reader = null;
			this.file = null;
			bcore.hide_progress();
			if (with_message) {
				bcore.msgprint({
					title: __('File Upload'),
					message: __('File Upload Disconnected. Please try again.'),
					indicator: 'red'
				});
			}
		}
	}
}
