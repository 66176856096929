import { useRef, useState, useEffect } from "react";
import { queue_fetch } from "../../utils";
import { is_ref_valid } from "../../web_components/utils";
import clsx from "clsx";
import { use_mounted } from "../hooks/use_mounted";
import { use_local_cache } from "../hooks/use_local_cache";

/**
 * Renders an icon
 * @param {*} props
 */
export const Icon = ({ icon, className, stroke, fill, size }) => {
	const icon_url = `/assets/bcore/icons/${icon}.svg`;
	const ref = useRef();
	const [svg, set_svg] = use_local_cache(icon_url);
	const is_mounted = use_mounted();

	useEffect(async () => {
		const result = await queue_fetch(icon_url);
		if (is_mounted) {
			set_svg(await result.text());
		}
	}, [icon_url, set_svg, is_mounted]);

	useEffect(() => {
		if (is_ref_valid(ref) && svg) {
			ref.current.innerHTML = svg;
			const iconEl = ref.current.firstChild;
			iconEl.classList.add("w-100p", "h-100p");
			if (stroke) {
				iconEl.style.strokeWidth = stroke;
			}
			if (fill) {
				iconEl.style.fill = fill;
			}
		}
	}, [ref, svg, size, stroke, fill]);

	return <span ref={ref}
		style={{
			...(size && {
				width: size,
				height: size
			})
		}}
		className={clsx("d-inline-block", className)}></span>
}