
	bcore.templates['list_sidebar_stat'] = `
{% if(!stat.length) { %}
<li class="stat-no-records text-muted">{{ __("No records tagged.") }}</li>
{% } else {
	for (var i=0, l=stat.length; i < l; i++) {
		var stat_label = stat[i][0];
		var stat_count = stat[i][1];
%}
<li>
	<a class="stat-link badge-hover" data-label="{{ stat_label %}" data-field="{{ field %}" href="#" onclick="return false;">
		<span class="badge pull-right" style="position: relative">{{ stat_count }}</span>
		<span class="stat-label">{{ __(stat_label) }}</span>
	</a>
</li>
	{% }
} %}
`;
