
	bcore.templates['timeline_item'] = `<div class="timeline-item rel {% if (data.user_content) { %} m-2 user-content {% } else { %} mv-2 md-pl-70px notification-content {% } %} {{ data.color || "" }}"
	data-doctype="{{ data.doctype }}" data-name="{{ data.name }}" data-communication-type = "{{ data.communication_type }}">
	{% if (data.user_content) { %}
	<span class="pull-left avatar avatar-medium hidden-xs">
		{% if(data.user_info.image) { %}
		<div class="avatar-frame" style="background-image: url(\'{%= data.user_info.image %}\')"></div>
		{% } else { %}
		<div class="standard-image avatar-small" style="background-color: {{ data.user_info.color }}">
			{{ data.user_info.abbr }}</div>
		{% } %}
	</span>
	{% } %}

	<div class="media-body">
		<div class="media-content-wrapper">
			<div class="action-btns flex-row-r z-1 right-0 top-1h mt-1h abs">
				{% if(data.delete) { %}
				<div class="hidden-xs close-btn-container mh-1">
					<span class="small text-muted">
						{%= data.delete %}
					</span>
				</div>
				{% } %}
				{% if(data.edit) { %}
				<div class="edit-btn-container mr-1">
					<span class="small text-muted">
						{%= data.edit %}
					</span>
				</div>
				{% } %}
			</div>
			{% if(data.communication_type==="Communication"
				|| data.communication_type==="Feedback"
				|| (data.communication_type==="Comment"
					&& data.comment_type==="Comment")) { %}
			<div class="comment-header clearfix small bg-neutral-4 p-1 w-100p {% if (data.edit || data.delete) { %} links-active {% } %}">
				<span class="pull-left avatar avatar-small visible-xs">
					{% if(data.user_info.image) { %}
					<div class="avatar-frame" style="background-image: url(\'{%= data.user_info.image %}\')"></div>
					{% } else { %}
					<div class="standard-image" style="background-color: {{ data.user_info.color }}">
						{{ data.user_info.abbr }}</div>
					{% } %}
				</span>
				<div class="asset-details" data-communication-type = "{{ data.communication_type }}">
					<span class="author-wrap">
						<i class="{%= data.icon %} hidden-xs fa-fw"></i>
						<span title="{%= data.comment_by %}">{%= data.fullname %}</span>
					</span>
					<span>
						{% if (data.timeline_doctype===data.frm.doc.doctype
							&& data.timeline_name===data.frm.doc.name) { %}
							&ndash;
							<a href="#Form/{%= data.reference_doctype %}/{%= data.reference_name %}" class="text-muted">
								<strong>{{ __(data.reference_doctype) }}</strong>
									{{ data.reference_name }}
							</a>
						{% } %}
					</span>
						{% if(in_list(["Communication", "Feedback"], data.communication_type)) { %}
							{% if (bcore.model.can_read(\'Communication\')) { %}
							<a href="#Form/Communication/{%= data.name %}"
								class="text-muted">
							{% } %}

							{% if (data.delivery_status) {
								if (in_list(["Sent", "Clicked"], data.delivery_status)) {
									var indicator_class = "green";
								} else if (data.delivery_status === "Sending") {
									var indicator_class = "orange";
								} else if (in_list(["Opened", "Read"], data.delivery_status)) {
									var indicator_class = "blue";
								} else {
									var indicator_class = "red";
								}
							%}
							<span class="text-muted hidden-xs">&ndash;</span>
							<span class="indicator-right {%= indicator_class %}
								delivery-status-indicator"
								title="{%= __(data.delivery_status) %}"><span class="hidden-xs">
								{%= __(data.delivery_status) %}</span></span>

							{% } else { %}
								{% if (bcore.model.can_read(\'Communication\')) { %}
								<span class="text-muted n-dash">&ndash;</span>
								{%= __("Details") %}
								{% } %}
							{% } %}

							{% if (bcore.model.can_read(\'Communication\')) { %}
							</a>
							{% } %}

							{% if (data.communication_medium === "Email"
								&& data.sender !== bcore.session.user_email) { %}
							<a class="text-muted reply-link pull-right timeline-content-show"
								data-name="{%= data.name %}" title="{%= __("Reply") %}">{%= __("Reply") %}</a>
							<a class="text-muted reply-link-all pull-right timeline-content-show"
								data-name="{%= data.name %}" title="{%= __("Reply All") %}">{%= __("Reply All") %}</a>
							{% } %}
						{% } %}
					<span class="text-muted commented-on hidden-xs {% if (data.futur_date) { %}timeline-futur{% } %}">
						&ndash; {%= data.comment_on %}</span>
					<span class="text-muted commented-on-small {% if (data.futur_date) { %}timeline-futur{% } %}">
						&ndash; {%= data.comment_on_small %}</span>
					<span class="comment-likes hidden-xs"
						data-liked-by=\'{{ JSON.stringify(data._liked_by) }}\'>
						<i class="octicon octicon-heart like-action
							{% if (!data.liked_by_user) { %}
								text-extra-muted not-liked
							{% } %} fa-fw"
							data-doctype="{%= data.doctype %}"
							data-name="{%= data.name %}"></i>
						<span class="likes-count text-muted hide">
							{{ (data._liked_by || []).length }}</span>
					</span>
				</div>
			</div>
			<div class="reply timeline-content-show p-1 b-solid">
				<div class="timeline-item-content">
					{% if data.show_subject %}
						<p class="text-muted small">
							<b>{{ __("Title") }}:</b> {{ data.subject }}</p>
						<hr>
					{% endif %}

					{% if data.communication_type == "Feedback" && data.rating_icons %}
						<p class="text-muted small">{{ data.rating_icons }}</p>
						<hr>
					{% endif %}

					<div class="timeline-item-content-html">

					</div>
				</div>
				<div class="timeline-item-edit"></div>
				{% if(data.attachments && data.attachments.length) { %}
				<div style="margin: 10px 0px">
					{% $.each(data.attachments, function(i, a) { %}
					<div class="ellipsis">
						<a href="{%= encodeURI(a.file_url).replace(/#/g, \'%23\') %}"
							class="text-muted small" target="_blank" rel="noopener noreferrer">
							<i class="fa fa-paperclip"></i>
							{%= a.file_url.split("/").slice(-1)[0] %}
							{% if (a.is_private) { %}
							<i class="fa fa-lock text-warning"></i>
							{% } %}
						</a>
					</div>
					{% }); %}
				</div>
				{% } %}
			</div>

			{% } else if(in_list(["Assignment Completed", "Assigned", "Shared",
				"Unshared", "Milestone"], data.comment_type)) { %}
				<div class="small">
					<i class="{%= data.icon %} fa-fw"></i>
						{% if (data.timeline_doctype===data.frm.doc.doctype
							&& data.timeline_name===data.frm.doc.name) { %}
							<a href="#Form/{%= data.reference_doctype %}/{%= data.reference_name %}">
								<strong>{{ __(data.reference_doctype) }}</strong>
									{{ data.reference_name }}
							</a>
							&ndash;
						{% } %}
						{% if(data.link_doctype && data.link_name) { %}
						<a href="#Form/{%= data.link_doctype %}/{%= data.link_name %}">
						{% } %}
						{{ __(data.content) }}
						{% if(data.link_doctype && data.link_name) { %}
						</a>
						{% } %}
						<span class="text-muted commented-on" style="font-weight: normal;">
						&ndash; {%= data.comment_on %}</span>
				</div>
			{% } else { %}
				<div class="small">
					<i class="{%= data.icon %} fa-fw"></i>
					{% if (data.comment_type == "Like") { %}
					<span title="{%= data.comment_by %}">
						{% if (data.timeline_doctype===data.frm.doc.doctype
							&& data.timeline_name===data.frm.doc.name) { %}
							<a href="#Form/{%= data.reference_doctype %}/{%= data.reference_name %}">
								<strong>{{ __(data.reference_doctype) }}</strong>
									{{ data.reference_name }}
							</a> &ndash;
						{% } %}
						{%= __("Liked by {0}", [data.fullname]) %}
					</span>
					{% } else if (data.comment_type == "Energy Points") { %}
						{{ data.content_html }}
					{% } else { %}
						<b title="{{ data.comment_by }}">{%= data.fullname %}</b>
						{%= __(data.content) %}
						{% if (data.timeline_doctype===data.frm.doc.doctype
							&& data.timeline_name===data.frm.doc.name) { %}
							 &ndash;
							 <a href="#Form/{%= data.reference_doctype %}/{%= data.reference_name %}">
								<strong>{{ __(data.reference_doctype) }}</strong>
									{{ data.reference_name }}
							</a>
						{% } %}
					{% } %}
					<span class="text-muted commented-on" style="font-weight: normal;">
					&ndash; {%= data.comment_on %}</span>
				</div>
			{% } %}
		</div>
	</div>
</div>
`;
