
	bcore.templates['kanban_board'] = `<div class="kanban">
    <div class="kanban-column add-new-column">
        <div class="kanban-column-title compose-column">
            <a class="text-muted"> {{ __("Add a column") }}</a>
        </div>
        <form class="compose-column-form kanban-column-title">
            <input class="new-column-title" name="title" type="text" autocomplete="off">
        </form>
    </div>
    <div class="kanban-empty-state text-muted text-center" style="display: none;">
        {{ __("Loading...") }}
    </div>
</div>`;
