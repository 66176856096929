import { useState } from "react"
import { Icon } from "../icon";
import clsx from "clsx";

/**
 * Renders a collapsible container.
 * @param {*} props Collapsible component properties
 */
export const Collapsible = ({
	name, handle_click, label, open,
	labelClasses, contentClasses, children,
	actions
}) => {
	return <>
		<div className={clsx(
			"bt-solid", "p-1h",
			"flex-static", "flex-row", "gap-1h",
			"cursor-pointer",
			"ai-center", "z-1", labelClasses, {
			"bb-solid": open,
			"elevate": open
		}
		)}
			onClick={() => handle_click(name)}
		>
			<Icon icon={!open ? "chevron-right" : "chevron-down"} className="flex-static h-16px" />
			<span className="fw-bold">{label}</span>
			{ open && (typeof actions === "function" ? actions() : actions) }
		</div>
		<div className={clsx("overflow-auto flex-fluid", { "hide": !open })}>
			<div className={clsx("p-1h flex-col min-h-100p", contentClasses)}>
				{children}
			</div>
		</div>
	</>
}