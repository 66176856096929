import { Router } from "../../router";
import { ComponentDependencies } from "../../../compose";
import { BootInfoComponent } from "./boot_info";
import { EVT_INIT, EVT_AFTER_INIT } from "../../../events";

export class RouterInitComponent extends ComponentDependencies(
	BootInfoComponent
) {
	async [EVT_INIT]() {
		this.parent.router = new Router();

		// map router to old api
		bcore.router = this.parent.router;
		bcore.route = this.parent.router;

		await this.parent.router.init();
	}

	async [EVT_AFTER_INIT]() {
		// route to home page
		bcore.router.route();
	}
}