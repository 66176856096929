import clsx from "clsx";
import { to_str } from "./utils";
import { make_react_component } from "../controllers/web_components";
import { use_wc_ready } from "../react/hooks/use_loading";
import { useState } from "react";

export const Img = (props) => {
	const ready = use_wc_ready(props.$web_component);
	const [imageLoaded, setImageLoaded] = useState(false);
	const handleImageLoad = () => {
		setImageLoaded(true);
	}
	const { src, fit, backgroundColor, className } = props;
	const style = backgroundColor ? { backgroundColor } : {};
	const theme = props.$web_component.get_context("theme");

	props.$web_component.mountpoint.classList.add(theme);

	return <>
		{(!ready || !imageLoaded) && (
			<div className="skeleton tiny">
				<div className="skeleton-icon"></div>
			</div>
		)}
		<img
			src={src}
			style={style}
			className={clsx(className, 'm-auto', { "w-100p": fit == "width", "h-100p": fit == "height", "w-100p h-100p": fit == "stretch" })}
			onLoad={handleImageLoad}
		/>
	</>
}

make_react_component({
	tag: "b-img",
	component: Img,
	props: {
		src: to_str,
		fit: to_str
	}
});