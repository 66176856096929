import { to_str } from "./utils";
import { useState } from "react";
import { useBCoreCall } from "../react/hooks/bcore_hooks";
import { make_react_component } from "../controllers/web_components";
import clsx from "clsx";

export const NavLogo = ({ label, $web_component, fit }) => {
	const [appLogoUrl, setAppLogoUrl] = useState("");
	$web_component.element.classList.add("bg-transparent");

	useBCoreCall('bcore.client.get_hooks', { hook: 'app_logo_url' }, (r) => {
		setAppLogoUrl((r.message || []).slice(-1)[0]);
	}, []);

	return <a className={clsx("d-inline-flex", "flex-fluid")} href="#" aria-label={label}>
		<img src={appLogoUrl} className={clsx("bg-transparent", "m-auto", { "w-100p": fit == "width", "h-100p": fit == "height", "w-100p h-100p": fit == "stretch" })} />
	</a>
}

make_react_component({
	tag: "b-navlogo",
	component: NavLogo,
	props: {
		label: to_str,
		src: to_str,
		fit: to_str
	}
});