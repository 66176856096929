import { ComponentDependencies } from "../../../compose";
import { EVT_AFTER_INIT } from "../../../events";
import { BootInfoComponent } from "./boot_info";

/**
 * Initializes toolbar controller
 */
export class ToolBarComponent extends ComponentDependencies(BootInfoComponent) {
	[EVT_AFTER_INIT]() {
		if (bcore.boot && bcore.boot.home_page !== 'setup-wizard') {
			bcore.bcore_toolbar = new bcore.ui.toolbar.Toolbar();
			console.warn("Consider injecting new toolbar here...");
			this.set_my_settings_route();
			this.bind_events();
		}
	}

	set_my_settings_route() {
		let my_settings = $(".my-user-settings");
		my_settings.on('click', ()=>{
			bcore.set_route("Form", "User", bcore.session.user);
		});
	}
	
	bind_events() {
		$("#shortcuts").on('click', (event)=>{
			bcore.ui.toolbar.show_shortcuts(event);
		});
	
		$("#about_us").on("click", ()=>{
			bcore.ui.toolbar.show_about();
		});
	
		$(".logout").on("click", ()=>{
			bcore.app.logout();
		});
	
		$(".clear_cache").on("click", ()=>{
			bcore.ui.toolbar.clear_cache();
		});
	
		$(".setup_session_defaults").on("click", ()=>{
			bcore.ui.toolbar.setup_session_defaults();
		});
	}
	
}