import "../controllers/web_components";
// import "./b-navbar";
import "./b-navlogo";
import "./b-img";
// import "./b-awesomebar";
// import "./b-awesomebar-item";
// import "./b-autocomplete";
// import "./b-spacer";
import "./b-icon";
// import "./b-field";
// import "./b-input";
// import "./b-tagged";
// import "./b-infinite-scroller";
// import "./b-template";
// import "./b-panel";
// import "./b-props-panel";
// import "./b-draw-canvas";
// import "./b-text";
// import "./b-button";
// import "./b-expandable-panel";
// import "./b-tools-panel";
// import "./b-tab-panel";
// import "./b-tab";
// import "./florist";
// import "./b-input-percent";
// import "./b-input-currency";
// import "./b-standard-input";
// import "./b-check";
// import "./b-text-area";
// import "./b-barcode";
// import "./b-label";
// import "./b-rating";
// import "./b-calendar";
// import "./b-password";
// import "./b-attach-file";
// import "./b-signature";
// import "./fields";
// import "./b-color-input";
import "./b-extras-bar";
