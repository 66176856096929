import { useEffect, useMemo, useState } from "react";
import { make_react_component } from "../../controllers/web_components";
import { to_bool } from "../utils";
import clsx from "clsx";
import style from "./styles.scss";

const ExtrasPanel = ({ open, $web_component }) => {
	const [isOpen, setIsOpen] = useState(open || false);
	const [panels, setPanels] = useState([]);

	// Build icons and panel components
	const panel_components = useMemo(() => {
		const draw_icons = [];
		let draw_panel = null;
		for (const panel of panels) {
			if (panel.icon) {
				draw_icons.push(<img src={panel.icon} styles={{ maxWidth: '16px', maxHeight: '16px' }} />);
			}
			if (panel.component && isOpen == panel.id) {
				const Component = panel.component;
				draw_panel = <Component key={panel.id} id={panel.id} />
			}
		}
		return { icons: draw_icons, open_panel: draw_panel };
	}, [panels, isOpen]);

	// Listen for panel api events
	useEffect(() => {

		const handle_extras_panel_added = (e) => {
			setPanels([...panels, e.detail]);
		}
		const handle_open_panel = (e) => {
			setIsOpen(e.detail);
		}
		const handle_close_panel = (e) => {
			setIsOpen(false);
		}
		const handle_toggle_panel = (e) => {
			setIsOpen(isOpen == e.detail ? false : e.detail);
		}
		window.addEventListener("extras-panel-added", handle_extras_panel_added);
		window.addEventListener("extras-panel-open", handle_open_panel);
		window.addEventListener("extras-panel-close", handle_close_panel);
		window.addEventListener("extras-panel-toggle", handle_toggle_panel);

		return () => {
			window.removeEventListener("extras-panel-added", handle_extras_panel_added);
			window.removeEventListener("extras-panel-open", handle_open_panel);
			window.removeEventListener("extras-panel-close", handle_close_panel);
			window.removeEventListener("extras-panel-toggle", handle_toggle_panel);
		}
	}, [panels, isOpen, setIsOpen, setPanels])

	const el = $web_component.mountpoint;

	if (panel_components.icons.length > 0) {
		el.classList.add("has-icons");
	} else {
		el.classList.remove("has-icons");
	}

	if (isOpen) {
		el.classList.add("open");
		$web_component.element.classList.add("full-screen");
	} else {
		el.classList.remove("open");
		$web_component.element.classList.remove("full-screen");
	}

	return <div className={clsx("panel-content", "grid-2x1", "w-100p")}>
		{isOpen && <div className="content pos-1_1 overflow-hidden">{panel_components.open_panel}</div>}
		{panel_components.icons.length > 0 && <div className="icons pos-2_1">{panel_components.icons}</div>}
	</div>
}

make_react_component({
	tag: "b-extras-bar",
	style,
	props: {
		open: to_bool,
	},
	component: ExtrasPanel
})