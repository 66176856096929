import { Component } from "../../../compose";

const EVENT_CATEGORY_TO_ICON_MAP = {
	"Event": "calendar-event",
	"Meeting": "car",
	"Call": "phone-outgoing",
	"Sent/Received Email": "mail",
	"Holiday": "christmas-tree",
	"Other": false
}

export class TodayEventsComponents extends Component {
	normalize(event) {
		let time = bcore.datetime.get_time(event.starts_on);

		return {
			type: "Event",
			name: event.name,
			time,
			subject: event.subject,
			color: event.color,
			event_type: event.event_type,
			event_category: event.event_category,
			icon: EVENT_CATEGORY_TO_ICON_MAP[event.event_category] || false,
			description: event.description
		}
	}

	async on_update() {
		const today = bcore.datetime.get_today();
		this.events = await bcore.xcall('bcore.desk.doctype.event.event.get_events', {
			start: today,
			end: today
		});

		this.parent.notifications.push(...this.events.map(this.normalize));
	}
}