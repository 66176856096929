bcore.provide('bcore.ui.form');

import './quick_entry';
import './toolbar';
import './dashboard';
import './workflow';
import './save';
import './print';
import './success_action';
import './script_manager';
import './script_helpers';
import './sidebar/form_sidebar';
import './footer/footer';

export class Controller {
	constructor(opts) {
		Object.assign(this, opts);
	}
}
bcore.ui.form.Controller = Controller;

bcore.ui.form.Form = class bcoreForm {
	constructor(doctype, parent, in_form) {
		this.docname = '';
		this.doctype = doctype;
		this.hidden = false;
		this.refresh_if_stale_for = 120;

		var me = this;
		this.opendocs = {};
		this.custom_buttons = {};
		this.sections = [];
		this.grids = [];
		this.cscript = new Controller({ frm: this });
		this.events = {};
		this.pformat = {};
		this.fetch_dict = {};
		this.parent = parent;

		this.setup_meta(doctype);

		// show in form instead of in dialog, when called using url (router.js)
		this.in_form = in_form ? true : false;

		// notify on rename
		$(document).on('rename', function (event, dt, old_name, new_name) {
			if (dt == me.doctype)
				me.rename_notify(dt, old_name, new_name);
		});
	}

	setup_meta() {
		this.meta = bcore.get_doc('DocType', this.doctype);

		if (this.meta.istable) {
			this.meta.in_dialog = 1;
		}

		this.perm = bcore.perm.get_perm(this.doctype); // for create
		this.action_perm_type_map = {
			"Create": "create",
			"Save": "write",
			"Submit": "submit",
			"Update": "submit",
			"Cancel": "cancel",
			"Amend": "amend",
			"Delete": "delete"
		};
	}

	async setup() {
		bcore.dom.freeze("Loading");
		try {
			this.fields = [];
			this.fields_dict = {};
			this.state_fieldname = bcore.workflow.get_state_fieldname(this.doctype);

			// wrapper
			this.wrapper = this.parent;
			this.$wrapper = $(this.wrapper);
			bcore.ui.make_app_page({
				parent: this.wrapper,
				single_column: this.meta.hide_toolbar
			});
			this.page = this.wrapper.page;
			this.layout_main = this.page.main.get(0);

			this.toolbar = new bcore.ui.form.Toolbar({
				frm: this,
				page: this.page
			});

			// navigate records keyboard shortcuts
			this.add_nav_keyboard_shortcuts();

			// print layout
			this.setup_print_layout();

			// 2 column layout
			this.setup_std_layout();

			// client script must be called after "setup" - there are no fields_dict attached to the frm otherwise
			this.script_manager = new bcore.ui.form.ScriptManager({
				frm: this
			});
			await this.script_manager.setup();
			this.watch_model_updates();

			if (!this.meta.hide_toolbar) {
				this.footer = new bcore.ui.form.Footer({
					frm: this,
					parent: this.page.main
				});
				$("body").attr("data-sidebar", 1);
			}
			this.setup_file_drop();

			this.setup_done = true;
		} finally {
			bcore.dom.unfreeze();
		}
	}

	add_nav_keyboard_shortcuts() {
		bcore.ui.keys.add_shortcut({
			shortcut: 'shift+ctrl+>',
			action: () => this.navigate_records(0),
			page: this.page,
			description: __('Go to next record'),
			ignore_inputs: true,
			condition: () => !this.is_new()
		});

		bcore.ui.keys.add_shortcut({
			shortcut: 'shift+ctrl+<',
			action: () => this.navigate_records(1),
			page: this.page,
			description: __('Go to previous record'),
			ignore_inputs: true,
			condition: () => !this.is_new()
		});
	}

	setup_print_layout() {
		this.print_preview = new bcore.ui.form.PrintPreview({
			frm: this
		});

		// show edit button for print view
		this.page.wrapper.on('view-change', () => {
			this.toolbar.set_primary_action();
		});
	}

	setup_std_layout() {
		this.form_wrapper = $('<div></div>').appendTo(this.layout_main);
		this.body = $('<div></div>').appendTo(this.form_wrapper);

		// only tray
		this.meta.section_style = 'Simple'; // always simple!

		// layout
		this.layout = new bcore.ui.form.Layout({
			parent: this.body,
			doctype: this.doctype,
			frm: this,
			with_dashboard: true
		});
		this.layout.make();

		this.fields_dict = this.layout.fields_dict;
		this.fields = this.layout.fields_list;

		this.dashboard = new bcore.ui.form.Dashboard({
			frm: this,
		});

		// workflow state
		this.states = new bcore.ui.form.States({
			frm: this
		});
	}

	watch_model_updates() {
		// watch model updates
		var me = this;

		// on main doc
		bcore.model.on(me.doctype, "*", function (fieldname, value, doc) {
			// set input
			if (doc.name === me.docname) {
				if ((value === '' || value === null) && !doc[fieldname]) {
					// both the incoming and outgoing values are falsy
					// the texteditor, summernote, changes nulls to empty strings on render,
					// so ignore those changes
				} else {
					me.dirty();
				}

				let field = me.fields_dict[fieldname];
				field && field.refresh(fieldname);

				// Validate value for link field explicitly
				field && ["Link", "Dynamic Link"].includes(field.df.fieldtype) && field.validate && field.validate(value);

				me.layout.refresh_dependency();
				let object = me.script_manager.trigger(fieldname, doc.doctype, doc.name);
				return object;
			}
		});

		// on table fields
		var table_fields = bcore.get_children("DocType", me.doctype, "fields", {
			fieldtype: ["in", bcore.model.table_fields]
		});

		// using $.each to preserve df via closure
		$.each(table_fields, function (i, df) {
			bcore.model.on(df.options, "*", function (fieldname, value, doc) {
				if (doc.parent === me.docname && doc.parentfield === df.fieldname) {
					me.dirty();
					me.fields_dict[df.fieldname].grid.set_value(fieldname, value, doc);
					me.script_manager.trigger(fieldname, doc.doctype, doc.name);
				}
			});
		});
	}

	setup_file_drop() {
		var me = this;
		this.$wrapper.on('dragenter dragover', false)
			.on('drop', function (e) {
				var dataTransfer = e.originalEvent.dataTransfer;
				if (!(dataTransfer && dataTransfer.files && dataTransfer.files.length > 0)) {
					return;
				}

				e.stopPropagation();
				e.preventDefault();

				if (me.doc.__islocal) {
					bcore.msgprint(__("Please save before attaching."));
					throw "attach error";
				}

				if (me.attachments.max_reached()) {
					bcore.msgprint(__("Maximum Attachment Limit for this record reached."));
					throw "attach error";
				}

				new bcore.ui.FileUploader({
					doctype: me.doctype,
					docname: me.docname,
					files: dataTransfer.files,
					folder: 'Home/Attachments',
					on_success(file_doc) {
						me.attachments.attachment_uploaded(file_doc);
					}
				});
			});
	}

	// REFRESH

	async refresh(docname) {
		var switched = docname ? true : false;

		if (docname) {
			this.switch_doc(docname);
		}

		cur_frm = this;

		if (this.docname) { // document to show

			// set the doc
			this.doc = bcore.get_doc(this.doctype, this.docname);

			// check permissions
			if (!this.has_read_permission()) {
				bcore.show_not_permitted(__(this.doctype) + " " + __(this.docname));
				return;
			}

			// read only (workflow)
			this.read_only = bcore.workflow.is_read_only(this.doctype, this.docname);
			if (this.read_only) this.set_read_only(true);

			// check if doctype is already open
			if (!this.opendocs[this.docname]) {
				this.check_doctype_conflict(this.docname);
			} else {
				if (await this.check_reload()) {
					return;
				}
			}

			// do setup
			if (!this.setup_done) {
				await this.setup();
			}

			// load the record for the first time, if not loaded (call 'onload')
			await this.trigger_onload(switched);

			// if print format is shown, refresh the format
			if (this.print_preview.wrapper.is(":visible")) {
				this.print_preview.preview();
			}

			if (switched) {
				if (this.show_print_first && this.doc.docstatus === 1) {
					// show print view
					this.print_doc();
				}
			}

			// set status classes
			this.$wrapper.removeClass('validated-form')
				.toggleClass('editable-form', this.doc.docstatus === 0)
				.toggleClass('submitted-form', this.doc.docstatus === 1)
				.toggleClass('cancelled-form', this.doc.docstatus === 2);

			this.show_conflict_message();
		}
	}

	switch_doc(docname) {
		// record switch
		if (this.docname != docname && (!this.meta.in_dialog || this.in_form) && !this.meta.istable) {
			if (this.print_preview) {
				this.print_preview.hide();
			}
		}
		// reset visible columns, since column headings can change in different docs
		this.grids.forEach(grid_obj => grid_obj.grid.visible_columns = null);
		bcore.ui.form.close_grid_form();
		this.docname = docname;
	}

	async check_reload() {
		if (this.doc && (!this.doc.__unsaved) && this.doc.__last_sync_on &&
			(new Date() - this.doc.__last_sync_on) > (this.refresh_if_stale_for * 1000)) {
			await this.reload_doc();
			return true;
		}
	}

	async trigger_onload(switched) {
		this.cscript.is_onload = false;
		if (!this.opendocs[this.docname]) {
			var me = this;
			this.cscript.is_onload = true;
			await this.initialize_new_doc();
			$(document).trigger("form-load", [this]);
			$(this.page.wrapper).on('hide', function () {
				$(document).trigger("form-unload", [me]);
			});
		} else {
			this.render_form(switched);
			if (this.doc.localname) {
				// trigger form-rename and remove .localname
				delete this.doc.localname;
				$(document).trigger("form-rename", [this]);
			}
		}
	}

	async initialize_new_doc() {
		// moved this call to refresh function
		// this.check_doctype_conflict(docname);
		var me = this;

		// hide any open grid
		await this.script_manager.trigger("before_load", this.doctype, this.docname)
			.then(() => {
				me.script_manager.trigger("onload");
				me.opendocs[me.docname] = true;
				me.render_form();

				bcore.after_ajax(function () {
					me.trigger_link_fields();
				});

				bcore.breadcrumbs.add(me.meta.module, me.doctype);
			});

		// update seen
		if (this.meta.track_seen) {
			$('.list-id[data-name="' + me.docname + '"]').addClass('seen');
		}
	}

	render_form(switched) {
		if (!this.meta.istable) {
			this.layout.doc = this.doc;
			this.layout.attach_doc_and_docfields();

			this.sidebar = new bcore.ui.form.Sidebar({
				frm: this,
				page: this.page
			});
			this.sidebar.make();

			// clear layout message
			this.layout.show_message();

			bcore.run_serially([
				// header must be refreshed before client methods
				// because add_custom_button
				() => this.refresh_header(switched),
				// trigger global trigger
				// to use this
				() => $(document).trigger('form-refresh', [this]),
				// fields
				() => this.refresh_fields(),
				// call trigger
				() => this.script_manager.trigger("refresh"),
				// call onload post render for callbacks to be fired
				() => {
					if (this.cscript.is_onload) {
						return this.script_manager.trigger("onload_post_render");
					}
				},
				() => this.dashboard.after_refresh()
			]);
			// focus on first input

			if (this.is_new()) {
				var first = this.form_wrapper.find('.form-layout input:first');
				if (!in_list(["Date", "Datetime"], first.attr("data-fieldtype"))) {
					first.focus();
				}
			}
		} else {
			this.refresh_header(switched);
		}

		this.$wrapper.trigger('render_complete');

		if (!this.hidden) {
			this.layout.show_empty_form_message();
		}

		this.scroll_to_element();
	}

	refresh_fields() {
		this.layout.refresh(this.doc);
		this.layout.primary_button = this.$wrapper.find(".btn-primary");

		// cleanup activities after refresh
		this.cleanup_refresh(this);
	}

	cleanup_refresh() {
		if (this.fields_dict['amended_from']) {
			if (this.doc.amended_from) {
				unhide_field('amended_from');
				if (this.fields_dict['amendment_date']) unhide_field('amendment_date');
			} else {
				hide_field('amended_from');
				if (this.fields_dict['amendment_date']) hide_field('amendment_date');
			}
		}

		if (this.fields_dict['trash_reason']) {
			if (this.doc.trash_reason && this.doc.docstatus == 2) {
				unhide_field('trash_reason');
			} else {
				hide_field('trash_reason');
			}
		}

		if (this.meta.autoname && this.meta.autoname.substr(0, 6) == 'field:' && !this.doc.__islocal) {
			var fn = this.meta.autoname.substr(6);

			if (this.doc[fn]) {
				this.toggle_display(fn, false);
			}
		}

		if (this.meta.autoname == "naming_series:" && !this.doc.__islocal) {
			this.toggle_display("naming_series", false);
		}
	}

	refresh_header(switched) {
		// set title
		// main title
		if (!this.meta.in_dialog || this.in_form) {
			bcore.utils.set_title(this.meta.issingle ? this.doctype : this.docname);
		}

		// show / hide buttons
		if (this.toolbar) {
			if (switched) {
				this.toolbar.current_status = undefined;
			}
			this.toolbar.refresh();
		}

		this.dashboard.refresh();

		this.show_submit_message();
		this.clear_custom_buttons();
		this.show_web_link();
	}

	// SAVE

	save_or_update() {
		if (this.save_disabled) return;

		if (this.doc.docstatus === 0) {
			this.save();
		} else if (this.doc.docstatus === 1 && this.doc.__unsaved) {
			this.save("Update");
		}
	}

	save(save_action, callback, btn, on_error) {
		let me = this;
		return new Promise((resolve, reject) => {
			btn && $(btn).prop("disabled", true);
			$(document.activeElement).blur();

			bcore.ui.form.close_grid_form();
			// let any pending js process finish
			setTimeout(function () {
				me.validate_and_save(save_action, callback, btn, on_error, resolve, reject);
			}, 100);
		}).then(() => {
			me.show_success_action();
		}).catch((e) => {
			console.error(e); // eslint-disable-line
		});
	}

	validate_and_save(save_action, callback, btn, on_error, resolve, reject) {
		var me = this;
		if (!save_action) save_action = "Save";
		this.validate_form_action(save_action, resolve);

		if ((!this.meta.in_dialog || this.in_form) && !this.meta.istable) {
			bcore.utils.scroll_to(0);
		}
		var after_save = function (r) {
			if (!r.exc) {
				if (["Save", "Update", "Amend"].indexOf(save_action) !== -1) {
					bcore.utils.play_sound("click");
				}

				me.script_manager.trigger("after_save");
				// submit comment if entered
				if (me.timeline) {
					me.timeline.comment_area.submit();
				}
				me.refresh();
			} else {
				if (on_error) {
					on_error();
					reject();
				}
			}
			callback && callback(r);
			resolve();
		};

		var fail = (e) => {
			if (e) {
				console.error(e)
			}
			btn && $(btn).prop("disabled", false);
			if (on_error) {
				on_error();
				reject();
			}
		};

		if (save_action != "Update") {
			// validate
			bcore.validated = true;
			bcore.run_serially([
				() => this.script_manager.trigger("validate"),
				() => this.script_manager.trigger("before_save"),
				() => {
					if (!bcore.validated) {
						fail();
						return;
					}

					bcore.ui.form.save(me, save_action, after_save, btn);
				}
			]).catch(fail);
		} else {
			bcore.ui.form.save(me, save_action, after_save, btn);
		}
	}

	savesubmit(btn, callback, on_error) {
		var me = this;
		return new Promise(resolve => {
			this.validate_form_action("Submit");
			bcore.confirm(__("Permanently Submit {0}?", [this.docname]), function () {
				bcore.validated = true;
				me.script_manager.trigger("before_submit").then(function () {
					if (!bcore.validated) {
						return me.handle_save_fail(btn, on_error);
					}

					me.save('Submit', function (r) {
						if (r.exc) {
							me.handle_save_fail(btn, on_error);
						} else {
							bcore.utils.play_sound("submit");
							callback && callback();
							me.script_manager.trigger("on_submit")
								.then(() => resolve(me));
						}
					}, btn, () => me.handle_save_fail(btn, on_error), resolve);
				});
			}, () => me.handle_save_fail(btn, on_error));
		});
	}

	savecancel(btn, callback, on_error) {
		var me = this;

		this.validate_form_action('Cancel');
		bcore.confirm(__("Permanently Cancel {0}?", [this.docname]), function () {
			bcore.validated = true;
			me.script_manager.trigger("before_cancel").then(function () {
				if (!bcore.validated) {
					return me.handle_save_fail(btn, on_error);
				}

				var after_cancel = function (r) {
					if (r.exc) {
						me.handle_save_fail(btn, on_error);
					} else {
						bcore.utils.play_sound("cancel");
						me.refresh();
						callback && callback();
						me.script_manager.trigger("after_cancel");
					}
				};
				bcore.ui.form.save(me, "cancel", after_cancel, btn);
			});
		}, () => me.handle_save_fail(btn, on_error));
	}

	savetrash() {
		this.validate_form_action("Delete");
		bcore.model.delete_doc(this.doctype, this.docname, function () {
			window.history.back();
		});
	}

	amend_doc() {
		if (!this.fields_dict['amended_from']) {
			bcore.msgprint(__('"amended_from" field must be present to do an amendment.'));
			return;
		}

		bcore.xcall('bcore.client.is_document_amended', {
			'doctype': this.doc.doctype,
			'docname': this.doc.name
		}).then(is_amended => {
			if (is_amended) {
				bcore.throw(__('This document is already amended, you cannot ammend it again'));
			}
			this.validate_form_action("Amend");
			var me = this;
			var fn = function (newdoc) {
				newdoc.amended_from = me.docname;
				if (me.fields_dict && me.fields_dict['amendment_date'])
					newdoc.amendment_date = bcore.datetime.obj_to_str(new Date());
			};
			this.copy_doc(fn, 1);
			bcore.utils.play_sound("click");
		});
	}

	validate_form_action(action, resolve) {
		var perm_to_check = this.action_perm_type_map[action];
		var allowed_for_workflow = false;
		var perms = bcore.perm.get_perm(this.doc.doctype)[0];

		// Allow submit, write, cancel and create permissions for read only documents that are assigned by
		// workflows if the user already have those permissions. This is to allow for users to
		// continue through the workflow states and to allow execution of functions like Duplicate.
		if ((bcore.workflow.is_read_only(this.doctype, this.docname) && (perms["write"] ||
			perms["create"] || perms["submit"] || perms["cancel"])) || !bcore.workflow.is_read_only(this.doctype, this.docname)) {
			allowed_for_workflow = true;
		}

		if (!this.perm[0][perm_to_check] && !allowed_for_workflow) {
			if (resolve) {
				// re-enable buttons
				resolve();
			}
			bcore.throw(__("No permission to '{0}' {1}", [__(action), __(this.doc.doctype)]));
		}
	}

	// HELPERS

	enable_save() {
		this.save_disabled = false;
		this.toolbar.set_primary_action();
	}

	disable_save() {
		// IMPORTANT: this function should be called in refresh event
		this.save_disabled = true;
		this.toolbar.current_status = null;
		this.page.clear_primary_action();
	}

	disable_form() {
		this.set_read_only();
		this.fields
			.forEach((field) => {
				this.set_df_property(field.df.fieldname, "read_only", "1");
			});
		this.disable_save();
	}

	handle_save_fail(btn, on_error) {
		$(btn).prop('disabled', false);
		if (on_error) {
			on_error();
		}
	}

	trigger_link_fields() {
		// trigger link fields which have default values set
		if (this.is_new() && this.doc.__run_link_triggers) {
			$.each(this.fields_dict, function (fieldname, field) {
				if (field.df.fieldtype == "Link" && this.doc[fieldname]) {
					// triggers add fetch, sets value in model and runs triggers
					field.set_value(this.doc[fieldname]);
				}
			});

			delete this.doc.__run_link_triggers;
		}
	}

	show_conflict_message() {
		if (this.doc.__needs_refresh) {
			if (this.doc.__unsaved) {
				this.dashboard.clear_headline();
				this.dashboard.set_headline_alert(__("This form has been modified after you have loaded it")
					+ '<a class="btn btn-xs btn-primary pull-right" onclick="cur_frm.reload_doc()">'
					+ __("Refresh") + '</a>', "alert-warning");
			} else {
				this.reload_doc();
			}
		}
	}

	show_submit_message() {
		if (this.meta.is_submittable
			&& this.perm[0] && this.perm[0].submit
			&& !this.is_dirty()
			&& !this.is_new()
			&& !bcore.model.has_workflow(this.doctype) // show only if no workflow
			&& this.doc.docstatus === 0) {
			this.dashboard.add_comment(__('Submit this document to confirm'), 'intent-info', true);
		}
	}

	show_web_link() {
		if (!this.doc.__islocal && this.doc.__onload && this.doc.__onload.is_website_generator) {
			this.web_link && this.web_link.remove();
			if (this.doc.__onload.published) {
				this.add_web_link("/" + this.doc.route);
			}
		}
	}

	add_web_link(path, label) {
		label = label || "See on Website";
		this.web_link = this.sidebar.add_user_action(__(label),
			function () { }).attr("href", path || this.doc.route).attr("target", "_blank");
	}

	has_read_permission() {
		// get perm
		var dt = this.parent_doctype ? this.parent_doctype : this.doctype;
		this.perm = bcore.perm.get_perm(dt, this.doc);

		if (!this.perm[0].read) {
			return 0;
		}
		return 1;
	}

	check_doctype_conflict(docname) {
		if (this.doctype == 'DocType' && docname == 'DocType') {
			bcore.msgprint(__('Allowing DocType, DocType. Be careful!'));
		} else if (this.doctype == 'DocType') {
			if (bcore.views.formview[docname] || bcore.pages['List/' + docname]) {
				window.location.reload();
				//	bcore.msgprint(__("Cannot open {0} when its instance is open", ['DocType']))
				// throw 'doctype open conflict'
			}
		} else {
			if (bcore.views.formview.DocType && bcore.views.formview.DocType.frm.opendocs[this.doctype]) {
				window.location.reload();
				//	bcore.msgprint(__("Cannot open instance when its {0} is open", ['DocType']))
				// throw 'doctype open conflict'
			}
		}
	}

	// rename the form
	// notify this form of renamed records
	rename_notify(dt, old, name) {
		// from form
		if (this.meta.istable)
			return;

		if (this.docname == old)
			this.docname = name;
		else
			return;

		// cleanup
		if (this && this.opendocs[old] && bcore.meta.docfield_copy[dt]) {
			// delete docfield copy
			bcore.meta.docfield_copy[dt][name] = bcore.meta.docfield_copy[dt][old];
			delete bcore.meta.docfield_copy[dt][old];
		}

		delete this.opendocs[old];
		this.opendocs[name] = true;

		if (this.meta.in_dialog || !this.in_form) {
			return;
		}

		bcore.re_route[window.location.hash] = '#Form/' + encodeURIComponent(this.doctype) + '/' + encodeURIComponent(name);
		bcore.set_route('Form', this.doctype, name);
	}

	// ACTIONS

	print_doc() {
		this.print_preview.toggle();
	}

	navigate_records(prev) {
		let filters, sort_field, sort_order;
		let list_view = bcore.get_list_view(this.doctype);
		if (list_view) {
			filters = list_view.get_filters_for_args();
			sort_field = list_view.sort_field;
			sort_order = list_view.sort_order;
		} else {
			let list_settings = bcore.get_user_settings(this.doctype)['List'];
			if (list_settings) {
				filters = list_settings.filters;
				sort_field = list_settings.sort_field;
				sort_order = list_settings.sort_order;
			}
		}

		let args = {
			doctype: this.doctype,
			value: this.docname,
			filters,
			sort_order,
			sort_field,
			prev,
		};

		bcore.call('bcore.desk.form.utils.get_next', args).then(r => {
			if (r.message) {
				bcore.set_route('Form', this.doctype, r.message);
				this.focus_on_first_input();
			}
		});
	}

	focus_on_first_input() {
		let $first_input_el = $(bcore.container.page).find('.bcore-control:visible').eq(0);
		$first_input_el.find('input, select, textarea').focus();
	}

	rename_doc() {
		bcore.model.rename_doc(this.doctype, this.docname, () => this.refresh_header());
	}

	share_doc() {
		this.shared.show();
	}

	email_doc(message) {
		new bcore.views.CommunicationComposer({
			doc: this.doc,
			frm: this,
			subject: __(this.meta.name) + ': ' + this.docname,
			recipients: this.doc.email || this.doc.email_id || this.doc.contact_email,
			attach_document_print: true,
			message: message,
			real_name: this.doc.real_name || this.doc.contact_display || this.doc.contact_name
		});
	}

	copy_doc(onload, from_amend) {
		this.validate_form_action("Create");
		var newdoc = bcore.model.copy_doc(this.doc, from_amend);

		newdoc.idx = null;
		newdoc.__run_link_triggers = false;
		if (onload) {
			onload(newdoc);
		}
		bcore.set_route('Form', newdoc.doctype, newdoc.name);
	}

	async reload_doc() {
		this.check_doctype_conflict(this.docname);

		if (!this.doc.__islocal) {
			bcore.model.remove_from_locals(this.doctype, this.docname);
			bcore.model.with_doc(this.doctype, this.docname, async () => {
				await this.refresh();
			});
		}
	}

	refresh_field(fname) {
		if (this.fields_dict[fname] && this.fields_dict[fname].refresh) {
			this.fields_dict[fname].refresh();
			this.layout.refresh_dependency();
		}
	}

	// UTILITIES
	add_fetch(link_field, src_field, tar_field) {
		if (!this.fetch_dict[link_field]) {
			this.fetch_dict[link_field] = { 'columns': [], 'fields': [] };
		}
		this.fetch_dict[link_field].columns.push(src_field);
		this.fetch_dict[link_field].fields.push(tar_field);
	}

	has_perm(ptype) {
		return bcore.perm.has_perm(this.doctype, 0, ptype, this.doc);
	}

	dirty() {
		this.doc.__unsaved = 1;
		this.$wrapper.trigger('dirty');
	}

	get_docinfo() {
		return bcore.model.docinfo[this.doctype][this.docname];
	}

	is_dirty() {
		return this.doc.__unsaved;
	}

	is_new() {
		return this.doc.__islocal;
	}

	get_perm(permlevel, access_type) {
		return this.perm[permlevel] ? this.perm[permlevel][access_type] : null;
	}

	set_intro(txt, color) {
		this.dashboard.set_headline_alert(txt, color);
	}

	set_footnote(txt) {
		this.footnote_area = bcore.utils.set_footnote(this.footnote_area, this.body, txt);
	}

	add_custom_button(label, fn, group) {
		// temp! old parameter used to be icon
		if (group && group.indexOf("fa fa-") !== -1) group = null;
		var btn = this.page.add_inner_button(label, fn, group);
		if (btn) {
			this.custom_buttons[label] = btn;
		}
		return btn;
	}

	clear_custom_buttons() {
		this.page.clear_inner_toolbar();
		this.page.clear_user_actions();
		this.custom_buttons = {};
	}

	//Remove specific custom button by button Label
	remove_custom_button(label, group) {
		this.page.remove_inner_button(label, group);
	}

	set_print_heading(txt) {
		this.pformat[this.docname] = txt;
	}

	scroll_to_element() {
		if (bcore.route_options && bcore.route_options.scroll_to) {
			var scroll_to = bcore.route_options.scroll_to;
			delete bcore.route_options.scroll_to;

			var selector = [];
			for (var key in scroll_to) {
				var value = scroll_to[key];
				selector.push(repl('[data-%(key)s="%(value)s"]', { key: key, value: value }));
			}

			selector = $(selector.join(" "));
			if (selector.length) {
				bcore.utils.scroll_to(selector);
			}
		}
	}

	show_success_action() {
		const route = bcore.get_route();
		if (route[0] !== 'Form') return;
		if (this.meta.is_submittable && this.doc.docstatus !== 1) return;

		const success_action = new bcore.ui.form.SuccessAction(this);
		success_action.show();
	}

	get_doc() {
		return locals[this.doctype][this.docname];
	}

	set_currency_labels(fields_list, currency, parentfield) {
		// To set the currency in the label
		// For example Total Cost(INR), Total Cost(USD)
		if (!currency) return;
		var me = this;
		var doctype = parentfield ? this.fields_dict[parentfield].grid.doctype : this.doc.doctype;
		var field_label_map = {};
		var grid_field_label_map = {};

		$.each(fields_list, function (i, fname) {
			var docfield = bcore.meta.docfield_map[doctype][fname];
			if (docfield) {
				var label = __(docfield.label || "").replace(/\([^\)]*\)/g, ""); // eslint-disable-line
				if (parentfield) {
					grid_field_label_map[doctype + "-" + fname] =
						label.trim() + " (" + __(currency) + ")";
				} else {
					field_label_map[fname] = label.trim() + " (" + currency + ")";
				}
			}
		});

		$.each(field_label_map, function (fname, label) {
			me.fields_dict[fname].set_label(label);
		});

		$.each(grid_field_label_map, function (fname, label) {
			fname = fname.split("-");
			var df = bcore.meta.get_docfield(fname[0], fname[1], me.doc.name);
			if (df) df.label = label;
		});
	}

	field_map(fnames, fn) {
		if (typeof fnames === 'string') {
			if (fnames == '*') {
				fnames = Object.keys(this.fields_dict);
			} else {
				fnames = [fnames];
			}
		}
		for (var i = 0, l = fnames.length; i < l; i++) {
			var fieldname = fnames[i];
			var field = bcore.meta.get_docfield(cur_frm.doctype, fieldname, this.docname);
			if (field) {
				fn(field);
				this.refresh_field(fieldname);
			}
		}
	}

	get_docfield(fieldname1, fieldname2) {
		if (fieldname2) {
			// for child
			var doctype = this.get_docfield(fieldname1).options;
			return bcore.meta.get_docfield(doctype, fieldname2, this.docname);
		} else {
			// for parent
			return bcore.meta.get_docfield(this.doctype, fieldname1, this.docname);
		}
	}

	set_df_property(fieldname, property, value, docname, table_field) {
		var df;
		if (!docname && !table_field) {
			df = this.get_docfield(fieldname);
		} else {
			var grid = this.fields_dict[table_field].grid,
				fname = bcore.utils.filter_dict(grid.docfields, { 'fieldname': fieldname });
			if (fname && fname.length)
				df = bcore.meta.get_docfield(fname[0].parent, fieldname, docname);
		}
		if (df && df[property] != value) {
			df[property] = value;
			refresh_field(fieldname, table_field);
		}
	}

	toggle_enable(fnames, enable) {
		this.field_map(fnames, function (field) {
			field.read_only = enable ? 0 : 1;
		});
	}

	toggle_reqd(fnames, mandatory) {
		this.field_map(fnames, function (field) {
			field.reqd = mandatory ? true : false;
		});
	}

	toggle_display(fnames, show) {
		this.field_map(fnames, function (field) {
			field.hidden = show ? 0 : 1;
		});
	}

	get_files() {
		return this.attachments
			? bcore.utils.sort(this.attachments.get_attachments(), "file_name", "string")
			: [];
	}

	set_query(fieldname, opt1, opt2) {
		if (opt2) {
			// on child table
			// set_query(fieldname, parent fieldname, query)
			this.fields_dict[opt1].grid.get_field(fieldname).get_query = opt2;
		} else {
			// on parent table
			// set_query(fieldname, query)
			if (this.fields_dict[fieldname]) {
				this.fields_dict[fieldname].get_query = opt1;
			}
		}
	}

	clear_table(fieldname) {
		bcore.model.clear_table(this.doc, fieldname);
	}

	add_child(fieldname, values) {
		var doc = bcore.model.add_child(this.doc, bcore.meta.get_docfield(this.doctype, fieldname).options, fieldname);
		if (values) {
			// Values of unique keys should not be overridden
			var d = {};
			var unique_keys = ["idx", "name"];

			Object.keys(values).map((key) => {
				if (!unique_keys.includes(key)) {
					d[key] = values[key];
				}
			});

			$.extend(doc, d);
		}
		return doc;
	}

	set_value(field, value, if_missing) {
		var me = this;
		var _set = function (f, v) {
			var fieldobj = me.fields_dict[f];
			if (fieldobj) {
				if (!if_missing || !bcore.model.has_value(me.doctype, me.doc.name, f)) {
					if (bcore.model.table_fields.includes(fieldobj.df.fieldtype) && $.isArray(v)) {

						bcore.model.clear_table(me.doc, fieldobj.df.fieldname);

						for (var i = 0, j = v.length; i < j; i++) {
							var d = v[i];
							var child = bcore.model.add_child(me.doc, fieldobj.df.options,
								fieldobj.df.fieldname, i + 1);
							$.extend(child, d);
						}

						me.refresh_field(f);
						return Promise.resolve();
					} else {
						return bcore.model.set_value(me.doctype, me.doc.name, f, v);
					}
				}
			} else {
				bcore.msgprint(__("Field {0} not found.", [f]));
				throw "frm.set_value";
			}
		};

		if (typeof field == "string") {
			return _set(field, value);
		} else if ($.isPlainObject(field)) {
			let tasks = [];
			for (let f in field) {
				let v = field[f];
				if (me.get_field(f)) {
					tasks.push(() => _set(f, v));
				}
			}
			return bcore.run_serially(tasks);
		}
	}

	call(opts, args, callback) {
		var me = this;
		if (typeof opts === 'string') {
			// called as frm.call('do_this', {with_arg: 'arg'});
			opts = {
				method: opts,
				doc: this.doc,
				args: args,
				callback: callback
			};
		}
		if (!opts.doc) {
			if (opts.method.indexOf(".") === -1)
				opts.method = bcore.model.get_server_module_name(me.doctype) + "." + opts.method;
			opts.original_callback = opts.callback;
			opts.callback = function (r) {
				if ($.isPlainObject(r.message)) {
					if (opts.child) {
						// update child doc
						opts.child = locals[opts.child.doctype][opts.child.name];

						var std_field_list = ["doctype"].concat(bcore.model.std_fields_list);
						for (var key in r.message) {
							if (std_field_list.indexOf(key) === -1) {
								opts.child[key] = r.message[key];
							}
						}

						me.fields_dict[opts.child.parentfield].refresh();
					} else {
						// update parent doc
						me.set_value(r.message);
					}
				}
				opts.original_callback && opts.original_callback(r);
			};
		} else {
			opts.original_callback = opts.callback;
			opts.callback = function (r) {
				if (!r.exc) me.refresh_fields();

				opts.original_callback && opts.original_callback(r);
			};

		}
		return bcore.call(opts);
	}

	get_field(field) {
		return this.fields_dict[field];
	}

	set_root_read_only(fieldname) {
		// read-only for root doctype group
		if (this.meta.is_tree && !this.doc[fieldname] && !this.is_new()) {
			this.set_read_only();
			this.set_intro(__("This is a root {0} and cannot be edited.", [this.doc.doctype.bold()]), true);
		}
	}

	set_read_only() {
		var perm = [];
		var docperms = bcore.perm.get_perm(this.doc.doctype);
		for (var i = 0, l = docperms.length; i < l; i++) {
			var p = docperms[i];
			perm[p.permlevel || 0] = { read: 1, print: 1, cancel: 1, email: 1 };
		}
		this.perm = perm;
	}

	trigger(event, doctype, docname) {
		return this.script_manager.trigger(event, doctype, docname);
	}

	get_formatted(fieldname) {
		return bcore.format(this.doc[fieldname],
			bcore.meta.get_docfield(this.doctype, fieldname, this.docname),
			{ no_icon: true }, this.doc);
	}

	open_grid_row() {
		return bcore.ui.form.get_open_grid_form();
	}

	get_title() {
		if (this.meta.title_field) {
			return this.doc[this.meta.title_field];
		} else {
			return this.doc.name;
		}
	}

	get_selected() {
		// returns list of children that are selected. returns [parentfield, name] for each
		var selected = {}, me = this;
		bcore.meta.get_table_fields(this.doctype).forEach(function (df) {
			// handle TableMultiselect child fields
			let _selected = [];

			if (me.fields_dict[df.fieldname].grid) {
				_selected = me.fields_dict[df.fieldname].grid.get_selected();
			}

			if (_selected.length) {
				selected[df.fieldname] = _selected;
			}
		});
		return selected;
	}

	set_indicator_formatter(fieldname, get_color, get_text) {
		// get doctype from parent
		var doctype;
		if (bcore.meta.docfield_map[this.doctype][fieldname]) {
			doctype = this.doctype;
		} else {
			bcore.meta.get_table_fields(this.doctype).every(function (df) {
				if (bcore.meta.docfield_map[df.options][fieldname]) {
					doctype = df.options;
					return false;
				} else {
					return true;
				}
			});
		}

		bcore.meta.docfield_map[doctype][fieldname].formatter =
			function (value, df, options, doc) {
				if (value) {
					var label;
					if (get_text) {
						label = get_text(doc);
					} else if (bcore.form.link_formatters[df.options]) {
						label = bcore.form.link_formatters[df.options](value, doc);
					} else {
						label = value;
					}

					const escaped_name = encodeURIComponent(value);

					return repl('<a class="indicator %(color)s" href="#Form/%(doctype)s/%(name)s">%(label)s</a>', {
						color: get_color(doc || {}),
						doctype: df.options,
						name: escaped_name,
						label: label
					});
				} else {
					return '';
				}
			};
	}

	can_create(doctype) {
		// return true or false if the user can make a particlar doctype
		// will check permission, `can_make_methods` if exists, or will decided on
		// basis of whether the document is submittable
		if (!bcore.model.can_create(doctype)) {
			return false;
		}

		if (this.custom_make_buttons && this.custom_make_buttons[doctype]) {
			// custom buttons are translated and so are the keys
			const key = __(this.custom_make_buttons[doctype]);
			// if the button is present, then show make
			return !!this.custom_buttons[key];
		}

		if (this.can_make_methods && this.can_make_methods[doctype]) {
			return this.can_make_methods[doctype](this);
		} else {
			if (this.meta.is_submittable && !this.doc.docstatus == 1) {
				return false;
			} else {
				return true;
			}
		}
	}

	make_new(doctype, opts) {
		// make new doctype from the current form
		// will handover to `make_methods` if defined
		// or will create and match link fields
		var me = this;
		if (this.make_methods && this.make_methods[doctype]) {
			return this.make_methods[doctype](this);
		} else if (this.custom_make_buttons && this.custom_make_buttons[doctype]) {
			this.custom_buttons[__(this.custom_make_buttons[doctype])].trigger('click');
		} else {
			bcore.model.with_doctype(doctype, function () {
				var new_doc = bcore.model.get_new_doc(doctype, null, null, null, opts);

				// set link fields (if found)
				bcore.get_meta(doctype).fields.forEach(function (df) {
					if (df.fieldtype === 'Link' && df.options === me.doctype) {
						new_doc[df.fieldname] = me.doc.name;
					} else if (['Link', 'Dynamic Link'].includes(df.fieldtype) && me.doc[df.fieldname]) {
						new_doc[df.fieldname] = me.doc[df.fieldname];
					}
				});

				bcore.ui.form.make_quick_entry(doctype, null, null, new_doc);
			});
		}
	}

	update_in_all_rows(table_fieldname, fieldname, value) {
		// update the child value in all tables where it is missing
		if (!value) return;
		var cl = this.doc[table_fieldname] || [];
		for (var i = 0; i < cl.length; i++) {
			if (!cl[i][fieldname]) cl[i][fieldname] = value;
		}
		refresh_field("items");
	}

	get_sum(table_fieldname, fieldname) {
		let sum = 0;
		for (let d of (this.doc[table_fieldname] || [])) {
			sum += d[fieldname];
		}
		return sum;
	}

	scroll_to_field(fieldname) {
		let field = this.get_field(fieldname);
		if (!field) return;

		let $el = field.$wrapper;

		// uncollapse section
		if (field.section.is_collapsed()) {
			field.section.collapse(false);
		}

		// scroll to input
		bcore.utils.scroll_to($el, true);

		// highlight input
		$el.addClass('has-error');
		setTimeout(() => {
			$el.removeClass('has-error');
			$el.find('input, select, textarea').focus();
		}, 1000);
	}
};

bcore.validated = 0;
// Proxy for bcore.validated
Object.defineProperty(window, 'validated', {
	get: function () {
		console.warn('Please use `bcore.validated` instead of `validated`. It will be deprecated soon.'); // eslint-disable-line
		return bcore.validated;
	},
	set: function (value) {
		console.warn('Please use `bcore.validated` instead of `validated`. It will be deprecated soon.'); // eslint-disable-line
		bcore.validated = value;
		return bcore.validated;
	}
});
