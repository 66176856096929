import { useEffect, useRef, useState } from "react";
import { make_react_component } from "../controllers/web_components";
import { is_ref_valid, to_number, to_str } from "./utils";
import { use_wc_ready } from "../react/hooks/use_loading";
import { queue_fetch } from "../utils";

make_react_component({
	tag: "b-icon",
	style: `
	:host {
		--v-icon-stroke-width: 1;
		--v-icon-color: var(--c-text);
		--v-icon-fill:none;
	}
	:host svg {
		width: 100%;
		height: 100%;
		stroke-width: var(--v-icon-stroke-width);
		color: var(--v-icon-color);
		fill:var(--v-icon-fill);
	}
	`,
	props: {
		icon: to_str,
		stroke: to_number,
		color: to_str,
		fill: to_str,
	},
	component: ({ icon, stroke, color, fill = "none", $web_component }) => {
		const ready = use_wc_ready($web_component);
		const ref = useRef();
		const [svg, set_svg] = useState();
		const icon_url = `/assets/bcore/icons/${icon}.svg`;

		useEffect(async () => {
			const result = await queue_fetch(icon_url);
			set_svg(await result.text());
		}, [icon_url, set_svg]);

		useEffect(() => {
			if (is_ref_valid(ref) && svg) {
				ref.current.innerHTML = svg;
			}
		}, [ref, svg]);

		return <>
			{(!ready || !svg) && (
				<div className="skeleton tiny">
					<div className="skeleton-icon"></div>
				</div>
			)}
			<div ref={ref}
				style={{
					...(stroke && { "--v-icon-stroke-width": stroke }),
					...(color && { "--v-icon-color": color }),
					...(fill && { "--v-icon-fill": fill })
				}}
				className="flex-fluid"></div>
		</>
	}
});