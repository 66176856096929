// Framework events /////////////////////////////////////////////


/**
 * Triggered along every other event in a controller. Usefull to propagate
 * events further into child controllers.
 */
export const EVT_BROADCAST = Symbol("broadcast");

/**
 * Triggered before initialization to allow variable initialization.
 * You can think of this event as an async constructor for your controllers.
 */
export const EVT_CONSTRUCT = Symbol("construct");

/**
 * Triggered when a controller is initialized.
 */
export const EVT_INIT = Symbol("init");
export const FLAG_INITIALIZED = Symbol("initialized");

/**
 * Triggered right before initializing a controller
 */
export const EVT_BEFORE_INIT = Symbol("before_init");

/**
 * Triggered right after initalizing a controller.
 */
export const EVT_AFTER_INIT = Symbol("after_init");


// Parent and Child Controller events //////////////////////////////////


/**
 * Triggered when a child component is added to a parent enabled one.
 */
export const EVT_CHILD_CONTROLLER_ADDED = Symbol("child_controller_added");

/**
 * Triggered when a child component is removed from a parent enabled one.
 */
export const EVT_CHILD_CONTROLLER_REMOVED = Symbol("child_controller_removed");

/**
 * Triggered (in a child) when a child(ChildComponent) controller's parent(ParentComponent)
 * controler is set. Called right after the child controller is added to a parent one. 
 */
export const EVT_PARENT_CONTROLLER_SET = Symbol("parent_controller_set");

/**
 * Triggered (in a child) when a child(ChildComponent) controller is removed from a
 * parent(ParentComponent) controller.
 */
export const EVT_PARENT_CONTROLLER_UNSET = Symbol("parent_controller_unset");


// Tag events /////////////////////////////////////////////////////


/**
 * Triggered when a tag is added to a TaggedComponent enabled controller.
 */
export const EVT_TAG_ADDED = Symbol("tag_added");

/**
 * Triggered when a tag is removed from a TaggedComponent enabled controller.
 */
export const EVT_TAG_REMOVED = Symbol("tag_removed");