import localforage from "localforage";
import { useEffect, useState } from "react";

/**
 * Sets and gets data from local storage.
 * @param {*} key The local storage key
 * @param {*} default_value A default value if one isn't available
 */
export const use_local_cache = (key, default_value) => {
	const [cache, setCache] = useState(default_value);

	// Loads cache data on load
	useEffect(async () => {
		const data = await localforage.getItem(key);
		if (data) {
			setCache(data);
		}
	}, [key, setCache])

	// Stores data on cache on changes
	useEffect(async () => {
		if (cache) {
			await localforage.setItem(key, cache);
		}
	}, [key, cache]);

	return [cache, setCache]
}