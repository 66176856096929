
	bcore.templates['list_sidebar'] = `
<div class="d-flex flex-col gap-2">
	<ul class="list-unstyled sidebar-menu user-actions hide bb-solid"></ul>
	<ul class="list-unstyled sidebar-menu standard-actions flex-col gap-1h">
		<li class="list-sidebar-label">Views</li>
		{% if bcore.model.can_get_report(doctype) %}
		<li class="list-link">
			<div class="btn-group">
				<a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#" onclick="return false;">
					{{ __("Reports") }} <span class="caret"></span>
				</a>
				<ul class="dropdown-menu reports-dropdown" role="menu">
					<li><a href="#List/{{ doctype }}/Report">{{ __("Report Builder") }}</a></li>
				</ul>
			</div>
		</li>
		{% endif %}
		<li class="list-link" data-view="List">
			<a href="#List/{%= doctype %}/List">{%= __("List") %}</a></li>
		<li class="list-link" data-view="Dashboard">
			<a href="#List/{%= doctype %}/Dashboard">{%= __("Dashboard") %}</a></li>
		<li class="hide list-link" data-view="Image">
			<a href="#List/{%= doctype %}/Image">{%= __("Images") %}</a></li>
		<li class="hide list-link" data-view="Gantt">
			<a href="#List/{%= doctype %}/Gantt">{%= __("Gantt") %}</a></li>
		<li class="hide tree-link">
			<a href="#Tree/{%= doctype %}">{%= __("Tree") %}</a></li>
		<li class="hide list-link tree-list-link" data-view="List Tree">
			<a href="#List/{%= doctype %}/ListTree">{%= __("List Tree") %}</a></li>
		<li class="hide list-link" data-view="Calendar">
			<a href="#List/{%= doctype %}/Calendar">{%= __("Calendar") %}</a></li>
		<li class="hide list-link" data-view="Kanban">
			<div class="btn-group">
				<a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#" onclick="return false;">
					{{ __("Kanban") }} <span class="caret"></span>
				</a>
				<ul class="dropdown-menu kanban-dropdown" style="max-height: 300px; overflow-y: auto;" role="menu">
					<li class="new-kanban-board"><a href="#" onclick="return false;">{{ __("New Kanban Board") }}</a></li>
				</ul>
			</div>
		</li>
		<li class="hide list-link" data-view="Inbox">
			<div class="btn-group">
				<a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#" onclick="return false;">
					{{ __("Email Inbox") }} <span class="caret"></span>
				</a>
				<ul class="dropdown-menu email-account-dropdown" style="max-height: 300px; overflow-y: auto;" role="menu">
				</ul>
			</div>
		</li>
	</ul>
	
	<ul class="list-unstyled sidebar-menu list-group-by flex-col gap-1h">
	</ul>

	<ul class="list-unstyled sidebar-menu sidebar-stat flex-col gap-1h">
		<li class="list-sidebar-label stat-label">{{ __("Tags") }}</li>
		<li class="list-stats list-link">
			<div class="btn-group">
				<a class = "dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#" onclick="return false;">
					{{ __("Tags") }} <span class="caret"></span>
				</a>
				<ul class="dropdown-menu list-stats-dropdown" role="menu">
					<div class="dropdown-search">
						<input type="text" placeholder="Search" data-element="search" class="form-control input-xs">
					</div>
				</ul>
			</div>
		</li>
		<li class="list-link">
			<a class="list-tag-preview hidden-xs text-muted">{{ __("Show Tags") }}</a>
		</li>
	</ul>
	<ul class="list-unstyled sidebar-menu charts-menu hide flex-col gap-1h">
		<li class="h6">{%= __("Charts") %}</li>
		<li class="list-link">
			<a class="toggle-charts">{%= __("Toggle Charts") %}</a>
		</li>
		<li class="list-link">
			<a class="configure-charts hidden">{%= __("Configure Charts") %}</a>
		</li>
	</ul>
	<ul class="list-unstyled sidebar-menu list-filters flex-col gap-1h"></ul>
<div>
`;
