import { Component } from "../../../compose";
import { EVT_INIT } from "../../../events";

export class BootInfoComponent extends Component {
	async [EVT_INIT]() {
		if(bcore.boot.status==='failed') {
			bcore.msgprint({
				message: bcore.boot.error,
				title: __('Session Start Failed'),
				indicator: 'red',
			});
			throw 'boot failed';
		}

		if ( bcore.boot ) {
			bcore.modules = {};
			bcore.boot.allowed_modules.forEach(function(m) {
				bcore.modules[m.module_name]=m;
			});
			bcore.model.sync(bcore.boot.docs);
			$.extend(bcore._messages, bcore.boot.__messages);

			await this.broadcast("boot");
			bcore.user.name = bcore.boot.user.name;
		} else {
			await this.broadcast("boot_guest");
		}
	}
}